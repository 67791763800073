import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from './auth.module.js';
// import { timeseriesData } from './timeseriesData';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    storeDeviceType: null,
    storeBrandName: null,
    storeSession: null,
    storeDeviceId: null,
    storeStartDate: null,
    storeEndDate: null,
    loraJwt: null,
    // loggedIn: false,
    userEnvironments: [],
    activeEnvironment: null,
  },
  mutations: {
    setStoreDeviceType(state, storeDeviceType) {
      state.storeDeviceType = storeDeviceType;
    },
    setStoreBrandName(state, storeBrandName) {
      state.setStoreBrandName = storeBrandName;
    },
    setStoreSession(state, storeSession) {
      state.setStoreSession = storeSession;
    },
    setStoreDeviceId(state, storeDeviceId) {
      state.storeDeviceId = storeDeviceId;
    },
    setStoreStartDate(state, storeStartDate) {
      state.storeStartDate = storeStartDate;
    },
    setStoreEndDate(state, storeEndDate) {
      state.storeEndDate = storeEndDate;
    },
    setLoraJwt(state, loraJwt) {
      state.loraJwt = loraJwt;
    },
    // setLoggedId(state, loggedId) {
    //   state.loggedIn = loggedId;
    // }
    setUserEnvironments(state, environments) {
      state.userEnvironments = environments;
    },
    setActiveEnvironment(state, activeEnvironment) {
      state.activeEnvironment = activeEnvironment
    },
  },
  getters: {
    getStoreDeviceType: (getters) => {
      return getters.storeDeviceType;
    },
    getStoreBrandName: (getters) => {
      return getters.storeBrandName;
    },
    getStoreSession: (getters) => {
      return getters.storeSession;
    },
    getStoreDeviceId: (getters) => {
      return getters.storeDeviceId;
    },
    getStoreStartDate: (getters) => {
      return getters.storeStartDate;
    },
    getStoreEndDate: (getters) => {
      return getters.storeEndDate;
    },
    getLoraJwt: (getters) => {
      return getters.loraJwt;
    },
    isAdmin: (getters) => {
      if(!getters.auth.user) {
        return false;
      }
      return getters.auth.user.roles.includes("ROLE_ADMIN");
    },
    getUserEnvironments: (getters) => {
      return getters.userEnvironments;
    },
    getActiveEnvironment: (getters) => {
      return getters.activeEnvironment;
    },
  },
  actions: {
  },
  modules: {
    auth,
    // timeseriesData,
}
})
