// const environment = "development";
//const environment = 'prototype';
let environment = 'product';
export default {
  // API_URL: 'http://localhost:8080/v1',
  // API_URL: 'https://devapi.swedencentral.cloudapp.azure.com/v1',
  // Old test environment
  API_URL: "https://api.exeger.com/v1",
  // Prometheus
  // API_URL: 'https://proto-api.exeger.com/v1',
  DEV_API_URL: "https://dev-api.exeger.com/v1",
  PROTO_API_URL: "https://proto-api.exeger.com/v1",
  PROD_API_URL: "https://pub-api.exeger.com/v1",
  STAGE_API_URL: "https://stage-api.exeger.com/v1",

  // Resources
  RES_CUSTOMER_BRAND: "/customer-brand",
  RES_USER: "/user",
  RES_USER_UPDATE_PASSWORD: "/user/updatePassword",
  RES_CUSTOMER_SDK_API_KEY: "/customer/sdk-api-key",
  RES_DEVICE: "/device",
  RES_DEVICE_EVENTS: "/device-events",
  RES_DEVICE_EVENTS_DAILY_MEASUREMENTS: "/device-events/daily-measurements",
  RES_DEVICE_EVENTS_WEEKLY_MEASUREMENTS: "/device-events/weekly-measurements",
  RES_DEVICE_EVENTS_MONTHLY_MEASUREMENTS: "/device-events/monthly-measurements",
  RES_DEVICE_EVENTS_YEARLY_MEASUREMENTS: "/device-events/yearly-measurements",
  RES_DEVICE_EVENTS_TOTAL_USERS: "/device-events/total-users",
  RES_DEVICE_EVENTS_TOTAL_DEVICES: "/device-events/total-devices",
  RES_DEVICE_EVENTS_TOTAL_CUSTOMERS: "/device-events/total-customers",
  RES_DEVICE_EVENTS_TOTAL_NEW_USERS: "/device-events/total-new-users",
  RES_DEVICE_EVENTS_TOTAL_NEW_DEVICES: "/device-events/total-new-devices",
  RES_DEVICE_EVENTS_TOTAL_NEW_CUSTOMERS: "/device-events/total-new-customers",
  RES_TESTCASE: "/testcase",

  user_roles: {
    ADMIN: "ROLE_ADMIN",
    POWER_USER: "ROLE_POWER_USER",
    USER: "ROLE_USER",
  },

  CUSTOMER_FIELD_LABEL: environment == "prototype" ? "Customer" : "Brand name",

  DEV_ENV: "development",
  PROTO_ENV: "prototype",
  PROD_ENV: "product",
  STAGE_ENV: "staging",

  TEST_ENV: "prototype", // "product"
};
