import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Register from '../views/Register.vue';
import SignUp from '../views/SignUp.vue';
import Login from '../views/Login.vue';
import ChangePassword from "../views/ChangePassword.vue";
import UserSettings from "../views/UserSettings.vue";
import Profile from '../views/Profile.vue';
// import Configuration from '../views/Configuration.vue'
import Customer from '../views/Customer.vue';
import Activity from '../views/Activity.vue'
import LiveActivity from '../views/LiveActivity.vue'
import Device from '../views/Device.vue'
import RegisterLoraDevice from '../views/RegisterLoraDevice.vue'
// import ActivityMultipleHeadphones from '../views/ActivityNew.vue'
import LoggerFileUpload from '../views/LoggerFileUpload.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Dashboard - Exeger Data",
      metaTags: [
        {
          name: "description",
          content: "The dashboard page of Exeger Data.",
        },
        {
          property: "og:description",
          content: "The dashboard page of Exeger Data.",
        },
      ],
    },
  },
  // {
  //   path: '/register',
  //   name: 'Register',
  //   component: Register,
  // },
  {
    path: "/register",
    name: "Register",
    component: SignUp,
    meta: {
      title: "Sign up - Exeger Data",
      metaTags: [
        {
          name: "description",
          content: "The signup page of Exeger Data.",
        },
        {
          property: "og:description",
          content: "The signup page of Exeger Data.",
        },
      ],
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Sign in - Exeger Data",
      metaTags: [
        {
          name: "description",
          content: "The signin page of Exeger Data.",
        },
        {
          property: "og:description",
          content: "The signin page of Exeger Data.",
        },
      ],
    },
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      title: "Change Password - Exeger Data",
      metaTags: [
        {
          name: "description",
          content: "The page a user can change his/her password.",
        },
        {
          property: "og:description",
          content: "The page a user can change his/her password.",
        },
      ],
    },
  },
  {
    path: "/user-settings",
    name: "UserSettings",
    component: UserSettings,
    meta: {
      title: "User Settings - Exeger Data",
      metaTags: [
        {
          name: "description",
          content: "The page users see their settings and change their password.",
        },
        {
          property: "og:description",
          content: "The page users see their settings and change their password.",
        },
      ],
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  // {
  //   path: '/configuration',
  //   name: 'Configuration',
  //   component: Configuration,
  // },
  {
    path: "/customer",
    name: "Customer",
    component: Customer,
    meta: {
      title: "Customer - Exeger Data",
      metaTags: [
        {
          name: "description",
          content: "The customer page of Exeger Data.",
        },
        {
          property: "og:description",
          content: "The customer page of Exeger Data.",
        },
      ],
    },
  },
  {
    path: "/device",
    name: "Device",
    component: Device,
    meta: {
      title: "Testcase / Device - Exeger Data",
      metaTags: [
        {
          name: "description",
          content: "The testcase - device page of Exeger Data.",
        },
        {
          property: "og:description",
          content: "The testcase - device page of Exeger Data.",
        },
      ],
    },
  },
  {
    path: "/register-lora-device",
    name: "RegisterLoraDevice",
    component: RegisterLoraDevice,
  },
  {
    path: "/live-activity",
    name: "LiveActivity",
    component: LiveActivity,
  },
  {
    path: "/activity",
    name: "Activity",
    component: Activity,
    meta: {
      title: "Device activity Page - Exeger Data",
      metaTags: [
        {
          name: "description",
          content: "The device activity page of Exeger Data.",
        },
        {
          property: "og:description",
          content: "The device activity page of Exeger Data.",
        },
      ],
    },
  },
  // {
  //   path: '/activity-multiple-headphones',
  //   name: 'ActivityMultipleHeadphones',
  //   component: ActivityMultipleHeadphones,
  // },
  {
    path: "/logger-file-upload",
    name: "LoggerFileUpload",
    component: LoggerFileUpload,
    meta: {
      title: "Data file upload Page - Exeger Data",
      metaTags: [
        {
          name: "description",
          content: "The data upload page.",
        },
        {
          property: "og:description",
          content: "The data upload page.",
        },
      ],
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && (!loggedIn || loggedIn == 'undefined') ) {
    return next('/login');
  }

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  return next();
});

export default router
