<template>
  <fusioncharts
    :type="type"
    :width="width"
    :height="height"
    :dataFormat="dataFormat"
    :dataEmptyMessage="dataEmptyMessage"
    :dataSource="dataSource"
    ref="chartRef"
  ></fusioncharts>
</template>

<script>
export default {
  name: "MsColumn2d",
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    caption: {
      type: String,
    },
    subCaption: {
      type: String,
    },
    categories: {
      type: Array,
    },
    dataset: {
      type: Array,
    },
    yAxisName: {
      type: String,
    },
    xAxisName: {
      type: String,
    },
  },
  data: function() {
    return {
      type: "mscolumn2d",
      dataFormat: "json",
      dataEmptyMessage: 'No data to show',
      dataSource: {
        chart: {
          caption: this.caption,
          subCaption: this.subCaption,
          captionFontColor: "#000000",
          subCaptionFontColor: "#000000",
          bgColor: "#e0e0c0",
          xAxisName: "",
          yAxisName: "",
          showValues: 0,
          placeValuesInside: 0,
          plotFillHoverColor: "#eeeeee",
          theme: "fusion",
          showLegend: 1,
          plotHighlightEffect: "fadeout|anchorBgColor=ff0000, anchorBgAlpha=50",
          legendPosition: "bottom-right",
          chartLeftMargin: 0,
          chartRightMargin: 0,
          chartTopMargin: 0,
          chartBottomMargin: 0,
          // legendPosition: "absolute",
          // legendXPosition: 1,
          // legendYPosition: 1,
          // legendAllowDrag: 1,
          // legendNumRows: 1,
          // legendNumColumns: 2,
          plottooltext: "<b>$label</b><br> $seriesName: <b>$dataValue mAh</b>",
          exportEnabled: 0,
          paletteColors: ["#009900", "#ff3300"],
        },
        categories: this.categories,
        dataset: this.dataset
      },
    };
  },
  watch: {
    dataset(val) {
      this.dataSource.dataset = val;
      // this.dataSource.chart.legendYPosition = window.innerHeight;
    },
    categories(val) {
      this.dataSource.categories = val;
      // this.dataSource.chart.legendYPosition = window.innerHeight;
    },
    subCaption(val) {
      this.dataSource.chart.subCaption = val;
    },
    yAxisName(val) {
      this.dataSource.chart.yAxisName = val;
    }
  },
  mounted() {
    this.dataSource.dataset = this.dataset;
    this.dataSource.categories = this.categories;
    this.dataSource.chart.subCaption = this.subCaption;
    this.dataSource.chart.yAxisName = this.yAxisName;
    // this.dataSource.chart.legendYPosition = window.innerHeight;
  }
};
</script>

<style>
</style>
