<template>
  <fusioncharts
    :type="type"
    :width="width"
    :height="height"
    :dataFormat="dataFormat"
    :dataSource="dataSource"
    ref="chart"
  ></fusioncharts>
</template>

<script>
export default {
  name: "Scatter",
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    caption: {
      type: String,
    },
    subCaption: {
      type: String,
    },
    nodes: {
      type: Array,
    },
    links: {
      type: Array,
    },
  },
  data: function() {
    return {
      type: "sankey",
      dataFormat: "json",
      loadMessage: "loading...",
      loadMessageColor: "#000000",
      dataEmptyMessage: "No data to display",
      dataSource: {
        chart: {
          caption: this.caption,
          subCaption: this.subCaption,
          captionFontColor: "#000000",
          subCaptionFontColor: "#000000",
          orientation: "horizontal",
          linkalpha: 30,
          linkhoveralpha: 60,
          nodelabelposition: "start",
          theme: "fusion",
        },
        nodes: this.nodes,
        links: this.links,
      },
    };
  },
  created() {
    console.log("created");
    window.addEventListener("resize", this.onResize);
  },
  mounted() {
    console.log("mounted");
    window.addEventListener("resize", this.onResize);
    const chartObj = this.$refs.chart.chartObj;
    console.log("width: ", this.width);
    chartObj.render();
  },
  destroyed() {
    console.log("destroyed");
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      const chartObj = this.$refs.chart.chartObj;
      console.log("width: ", this.width);
      chartObj.render();
    },
  },
};
</script>

<style></style>
