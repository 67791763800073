<template>
  <fusioncharts
    :type="type"
    :width="width"
    :height="height"
    :dataFormat="dataFormat"
    :dataEmptyMessage="dataEmptyMessage"
    :dataSource="dataSource"
    ref="chartRef"
  ></fusioncharts>
</template>

<script>
export default {
  name: "Doughnut2d",
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    caption: {
      type: String,
    },
    subCaption: {
      type: String,
    },
    data: {
      type: Array,
    },
  },
  data: function() {
    return {
      type: "doughnut2d",
      dataFormat: "json",
      // loadMessage: "loading...",
      // loadMessageColor: "#000000",
      dataEmptyMessage: "No data to display",
      dataSource: {
        chart: {
          caption: this.caption,
          captionFontColor: "#000000",
          subCaptionFontColor: "#000000",
          subCaption: this.subCaption,
          showlegend: false,
          legendPosition: "right",
          bgColor: "#e0e0c0",
          paletteColors: ["#009900", "#ff3300"],
          showpercentvalues: "0",
          // defaultcenterlabel: "",
          aligncaptionwithcanvas: "0",
          captionpadding: "0",
          decimals: "1",
          plottooltext: "<b>$label:</b> <b>$value mAh</b>",
          chartLeftMargin: 0,
          chartRightMargin: 0,
          chartTopMargin: 0,
          chartBottomMargin: 0,
          // centerlabel: "$value",
          theme: "fusion",
      yaxis: [
          {
            title: "Current in mAh",
            plot: [
              {
                value: "Gain",
                connectnulldata: false,
                type: "column",
                aggregation: "Sum",
              },
              {
                value: "Drain",
                connectnulldata: false,
                type: "column",
                aggregation: "Sum",
              },
            ],
            style: {
              label: "y-axis-label",
              title: "y-axis-title",
            },
          },
        ]  },
        data: this.data,
      },
      
    };
  },
  watch: {
    data(val) {
      this.dataSource.data = val;
    },
    subCaption(val) {
      this.dataSource.chart.subCaption = val;
    }
  },
  mounted() {
    this.dataSource.data = this.data;
    this.dataSource.chart.subCaption = this.subCaption;
  }
};
</script>

<style></style>
