<template>
  <div class="main-container">
    <v-row class="alert-container">
      <v-alert v-if="showMessage" class="alert" :type="messageType" dark>
        {{ message }}
      </v-alert>
    </v-row>
    <v-card class="box-container" v-model="valid" lazy-validation>
        <v-overlay
          v-if="signingIn"
          class="sign-absolute"
          :value="signingIn"
          :opacity="0.3"
        >
          <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
        </v-overlay>
        <h2 class="heading">{{environment == "development" ? "Development Mode" : ""}} </h2>
        <h2 class="heading">Sign In</h2>
        <div class="form-fields">
          <v-text-field v-model="user.email" :rules="emailRules" placeholder="Email" required solo dense></v-text-field>
        </div>
        <div class="form-fields">
          <v-text-field
            v-model="user.password"
            :rules="passwordRules"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            placeholder="Password"
            required
            solo
            dense
          ></v-text-field>
        </div>
        <div class="form-fields">
          <v-btn class="button" :disabled="signedIn" color="primary" @click="handleLogin">
            Signin
          </v-btn>
        </div>
        <v-row v-if="showSwitchEnv">
          <v-col v-if="validEnv.prototype.valid" :cols="validEnv.count == 2 ? 6 : 4">
            <v-btn width="100%" @click="onPrototype" color="primary">
              Prototype
            </v-btn>
          </v-col>
          <v-col v-if="validEnv.staging.valid" :cols="validEnv.count == 2 ? 6 : 4">
            <v-btn width="100%" @click="onStaging" color="primary">
              Staging
            </v-btn>
          </v-col>
          <v-col v-if="validEnv.product.valid" :cols="validEnv.count == 2 ? 6 : 4">
            <v-btn width="100%" @click="onProduct" color="primary">
              Product
            </v-btn>
          </v-col>
        </v-row>
        <!-- <div class="login-choice"><span>or</span></div>
        <SocialLogin /> -->
    </v-card>
    <!-- <div class="footer">
      <p>Don't have an account? <router-link to="/register" style="color:#00f"> Create one now</router-link></p>
    </div> -->
  </div>
</template>

<script>
import User from "../models/user";
// import SocialLogin from "@/components/SocialLogin";
export default {
  name: "login",
  components: {
    // SocialLogin,
  },
  data() {
    return {
      message: null,
      messageType: null,
      showMessage: false,
      signingIn: false,
      user: new User("", ""),
      signedIn: false,
      showSwitchEnv: false,
      validEnv: {
        count: 0,
        prototype: {
          valid: false,
          user: null,
        },
        product: {
          valid: false,
          user: null,
        },
        staging: {
          valid: false,
          user: null,
        },
      },
      showPassword: false,
      valid: false,
      passwordRules: [(v) => !!v || "Password is required"],
      email: "",
      emailRules: [
        (value) => !!value || "Email is required.",
        (value) => (value || "").length <= 50 || "Max 50 characters",
        (value) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          ) || "Invalid e-mail.",
      ],
    };
  },
  computed: {
    // loggedIn() {
    //   return this.$store.state.loggedIn;
    // }
    userEnvironments() {
      return localStorage.getItem("userEnvironments");
    },
    environment() {
      return localStorage.getItem("activeEnvironment");
    },
    loggedInUser() {
      return localStorage.getItem("user");
    },
    accessToken() {
      return this.$store.state.accessToken;
    },
  },
  watch: {
    user() {
      if (this.validate()) {
        this.valid = true;
      } else {
        this.valid = false;
      }
      // if(newValue.username != null && newValue.username != ''
      //   && newValue.password != null && newValue.password != '') {
      //     this.valid = true;
      //   }
    },
  },
  created() {
    // if (this.loggedIn) {
    //   this.$router.push("/");
    // }
    // console.log('logged in user: ', this.loggedInUser);
    this.signedIn = this.loggedInUser != null && this.loggedInUser.length > 0;
    // console.log('signed in: ', this.signedIn);
    if (this.signedIn && this.activeEnvironment) {
      this.$router.push("/");
    } else {
      localStorage.removeItem("user");
      localStorage.removeItem("activeEnvironment");
      localStorage.removeItem("userEnvironments");
      this.$store.commit("setUserEnvironments", []);
      this.$store.commit("setActiveEnvironment", null);
      this.signedIn = false;
    }
  },
  methods: {
    // validate() {
    //   this.$refs.form.validate();
    // },
    // handleLogin() {
    //   this.showMessage = false;
    //   this.message = "";
    //   this.signingIn = true;
    //   if (this.user.email && this.user.password) {
    //     this.$store.dispatch("auth/login", this.user).then(
    //       () => {
    //         this.$router.push("/");
    //       },
    //       (error) => {
    //         this.signingIn = false;
    //         this.message = (error.response && error.response.data) || error.message || error.toString();
    //         this.showMessage = true;
    //         this.messageType = "error";
    //         window.setTimeout(() => {
    //           this.showMessage = false;
    //         }, 5000);
    //       }
    //     );
    //   }
    // },

    checkCurrentUser() {
      const userEnvironments = this.userEnvironments.split(",");
      if (userEnvironments != null) {
        if (userEnvironments.length == 1) {
          this.$router.push("/")
        } else if (userEnvironments.length > 1) {
          this.$store.commit("setUserEnvironments", userEnvironments);
          this.signedIn = true;
          this.showSwitchEnv = true;
          userEnvironments.forEach(env => {
          switch (env) {
            case this.$globals.PROD_ENV:
              this.validEnv.product.valid = true;
              this.validEnv.count++;              
              break;
            case this.$globals.STAGE_ENV:
              this.validEnv.staging.valid = true;
              this.validEnv.count++;              
              break;
            case this.$globals.PROTO_ENV:
              this.validEnv.prototype.valid = true;
              this.validEnv.count++;              
              break;
            default:
              break;
          }
          });
        }
      }
    },

    async environmentsLogin() {
      this.showMessage = false;
      this.message = "";
      this.signingIn = true;
      this.$store.commit("setUserEnvironments", []);
      this.$store.commit("setActiveEnvironment", null);
      this.validEnv.count = 0;
      if (this.user.email && this.user.password) {
        // For development testing
        // console.log('environment: ', localStorage.getItem("activeEnvironment"));
        if (this.$globals.ENV == this.$globals.DEV_ENV) {
          let context = {
            user: this.user,
            environment: "development",
          };
          try {
            const res = await this.$store.dispatch("auth/loginEnvironment", context);
            const data = await res.json();
            // console.log("login response: ", data);
            if (res.status == 200 && data.accessToken) {
              localStorage.setItem("user", JSON.stringify(data));
              // console.log("proto success");
              this.validEnv.prototype.valid = true;
              this.validEnv.prototype.user = data;
              this.validEnv.count++;
            }
          } catch (e) {
            console.error("error: ", e);
          }
        } else {
          let context = {
            user: this.user,
            environment: "prototype",
          };
          let userEnvironments = []
          try {
            const res = await this.$store.dispatch("auth/loginEnvironment", context);
            const data = await res.json();
            if (res.status == 200 && data.accessToken) {
              localStorage.setItem("user", JSON.stringify(data));
              // console.log("proto success");
              this.validEnv.prototype.valid = true;
              this.validEnv.prototype.user = data;
              this.validEnv.count++;
              userEnvironments = this.$store.getters.getUserEnvironments;
              // console.log('got user environments: ', userEnvironments);
              userEnvironments.push(this.$globals.PROTO_ENV);
              // console.log('added user environment: ', userEnvironments);
            }
          } catch (e) {
            // console.error("error: ", e);
          }

          context.environment = "product";
          try {
            const res = await this.$store.dispatch("auth/loginEnvironment", context);
            const data = await res.json();
            if (res.status == 200 && data.accessToken) {
              localStorage.setItem("user", JSON.stringify(data));
              // console.log("prod success");
              this.validEnv.product.valid = true;
              this.validEnv.product.user = data;
              this.validEnv.count++;
              userEnvironments = this.$store.getters.getUserEnvironments;
              // console.log('got user environments: ', userEnvironments);
              userEnvironments.push(this.$globals.PROD_ENV);
              // console.log('added user environment: ', userEnvironments);
            }
          } catch (e) {
            // console.error("error: ", e);
          }

          context.environment = "staging";
          try {
            const res = await this.$store.dispatch("auth/loginEnvironment", context);
            const data = await res.json();
            if (res.status == 200 && data.accessToken) {
              localStorage.setItem("user", JSON.stringify(data));
              // console.log("stage success");
              this.validEnv.staging.valid = true;
              this.validEnv.staging.user = data;
              this.validEnv.count++;
              userEnvironments = this.$store.getters.getUserEnvironments;
              // console.log('got user environments: ', userEnvironments);
              userEnvironments.push(this.$globals.STAGE_ENV);
              // console.log('added user environment: ', userEnvironments);
            }
          } catch (e) {
            // console.error("error: ", e);
          }
          // console.log("user envi: ", userEnvironments);
          this.$store.commit("setUserEnvironments", userEnvironments);
          localStorage.setItem("userEnvironments", userEnvironments);
          this.signingIn = false;
        }
      }
    },

    async handleLogin() {
      await this.environmentsLogin();
      // console.log("validEnv: ", this.validEnv);
      // console.log('this.environment: ', this.environment);
      if (this.environment == "development") {
        this.signedIn = true;
        this.showSwitchEnv = true;
        return;
      }

      // console.log('this.validEnv.count: ', this.validEnv.count);
      if (this.validEnv.count == 1) {
        if (this.validEnv.prototype.valid) {
          localStorage.setItem("activeEnvironment", this.$globals.PROTO_ENV);
          this.$store.commit("setActiveEnvironment", this.$globals.PROTO_ENV);
          this.showSwitchEnv = false;
          this.$store.commit("auth/loginSuccess", this.validEnv.prototype.user);
          this.$router.push("/");
        } else if (this.validEnv.product.valid) {
          localStorage.setItem("activeEnvironment", this.$globals.PROD_ENV);
          this.$store.commit("setActiveEnvironment", this.$globals.PROD_ENV);
          this.showSwitchEnv = false;
          this.$store.commit("auth/loginSuccess", this.validEnv.product.user);
          this.$router.push("/");
        } else if (this.validEnv.staging.valid) {
          localStorage.setItem("activeEnvironment", this.$globals.STAGE_ENV);
          this.$store.commit("setActiveEnvironment", this.$globals.STAGE_ENV);
          this.showSwitchEnv = false;
          this.$store.commit("auth/loginSuccess", this.validEnv.staging.user);
          this.$router.push("/");
        }
      } else if (this.validEnv.count > 1) {
        this.signedIn = true;
        this.showSwitchEnv = true;
      } else {
        this.signingIn = false;
        this.message = "Bad credentials or account doesn't exist";
        this.showMessage = true;
        this.messageType = "error";
        window.setTimeout(() => {
          this.showMessage = false;
        }, 5000);
      }
      // if (this.validEnv.prototype.valid && !this.validEnv.product.valid) {
      //   localStorage.setItem("activeEnvironment", this.$globals.PROTO_ENV)
      //   this.$store.commit("setActiveEnvironment", this.$globals.PROTO_ENV);
      //   this.showSwitchEnv = false;
      //   this.$store.commit("auth/loginSuccess", this.validEnv.prototype.user);
      //   this.$router.push("/");
      // } else if (!this.validEnv.prototype.valid && this.validEnv.product.valid) {
      //   localStorage.setItem("activeEnvironment", this.$globals.PROD_ENV)
      //   this.$store.commit("setActiveEnvironment", this.$globals.PROD_ENV);
      //   this.showSwitchEnv = false;
      //   this.$store.commit("auth/loginSuccess", this.validEnv.product.user);
      //   this.$router.push("/");
      // } else if ((this.validEnv.prototype.valid && this.validEnv.product.valid)) {
      //   this.signedIn = true;
      //   this.showSwitchEnv = true;
      // } else {
      //   this.signingIn = false;
      //   this.message = "Bad credentials or account doesn't exist";
      //   this.showMessage = true;
      //   this.messageType = "error";
      //   window.setTimeout(() => {
      //     this.showMessage = false;
      //   }, 5000);
      // }
    },

    onPrototype() {
      localStorage.setItem("activeEnvironment", this.$globals.PROTO_ENV)
      this.$store.commit("setActiveEnvironment", this.$globals.PROTO_ENV);
      // console.log('proto this.environment: ', this.environment);
      if (this.$globals.ENV == this.$globals.DEV_ENV && this.validEnv.development.user != null) {
        localStorage.setItem("apiUrl", this.$globals.DEV_API_URL);
        this.$globals.API_URL = this.$globals.DEV_API_URL;
        localStorage.setItem("user", JSON.stringify(this.validEnv.development.user));
      } else if(this.validEnv.prototype.user != null) {
        localStorage.setItem("apiUrl", this.$globals.PROTO_API_URL);
        this.$globals.API_URL = this.$globals.PROTO_API_URL;
        localStorage.setItem("user", JSON.stringify(this.validEnv.prototype.user));
      }
      // console.log("env: ", this.$globals.ENV);
      this.$store.commit("auth/loginSuccess", this.validEnv.prototype.user);
      this.$router.push("/");
    },
    onProduct() {
      localStorage.setItem("activeEnvironment", this.$globals.PROD_ENV)
      this.$store.commit("setActiveEnvironment", this.$globals.PROD_ENV);
      // console.log('prod this.environment: ', this.environment);
      if (this.$globals.ENV == this.$globals.DEV_ENV && this.validEnv.development.user != null) {
        localStorage.setItem("apiUrl", this.$globals.DEV_API_URL);
        this.$globals.API_URL = this.$globals.DEV_API_URL;
        localStorage.setItem("user", JSON.stringify(this.validEnv.development.user));
      } else if(this.validEnv.product.user != null) {
        localStorage.setItem("apiUrl", this.$globals.PROD_API_URL);
        this.$globals.API_URL = this.$globals.PROD_API_URL;
        localStorage.setItem("user", JSON.stringify(this.validEnv.product.user));
      }
      // console.log("this.validEnv.product.user: ", this.validEnv.product.user);
      this.$store.commit("auth/loginSuccess", this.validEnv.product.user);
      this.$router.push("/");
    },
    onStaging() {
      localStorage.setItem("activeEnvironment", this.$globals.STAGE_ENV)
      this.$store.commit("setActiveEnvironment", this.$globals.STAGE_ENV);
      // console.log('stage this.environment: ', this.environment);
      if (this.$globals.ENV == this.$globals.DEV_ENV && this.validEnv.development.user != null) {
        localStorage.setItem("apiUrl", this.$globals.DEV_API_URL);
        this.$globals.API_URL = this.$globals.DEV_API_URL;
        localStorage.setItem("user", JSON.stringify(this.validEnv.development.user));
      } else if(this.validEnv.staging.user != null) {
        localStorage.setItem("apiUrl", this.$globals.STAGE_API_URL);
        this.$globals.API_URL = this.$globals.STAGE_API_URL;
        localStorage.setItem("user", JSON.stringify(this.validEnv.staging.user));
      }
      // console.log("env: ", this.$globals.ENV);
      this.$store.commit("auth/loginSuccess", this.validEnv.staging.user);
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.main-container {
  margin-top: 3%;
  width: 100%;
}
.alert-container {
  width: 100%;
  position: absolute;
}
.alert {
  width: 100%;
}
.box-container {
  padding: 20px;
  margin: 0 auto;
  width: 25rem;
  box-shadow: beige;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
}
.heading {
  text-align: center;
  font-weight: 300;
  color: #444;
  margin: 0 auto 45px;
  font-size: 35px;
  line-height: 38px;
  text-transform: none;
  letter-spacing: 0;
}
.form-fields,
.form-fields button {
  width: 100%;
  margin: 5px 0;
  border-radius: 5px;
}
/* .form-fields input {
  width: 100%;
  line-height: 40px;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  background: #fff;
  padding: 0 5px;
  font-size: 14px;
} */
.sign-absolute {
  padding: 20px;
  margin: 0 auto;
  width: 25rem;
  height: inherit;
  border-radius: 5px;
  position: absolute;
}
.login-choice span {
  color: #5b6987;
  display: -ms-grid;
  display: grid;
  font-size: 16px;
  width: 100%;
  line-height: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -ms-grid-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  grid-template-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  grid-gap: 19px;
}
.login-choice span:after,
.login-choice span:before {
  content: "";
  border-top: 1px solid #e5e8ed;
}
.footer,
.footer a {
  text-align: center;
  color: #000;
}
</style>
