import AuthService from "../services/auth.service.js";

const invalidUser = localStorage.getItem("user") == "undefined" || localStorage.getItem("user") == "";
const user = JSON.parse(invalidUser ? null : localStorage.getItem("user"));

const initialState = user != "{}" ? { status: { loggedIn: true }, user } : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    async signupEnvironment({ commit }, context) {
      const res = await AuthService.signup(context.user, context.environment);
      if (res.status == 200) {
        // 
      } else {
        commit("signupFailure");
      }
      return res;
    },
    register({ commit }, user) {
      return AuthService.signup(user).then(
        (response) => {
          commit("signupSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("signupFailure");
          return Promise.reject(error);
        }
      );
    },
    async loginEnvironment({ commit }, context) {
      const res = await AuthService.login(context.user, context.environment);
      if (res.status == 200) {
        // 
      } else {
        commit("loginFailure");
      }
      return res;
    },
    login({ commit }, user) {
      return AuthService.login(user).then(
        (data) => {
          if (data.status == 401) {
            commit("loginFailure");
            return Promise.reject("Bad credentials");
          }
          commit("loginSuccess", data);
          return Promise.resolve(data);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      localStorage.removeItem("activeEnvironment");
      localStorage.removeItem("userEnvironments");
      localStorage.removeItem("user");
      commit("logout");
    },
  },
  mutations: {
    loginSuccess(state, user) {
      // console.log('user loginSuccess: ', user);
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    signupSuccess(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    signupFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
  },
};
