var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"alert-container"},[(_vm.showMessage)?_c('v-alert',{staticClass:"alert",attrs:{"type":_vm.messageType,"dark":""}},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()],1),_c('v-row',{staticClass:"upload-file-container",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('form',{attrs:{"action":"#"}},[_c('v-card',{staticClass:"card",attrs:{"disabled":_vm.uploading}},[(_vm.uploading)?_c('v-overlay',{staticClass:"d-flex flex-column justify-center align-center class-absolute",attrs:{"value":_vm.uploading,"opacity":1}},[_c('v-progress-circular',{attrs:{"size":100,"width":10,"color":"lightgrey","indeterminate":""}})],1):_vm._e(),_c('h2',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.customerName))]),_c('v-card-title',{staticClass:"card-title"},[_vm._v("Upload Logger file")]),_c('v-file-input',{staticClass:"file-input",attrs:{"chips":"","counter":"","show-size":"","small-chips":"","truncate-length":"15","accept":".ll4"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),(_vm.sessionList != null && _vm.sessionList.length > 0)?_c('v-card',{staticClass:"card-details",staticStyle:{"border":"1px solid black"}},[_c('v-card-title',{staticClass:"card-title"},[_vm._v(" File Details ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"text-field",attrs:{"label":"Customer","readonly":"","maxlength":"30","dense":"","outlined":""},model:{value:(_vm.customerName),callback:function ($$v) {_vm.customerName=$$v},expression:"customerName"}},'v-text-field',attrs,false),on))]}}],null,false,1529761524)},[_c('span',[_vm._v("Customer name")])])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"text-field",attrs:{"label":"Test-case","readonly":"","maxlength":"30","dense":"","outlined":""},model:{value:(_vm.testcaseName),callback:function ($$v) {_vm.testcaseName=$$v},expression:"testcaseName"}},'v-text-field',attrs,false),on))]}}],null,false,3657988281)},[_c('span',[_vm._v("Testcase name")])])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"text-field",attrs:{"label":"Device Id","readonly":"","maxlength":"30","dense":"","outlined":""},model:{value:(_vm.deviceId),callback:function ($$v) {_vm.deviceId=$$v},expression:"deviceId"}},'v-text-field',attrs,false),on))]}}],null,false,2535812275)},[_c('span',[_vm._v("Device ID")])])],1)],1)],1):_vm._e(),(_vm.sessionList != null && _vm.sessionList.length > 0)?_c('v-card',{staticClass:"card-sessions",staticStyle:{"border":"1px solid black"}},[_c('v-card-title',{staticClass:"card-title"},[_vm._v(" Sessions ")]),_vm._l((_vm.sessionList),function(s,index){return _c('v-card',{key:index,staticClass:"card-session"},[_c('v-card-title',{class:s.errors > 0 ? 'card-title-invalid' : 'card-title-valid'},[_vm._v(_vm._s(s.errors > 0 ? "Invalid session" : "Valid session"))]),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"text-field",attrs:{"label":"Session Start","readonly":"","dense":"","outlined":""},model:{value:(s.startUtcStr),callback:function ($$v) {_vm.$set(s, "startUtcStr", $$v)},expression:"s.startUtcStr"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Session Start: "+_vm._s(s.startUtcStr))])])],1),_c('v-col',{attrs:{"cols":"4","offset":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"text-field",attrs:{"label":"Session End","readonly":"","dense":"","outlined":""},model:{value:(s.endUtcStr),callback:function ($$v) {_vm.$set(s, "endUtcStr", $$v)},expression:"s.endUtcStr"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Session End: "+_vm._s(s.endUtcStr))])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-textarea',_vm._g(_vm._b({attrs:{"label":"Session Description","maxlength":"200","counter":"200","disabled":s.errors > 0,"dense":"","outlined":"","rows":"1"},model:{value:(s.description),callback:function ($$v) {_vm.$set(s, "description", $$v)},expression:"s.description"}},'v-textarea',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Session Description: "+_vm._s(s.description))])])],1),_c('v-col',{attrs:{"cols":"2","offset":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"text-field",attrs:{"label":"Samples","readonly":"","dense":"","outlined":""},model:{value:(s.numberOfSamples),callback:function ($$v) {_vm.$set(s, "numberOfSamples", $$v)},expression:"s.numberOfSamples"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Number of samples: "+_vm._s(s.numberOfSamples))])])],1),_c('v-col',{attrs:{"cols":"2","offset":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({class:(s.errors > 0) ? 'text-field text-field-errors' : 'text-field',attrs:{"label":"Session Errors","readonly":"","dense":"","outlined":""},model:{value:(s.errors),callback:function ($$v) {_vm.$set(s, "errors", $$v)},expression:"s.errors"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Error: "+_vm._s(s.errorDescription))])])],1)],1)],1)})],2):_vm._e(),_c('v-row',{staticClass:"upload-button",attrs:{"justify":"end","dense":""}},[_c('v-btn',{attrs:{"color":"green","disabled":_vm.uploading || !_vm.file},on:{"click":_vm.uploadFile}},[_vm._v("Upload")])],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }