<template>
  <fusioncharts
    :type="type"
    :width="width"
    :height="height"
    :dataFormat="dataFormat"
    :dataSource="dataSource"
    :events="events"
    ref="fc"
  ></fusioncharts>
</template>

<script>
export default {
  name: "App",
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    data: {
      type: Array,
    },
    maxGainValue: {
      type: String,
    },
    minGainValue: {
      type: String,
    },
    linkeddata: {
      type: Array,
    },
  },
  data: function() {
    return {
      step: 0,
      localData: this.data,
      euLinkedData: [],
      type: "maps/world",
      // renderAt: "chart-container",
      dataFormat: "json",
      dataSource: {
        chart: {
          caption: this.title,
          subcaption: this.subTitle,
          bgColor: "#e0e0c0",
          theme: "fusion",
          formatNumberScale: "1",
          decimals: "1",
          showLabels: "1",
          nullEntityColor: "#C2C2D6",
          nullEntityAlpha: "50",
          hoverOnNull: "0",
          useSNameInLabels: "0",
          entityFillColor: "#A8A8A8",
          entityFillHoverColor: "#E5E5E9",
          chartLeftMargin: 0,
          chartRightMargin: 0,
          chartTopMargin: 0,
          chartBottomMargin: 0,
          showToolTip: 1,
          toolTipSepChar: ": ",
          numberSuffix: " mAh",
          entitytooltext: "<b>$lName</b><br>Gain: <b>$dataValue</b>",
          exportEnabled: 1,
          exportFormats: "PNG|JPG|PDF|XLSX",
        },
        entityDef: [
          {
            internalId: "NA",
            newId: "North America",
          },
          {
            internalId: "SA",
            newId: "South America",
          },
          {
            internalId: "EU",
            newId: "Europe",
          },
          {
            internalId: "WE",
            newId: "Western Europe",
          },
          {
            internalId: "AS",
            newId: "Asia",
          },
          {
            internalId: "AF",
            newId: "Africa",
          },
          {
            internalId: "AU",
            newId: "Australia",
          },
        ],
        colorrange: {
          gradient: 0,
          color: [
            {
              minvalue: 0,
              maxvalue: parseInt(this.minGainValue) + this.step,
              displayvalue: "Lowest Gain Values",
              code: "#e44a00",
            },
            {
              minvalue: parseInt(this.minGainValue) + this.step + 1,
              maxvalue: parseInt(this.minGainValue) + this.step * 2,
              displayvalue: "Average Gain Values",
              code: "#f8bd19",
            },
            {
              minvalue: parseInt(this.minGainValue) + this.step * 2 + 1,
              maxvalue: parseInt(this.maxGainValue),
              displayvalue: "Highest Gain Values",
              code: "#6baa01",
            },
          ],
          // startlabel: "Low",
          // endlabel: "High",
          // code: "#e44a00",
          // minvalue: parseInt(this.minGainValue),
          // gradient: 1,
          // color: [
          //   {
          //     maxvalue: (parseInt(this.maxGainValue) - parseInt(this.minGainValue)) / 2,
          //     displayvalue: "Average",
          //     code: "#f8bd19",
          //   },
          //   {
          //     maxvalue: parseInt(this.maxGainValue),
          //     code: "#6baa01",
          //   },
          // ],
        },

        data: this.data,
        linkeddata: this.linkeddata,
      },
      events: {
        entityclick: function (e) {
          console.log(e.data.originalId);
          if (e.data.originalId === "EU") {
            e.sender.configureLink([
              {
                type: "maps/europe",
              },
            ]);
          } else if (e.data.originalId === "AF") {
            e.sender.configureLink([
              {
                type: "maps/africa",
              },
            ]);
          } else if (e.data.originalId === "NA") {
            e.sender.configureLink([
              {
                type: "maps/northamerica",
              },
            ]);
          } else if (e.data.originalId === "SA") {
            e.sender.configureLink([
              {
                type: "maps/southamerica",
              },
            ]);
          } else if (e.data.originalId === "AS") {
            e.sender.configureLink([
              {
                type: "maps/asia",
              },
            ]);
          } else if (e.data.originalId === "AU") {
            e.sender.configureLink([
              {
                type: "maps/australia",
              },
            ]);
          }
        },
      },
    };
  },
  watch: {
    data(val) {
      this.localData = val;
    },
    linkeddata(val) {
      this.dataSource.linkeddata = val;
      // console.log('linked data: ', val);
    },
    maxGainValue(val) {
      // this.dataSource.colorrange.color[1].maxvalue = parseInt(val);
      this.step = parseInt(val) / 3;
      this.updateMinMaxLegends();
    },
    // minGainValue(val) {
    //   this.dataSource.colorrange.minvalue = parseInt(val);
    // },
    subTitle(val) {
      this.dataSource.chart.subcaption = val;
    },
  },
  mounted() {
    this.dataSource.data = this.data;
    this.step = parseInt(this.maxGainValue) / 3;
    this.updateMinMaxLegends();
    this.step = parseInt(this.minGainValue) / 3;
    // this.dataSource.colorrange.color[2].maxvalue = parseInt(this.maxGainValue);
    // this.dataSource.colorrange.minvalue = parseInt(this.minGainValue);
    this.dataSource.chart.subcaption = this.subTitle;
    this.dataSource.linkeddata = this.linkeddata;
    // console.log('linked data : ', this.linkeddata);

  },
  methods: {
    updateMinMaxLegends() {
      this.dataSource.colorrange.color[0].maxvalue = parseInt(this.minGainValue) + this.step;
      this.dataSource.colorrange.color[1].minvalue = parseInt(this.minGainValue) + this.step;
      this.dataSource.colorrange.color[1].maxvalue = parseInt(this.minGainValue) + this.step * 2;
      this.dataSource.colorrange.color[2].minvalue = parseInt(this.minGainValue) + this.step * 2;
      this.dataSource.colorrange.color[2].maxvalue = parseInt(this.maxGainValue);
    },
  },
};
</script>

<style></style>
