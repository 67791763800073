<template>
  <v-app id="exegerdata">
    <v-navigation-drawer v-if="currentUser" v-model="drawer" app clipped color="#eee">
      <v-list class="list-item" dense>
        <v-list-item to="/">
          <v-list-item-action>
            <v-icon>mdi-home-analytics</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Dashboard
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item v-if="isAdmin" to="/live-activity">
          <v-list-item-action>
            <v-icon>mdi-chart-line</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Live Activity
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item v-if="isAdmin || isPowerUser" to="/activity">
          <v-list-item-action>
            <v-icon>mdi-chart-bar</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Activity
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item to="/activity-multiple-headphones">
          <v-list-item-action>
            <v-icon>mdi-poll</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Activity Multiple Headphones
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item v-if="(isAdmin || isPowerUser) && (environment == protoEnv || environment == devEnv)" to="/logger-file-upload">
          <v-list-item-action>
            <v-icon>mdi-file-upload-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Logger File Upload
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item v-if="isAdmin" to="/configuration">
          <v-list-item-action>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Configuration
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item v-if="isAdmin && (environment == protoEnv || environment == devEnv)" to="/customer">
          <v-list-item-action>
            <v-icon>mdi-briefcase-account</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Customers
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="isAdmin && (environment == protoEnv || environment == devEnv)" to="/device">
          <v-list-item-action>
            <v-icon>mdi-solar-panel</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Testcases / Devices
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item v-if="isAdmin" to="/register-lora-device">
          <v-list-item-action>
            <v-icon>mdi-wifi-plus</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Register LoRa Device
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left color="#eee">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer />
      <v-toolbar-title>Exeger Data</v-toolbar-title>
      <v-spacer />
      <div v-if="!currentUser" class="ml-auto">
        <!-- <router-link to="/register" class="nav-link">
          <v-icon class="navbar-nav" color="success">mdi-account-plus</v-icon>
        </router-link> -->
        <router-link to="/login" class="nav-link">
          <v-icon class="navbar-nav" color="primary">mdi-login</v-icon>
        </router-link>
      </div>
      <div v-else class="ml-auto">
        <v-menu v-model="menu" :close-on-content-click="true" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" size="30" v-bind="attrs" v-on="on">
              {{ accountIcon }}
            </v-icon>
          </template>
          <v-card class="d-flex flex-column">
            <v-row>
              <v-col class="user-menu-items">
                <v-avatar color="blue" size="80">
                  <v-img :src="avatarPicture"> </v-img>
                </v-avatar>
              </v-col>
            </v-row>
            <v-list class="list-item" dense>
              <v-list-item disabled class="user-menu-items">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ currentUser.email }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="environment == stageEnv && isDeveloper" to="/user-settings">
                <v-list-item-action>
                  <v-icon>mdi-cog</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Settings
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-else to="/change-password">
                <v-list-item-action>
                  <v-icon>mdi-lock-reset</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Change Password
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-action>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Sign Out
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </div>
    </v-app-bar>

    <v-main class="main-app">
      <router-view />
    </v-main>

    <v-footer app>
      <span>Exeger &copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "exegerdata",
  data: () => ({
    drawer: null,
    menu: false,
  }),
  created() {
    // console.log(`this.currentUser: ${JSON.stringify(this.currentUser)}`);
    this.$vuetify.theme.dark = false;
    // console.log("app this.environment: ", this.environment);
  },
  computed: {
    environment() {
      return this.$store.getters.getActiveEnvironment != null ?
        this.$store.getters.getActiveEnvironment :
        localStorage.getItem("activeEnvironment");
    },
    prodEnv() {
      return this.$globals.PROD_ENV;
    },
    stageEnv() {
      return this.$globals.STAGE_ENV;
    },
    protoEnv() {
      return this.$globals.PROTO_ENV;
    },
    devEnv() {
      return this.$globals.DEV_ENV;
    },
    currentUser() {
      if (this.environment == null) {
        this.logout();
      }
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isAdmin() {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.roles.includes("ROLE_ADMIN");
      }
      return false;
    },
    isPowerUser() {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.roles.includes("ROLE_POWER_USER");
      }
      return false;
    },
    isDeveloper() {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.roles.includes("ROLE_DEVELOPER");
      }
      return false;
    },
    isUser() {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.roles.includes("ROLE_USER");
      }
      return false;
    },
    avatarPicture() {
      if (this.currentUser.picture != null) {
        return this.currentUser.picture;
      } else {
        return "./icon/avatar_2x.png";
      }
    },
    accountIcon() {
      return this.$store.state.auth.user.roles.includes("ROLE_ADMIN")
        ? "mdi-account-tie-hat"
        : this.$store.state.auth.user.roles.includes("ROLE_POWER_USER")
        ? "mdi-account-tie"
        : this.$store.state.auth.user.roles.includes("ROLE_DEVELOPER")
        ? "mdi-account-hard-hat"
        : "mdi-account";
    },
  },

  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(
        () => {
          // console.log("logout");
          if (this.$route.name != "Login") {
            this.$router.push("/login");
          }
        },
        (error) => {
          this.loading = false;
          this.message = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    },
  },
};
</script>

<style scoped>
.main-app {
  background-color: #fbfbfb;
}
.nav-link {
  text-decoration: none;
  color: inherit;
}
.list-item :hover {
  color: dodgerblue;
  background: lightcyan;
  cursor: pointer;
}
.toolbar-title-background {
  /* background-image: url(); */
}
.username {
  text-align: center;
  padding-top: 25px;
  font-size: 14px;
  /* padding-left: 20px;
  padding-right: 30px; */
}
.user-menu-items {
  text-align: center;
}
.btn-sign-out {
  margin-top: 20px;
  background-color: lightgrey;
  font-weight: 400;
  font-size: 10px;
}
</style>
