<template>
  <v-container>
    <v-row class="alert-container">
      <v-alert v-if="environment == 'development'" class="alert" type="warning">
        {{environment == "development" ? "Development Mode" : ""}}
      </v-alert>
    </v-row>
    <div v-if="environment == 'prototype'">
      <PrototypeAdminDashboard v-if="isAdmin" />
      <PrototypePowerUserDashboard v-else-if="isPowerUser" />
      <PrototypeUserDashboard v-else-if="isUser" />
    </div>
    <div v-else-if="environment == 'product'">
      <ProductAdminDashboard v-if="isAdmin" />
      <ProductPowerUserDashboard v-else-if="isPowerUser" />
      <ProductUserDashboard v-else-if="isUser" />
    </div>
    <div v-else-if="environment == 'staging'">
      <StagingDashboard />
    </div>
  </v-container>
</template>

<script>
import PrototypeAdminDashboard from "../components/dashboards/prototypeEnv/PrototypeAdminDashboard.vue";
import PrototypePowerUserDashboard from "@/components/dashboards/prototypeEnv/PrototypePowerUserDashboard.vue";
import PrototypeUserDashboard from "@/components/dashboards/prototypeEnv/PrototypeUserDashboard.vue";
import ProductAdminDashboard from "../components/dashboards/productEnv/ProductAdminDashboard.vue";
import ProductPowerUserDashboard from "@/components/dashboards/productEnv/ProductPowerUserDashboard.vue";
import ProductUserDashboard from "@/components/dashboards/productEnv/ProductUserDashboard.vue";
import StagingDashboard from "../components/dashboards/stagingEnv/StagingDashboard.vue";

export default {
  name: "Home",
  components: {
    PrototypeAdminDashboard,
    PrototypePowerUserDashboard,
    PrototypeUserDashboard,
    ProductAdminDashboard,
    ProductPowerUserDashboard,
    ProductUserDashboard,
    StagingDashboard,
  },
  data: () => ({}),

  created() {
    // console.log('home this.environment: ', this.environment);
  },

  computed: {
    environment() {
      return localStorage.getItem("activeEnvironment");
    },
    accessToken() {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    },
    isAdmin() {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.roles.includes("ROLE_ADMIN");
      }
      return false;
    },
    isPowerUser() {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.roles.includes("ROLE_POWER_USER");
      }
      return false;
    },
    isUser() {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.roles.includes("ROLE_USER");
      }
      return false;
    },
  },

  watch: {
  },

  methods: {},
};
</script>

<style>
.alert {
  width: 100%;
  text-align: center;
}
</style>
