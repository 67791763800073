<template>
  <fusioncharts
    :type="type"
    :width="width"
    :height="height"
    :dataFormat="dataFormat"
    :dataSource="dataSource"
    :dataEmptyMessage="dataEmptyMessage"
  ></fusioncharts>
  <!-- <fusioncharts
    :type="type"
    :width="width"
    :height="height"
    :dataFormat="dataFormat"
    :dataEmptyMessage="dataEmptyMessage"
    :dataSource="dataSource"
    ref="chartRef"
  ></fusioncharts> -->
</template>

<script>
export default {
  name: "Line2d",
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    dataSourceData: {
      type: Object,
    },
    dataEmptyMessageData: {
      type: String,
    },
  },
  data: function() {
    return {
      type: "msline",
      dataFormat: "json",
      loadMessage: "loading...",
      loadMessageColor: "#000000",
      dataEmptyMessage: "No data to display",
      dataSource: this.dataSourceData,
    };
  },

  watch: {
    dataSourceData(val) {
      this.dataSource = val;
    },
    dataEmptyMessageData(val) {
      this.dataEmptyMessage = val;
    },
  },

  mounted() {
    this.dataSource = this.dataSourceData;
    this.dataEmptyMessage = this.dataEmptyMessageData;
  },

  methods: {
  },
};
</script>

<style></style>
