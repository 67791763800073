<template>
  <v-container>
    <v-row class="alert-container">
      <v-alert v-if="showMessage" class="alert" :type="messageType" dark>
        {{ message }}
      </v-alert>
    </v-row>
    <div class="d-flex flex-column justify-space-between align-center">
      <h4 class="title">Register LoRa Device</h4>
      <img id="profile-img" src="../assets/lora_device.png" class="profile-img-card" />
      <v-form v-model="valid" lazy-validation>
        <v-card>
          <v-row class="fullwidth">
            <v-col cols="6">
              <v-card>
                <v-card-title>
                  Lora Gateway Settings
                </v-card-title>
                <v-select
                  v-model="loraApplicationSelected"
                  :items="loraApplicationListNames"
                  :rules="rules.notEmpty"
                  chips
                  small-chips
                  label="Application ID"
                  outlined
                ></v-select>
                <v-text-field v-model="description" label="Description" outlined></v-text-field>
                <v-text-field v-model="devEui" :rules="rules.rule8Hex" label="DevEUI" outlined></v-text-field>
                <v-select
                  v-model="loraDeviceProfileSelected"
                  :items="loraDeviceProfilesListNames"
                  :rules="rules.notEmpty"
                  label="Device Profile Id"
                  chips
                  small-chips
                  outlined
                ></v-select>
                <v-text-field v-model="deviceName" :rules="rules.notEmpty" label="Device name" outlined></v-text-field>
                <v-text-field
                  v-model="applicationKey"
                  :rules="rules.rule16Hex"
                  label="Application Key"
                  outlined
                ></v-text-field>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card>
                <v-card-title>
                  Device Settings
                </v-card-title>
                <v-text-field
                  v-model="deviceId"
                  label="Device Id"
                  :rules="rules.rule16"
                  placeholder="2021032900000112"
                  outlined
                ></v-text-field>
                <v-text-field v-model="cellId" label="Cell Id" :rules="rules.notEmpty" outlined></v-text-field>
                <v-text-field v-model="deviceType" label="Type" disabled outlined></v-text-field>
                <v-text-field
                  v-model="customerName"
                  label="Customer Name"
                  :rules="rules.notEmpty"
                  outlined
                ></v-text-field>
                <v-text-field v-model="modelName" label="Model Name" disabled outlined></v-text-field>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="btn-register" justify="end">
            <v-btn :disabled="!valid" color="success" @click="registerLoraDevice">
              REGISTER
            </v-btn>
          </v-row>
        </v-card>
      </v-form>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      deviceId: "",
      cellId: 111111,
      deviceType: "lora",
      modelName: "lora",
      customerName: "Exeger",
      deviceEnabled: true,
      loraApplicationSelected: null,
      loraApplication: {
        applicationId: null,
        applicationName: null,
      },
      loraApplicationList: null,
      loraApplicationListNames: [],
      loraDeviceProfileSelected: null,
      loraDeviceProfile: {
        deviceProfileId: null,
        deviceProfileName: null,
      },
      loraDeviceProfilesList: null,
      loraDeviceProfilesListNames: [],
      loraGetDeviceIntervalHandler: null,
      loraGetDeviceMaxAttempts: 3,
      description: "",
      devEui: "",
      deviceName: "",
      applicationKey: "",
      valid: false,
      rules: {
        notEmpty: [(v) => !!v || "Field is required!"],
        rule8Hex: [(v) => !!v || "Field is required!", (v) => v.length === 16 || "HEX encoded string 8 bytes long"],
        rule16Hex: [(v) => !!v || "Field is required!", (v) => v.length === 32 || "HEX encoded string 16 bytes long"],
        rule16: [(v) => !!v || "Field is required!", (v) => v.length === 16 || "Numeric value 16 digit long"],
      },
      message: null,
      messageType: null,
      showMessage: false,
    };
  },
  computed: {
    accessToken() {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    getLoraJwt() {
      return this.$store.getters.getLoraJwt;
    },
  },

  watch: {},

  created() {
    this.initLoraCommunication();
  },

  methods: {
    initLoraCommunication() {
      this.signinToLoraServer().then(async (resposne) => {
        const data = await resposne;
        console.log(`signinToLoraServer: ${data}`);
        this.getLoraApplications();
        this.getLoraDeviceProfiles();
      });
    },

    signinToLoraServer() {
      return new Promise((resolve, reject) => {
        var h = new Headers();
        h.append("Authorization", "Bearer " + this.accessToken);
        h.append("Content-Type", "application/json");
        h.append("Accept", "application/json");

        var requestOptions = {
          method: "GET",
          headers: h,
        };

        fetch(this.$globals.API_URL + "/lora-gateway/signin", requestOptions)
          .then(async (response) => {
            const data = await response.json();
            console.log(`got lora jwt: ${JSON.stringify(data)}`);
            this.$store.commit("setLoraJwt", data.jwt);
            return resolve(data);
          })
          .catch((error) => {
            console.log("error", error);
            return reject(error);
          });
      });
    },

    async getLoraApplications() {
      console.log("getLoraApplications");
      var h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      h.append("Grpc-Metadata-Authorization", "Bearer " + this.getLoraJwt);
      h.append("Accept", "application/json");

      var requestOptions = {
        method: "GET",
        headers: h,
      };

      try {
        const response = await fetch(this.$globals.API_URL + "/lora-gateway/applications?limit=10", requestOptions);
        const data = await response.json();
        if (data.success === true) {
          const loraRes = JSON.parse(data.message);
          const loraApps = loraRes.result;
          this.loraApplicationList = new Map();
          for (let i = 0; i < loraRes.totalCount; i += 1) {
            this.loraApplication.applicationId = loraApps[i].id;
            this.loraApplication.applicationName = loraApps[i].name;
            this.loraApplicationList.set(this.loraApplication.applicationName, this.loraApplication.applicationId);
            this.loraApplicationListNames.push(loraApps[i].name);
          }
        }
      } catch (error) {
        return console.log("error", error);
      }
    },

    async getLoraDeviceProfiles() {
      console.log("getLoraDeviceProfiles");
      var h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      h.append("Grpc-Metadata-Authorization", "Bearer " + this.getLoraJwt);
      h.append("Accept", "application/json");

      var requestOptions = {
        method: "GET",
        headers: h,
      };

      try {
        const response = await fetch(this.$globals.API_URL + "/lora-gateway/device-profiles?limit=10", requestOptions);
        const data = await response.json();
        if (data.success === true) {
          const loraRes = JSON.parse(data.message);
          const loraDevProf = loraRes.result;
          this.loraDeviceProfilesList = new Map();
          for (let i = 0; i < loraRes.totalCount; i += 1) {
            this.loraDeviceProfile.deviceProfileId = loraDevProf[i].id;
            this.loraDeviceProfile.deviceProfileName = loraDevProf[i].name;
            this.loraDeviceProfilesList.set(
              this.loraDeviceProfile.deviceProfileName,
              this.loraDeviceProfile.deviceProfileId
            );
            this.loraDeviceProfilesListNames.push(loraDevProf[i].name);
          }
        }
      } catch (error) {
        return console.log("error", error);
      }
    },

    registerLoraDevice() {
      this.registerLoraDeviceToLoraGateway().then(this.setApplicationKeyToDevice());
      this.registerLoraDeviceToExegerBackend();
    },

    registerLoraDeviceToLoraGateway() {
      return new Promise((resolve, reject) => {
        var h = new Headers();
        h.append("Authorization", "Bearer " + this.accessToken);
        h.append("Grpc-Metadata-Authorization", "Bearer " + this.getLoraJwt);
        h.append("Content-Type", "application/json");

        var payload = JSON.stringify({
          device: {
            applicationID: this.loraApplicationList.get(this.loraApplicationSelected),
            description: this.description,
            devEUI: this.devEui,
            deviceProfileID: this.loraDeviceProfilesList.get(this.loraDeviceProfileSelected),
            name: this.deviceName,
            referenceAltitude: 0,
            skipFCntCheck: true,
            tags: {},
            variables: {},
          },
        });

        console.log(JSON.stringify(payload));
        var requestOptions = {
          method: "POST",
          headers: h,
          body: payload,
        };

        fetch(this.$globals.API_URL + "/lora-gateway/devices", requestOptions)
          .then(async (response) => {
            const data = await response.json();
            console.log(JSON.stringify(data));
            return resolve(data);
          })
          .catch((error) => {
            console.log("error", error);
            return reject(error);
          });
      });
    },

    waitForLoraDeviceToBeCreated() {
      this.loraGetDeviceIntervalHandler = setInterval(this.loraDeviceCreated, 2 * 1000);
    },

    stopWaitingForLoraDeviceToBeCreated() {
      clearInterval(this.loraGetDeviceIntervalHandler);
    },

    loraDeviceCreated() {
      return new Promise((resolve, reject) => {
        this.loraGetDeviceMaxAttempts -= 1;
        console.log(`In loraDeviceCreated`);
        console.log(`this.loraGetDeviceMaxAttempts: ${this.loraGetDeviceMaxAttempts}`);
        var h = new Headers();
        h.append("Authorization", "Bearer " + this.accessToken);
        h.append("Grpc-Metadata-Authorization", "Bearer " + this.getLoraJwt);
        h.append("Content-Type", "application/json");

        var requestOptions = {
          method: "GET",
          headers: h,
        };

        fetch(this.$globals.API_URL + "/lora-gateway/devices/" + this.devEui, requestOptions)
          .then(async (response) => {
            const data = await response.json();
            console.log(`get device response: ${JSON.stringify(data)}`);
            if (response.ok) {
              return resolve();
            } else {
              return reject();
            }
          })
          .catch((error) => {
            console.log("error", error);
            return reject(error);
          });
      });
    },

    setApplicationKeyToDevice() {
      console.log(`In setApplicationKeyToDevice`);
      console.log(`this.loraGetDeviceMaxAttempts: ${this.loraGetDeviceMaxAttempts}`);
      this.loraDeviceCreated()
        .then(() => {
          var h = new Headers();
          h.append("Authorization", "Bearer " + this.accessToken);
          h.append("Grpc-Metadata-Authorization", "Bearer " + this.getLoraJwt);
          h.append("Content-Type", "application/json");

          var payload = JSON.stringify({
            deviceKeys: {
              appKey: "00000000000000000000000000000000",
              devEUI: this.devEui,
              genAppKey: "00000000000000000000000000000000",
              nwkKey: this.applicationKey,
            },
          });

          console.log(JSON.stringify(payload));
          var requestOptions = {
            method: "POST",
            headers: h,
            body: payload,
          };

          fetch(this.$globals.API_URL + "/lora-gateway/devices/device-keys", requestOptions)
            .then(async (response) => {
              const data = await response.json();
              console.log(JSON.stringify(data));
            })
            .catch((error) => {
              console.log("catch 1");
              console.log("error", error);
            });
        })
        .catch(() => {
          if (this.loraGetDeviceMaxAttempts > 0) {
            console.log("catch 2");
            this.setApplicationKeyToDevice();
          }
        });
    },

    registerLoraDeviceToExegerBackend() {
      return new Promise((resolve, reject) => {
        var h = new Headers();
        h.append("Authorization", "Bearer " + this.accessToken);
        h.append("Content-Type", "application/json");

        var payload = JSON.stringify({
          device_id: parseInt(this.deviceId),
          cell_id: parseInt(this.cellId),
          type: this.deviceType,
          model: this.modelName,
          lora_dev_eui: this.devEui,
          lora_dev_name: this.deviceName,
          customer_name: this.customerName,
          username: this.currentUser.username,
          enabled: this.deviceEnabled,
        });

        console.log(JSON.stringify(payload));
        var requestOptions = {
          method: "POST",
          headers: h,
          body: payload,
        };

        fetch(this.$globals.API_URL + "/device", requestOptions)
          .then(async (response) => {
            const data = await response.json();
            console.log(JSON.stringify(data));
            return resolve(data);
          })
          .catch((error) => {
            console.log("error", error);
            return reject(error);
          });
      });
    },
  },
};
</script>

<style>
.alert-container {
  width: 100%;
  position: absolute;
}
.alert {
  width: 100%;
}
.fullwidth {
  width: 900px;
}
.v-card {
  padding: 0.8em;
}
.btn-register {
  padding: 0.8em;
}
</style>
