import globals from "../globals/definitions.js";

class AuthService {
  async signup(user, env) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var payload = JSON.stringify({
      firstname: user.firstname,
      lastname: user.lastname,
      // "username": user.email,
      email: user.email,
      password: user.password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: payload,
      redirect: "follow",
    };

    try {
      let reqEndpoint =
        (env === "prototype" ? globals.PROTO_API_URL : env === "product" ? globals.PROD_API_URL : "") + "/auth/signup";
      return await fetch(reqEndpoint, requestOptions);
      // const data = await response.json();
      // if (data.accessToken) {
      //   localStorage.setItem('user', JSON.stringify(data));
      // }
      // return data;
    } catch (error) {
      return console.log("error", error);
    }
  }

  async login(user, env) {
    var myHeaders = new Headers();

    var payload = JSON.stringify({
      email: user.email,
      password: user.password,
    });
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: payload,
      redirect: "follow",
    };

    try {
      let reqEndpoint =
        (env === "prototype"
          ? globals.PROTO_API_URL
          : env === "product"
            ? globals.PROD_API_URL
            : env === "staging"
              ? globals.STAGE_API_URL
              : env === "development"
                ? globals.DEV_API_URL
                : "") + "/auth/signin";
      return await fetch(reqEndpoint, requestOptions);
      // const response = await fetch(reqEndpoint, requestOptions);
      // const data = await response.json();
      // console.log('login: ', data);
      // if (response.status == 200 && data.accessToken) {
      //   localStorage.setItem('user', JSON.stringify(data));
      // }
      // return data;
    } catch (error) {
      // return console.log("error", error);
      return console.error("Login Error");
    }
  }

  logout() {
    localStorage.removeItem("user");
  }
}

export default new AuthService();
