function getEuropeCountriesIds(countryCode) {
  // Countries' ids map source from Fusioncharts https://www.fusioncharts.com/dev/maps/spec-sheets/europe
  const euCountriesIdsMap = new Map([
    ["AL", { countryId: "001", label: "Albania" }],
    ["AD", { countryId: "002", label: "Andorra" }],
    ["AT", { countryId: "003", label: "Austria" }],
    ["BY", { countryId: "004", label: "Belarus" }],
    ["BE", { countryId: "005", label: "Belgium" }],
    ["BA", { countryId: "006", label: "Bosnia and Herzegovina" }],
    ["BG", { countryId: "007", label: "Bulgaria" }],
    ["HY", { countryId: "008", label: "Croatia" }],
    ["CZ", { countryId: "009", label: "Czech Republic" }],
    ["DK", { countryId: "010", label: "Denmark" }],
    ["EE", { countryId: "011", label: "Estonia" }],
    ["FI", { countryId: "012", label: "Finland" }],
    ["FR", { countryId: "013", label: "France" }],
    ["DE", { countryId: "014", label: "Germany" }],
    ["GR", { countryId: "015", label: "Greece" }],
    ["HU", { countryId: "016", label: "Hungary" }],
    ["IS", { countryId: "017", label: "Iceland" }],
    ["IE", { countryId: "018", label: "Ireland" }],
    ["IT", { countryId: "019", label: "Italy" }],
    ["LV", { countryId: "020", label: "Latvia" }],
    ["LI", { countryId: "021", label: "Liechtenstein" }],
    ["LT", { countryId: "022", label: "Lithuania" }],
    ["LU", { countryId: "023", label: "Luxembourg" }],
    ["MK", { countryId: "024", label: "Macedonia" }],
    ["MT", { countryId: "025", label: "Malta" }],
    ["MD", { countryId: "026", label: "Moldova" }],
    ["MC", { countryId: "027", label: "Monaco" }],
    ["MO", { countryId: "028", label: "Montenegro" }],
    ["NL", { countryId: "029", label: "Netherlands" }],
    ["NO", { countryId: "030", label: "Norway" }],
    ["PL", { countryId: "031", label: "Poland" }],
    ["PT", { countryId: "032", label: "Portugal" }],
    ["RO", { countryId: "033", label: "Romania" }],
    ["SM", { countryId: "034", label: "San Marino" }],
    ["CS", { countryId: "035", label: "Serbia" }],
    ["SK", { countryId: "036", label: "Slovakia" }],
    ["SL", { countryId: "037", label: "Slovenia" }],
    ["SI", { countryId: "037", label: "Slovenia" }], // This is added according to ISO-3166 Alpha-2 standard
    ["ES", { countryId: "038", label: "Spain" }],
    ["SE", { countryId: "039", label: "Sweden" }],
    ["CH", { countryId: "040", label: "Switzerland" }],
    ["UA", { countryId: "041", label: "Ukraine" }],
    ["UK", { countryId: "042", label: "United Kingdom" }],
    ["VA", { countryId: "043", label: "Vatican City" }],
    ["CY", { countryId: "044", label: "Cyprus" }],
    ["TK", { countryId: "045", label: "Turkey" }],
    ["RU", { countryId: "046", label: "Russia" }],
  ]);
  return euCountriesIdsMap.get(countryCode);
}

export { getEuropeCountriesIds };
