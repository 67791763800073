<template>
  <v-container>
    <v-row class="row-stats">
      <v-col class="column-stats" cols="3">
        <v-card class="justify-center">
          <v-card-text class="card-chart text-center">
            <doughnut-2d
              v-bind:width="x"
              v-bind:height="y"
              v-bind:caption="totalCustomersCaption"
              v-bind:subCaption="totalCustomersSubCaption"
              v-bind:data="totalCustomersData"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="column-stats" cols="3">
        <v-card class="justify-center">
          <v-card-text class="card-chart text-center">
            <doughnut-2d
              v-bind:width="x"
              v-bind:height="y"
              v-bind:caption="deviceTypesCaption"
              v-bind:subCaption="deviceTypesSubCaption"
              v-bind:data="deviceTypesData"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="column-stats" cols="6">
        <v-card class="justify-center">
          <v-card-text class="card-chart text-center">
            <doughnut-2d
              v-bind:width="x"
              v-bind:height="y"
              v-bind:caption="testcasesCaption"
              v-bind:subCaption="testcasesSubCaption"
              v-bind:data="testcasesData"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col class="column-stats" cols="3">
        <v-card>
          <v-card-title class="card-title">
            Prototypes that became Products
          </v-card-title>
          <v-card-text class="stats-card-text">
            2
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
    <v-row class="row-stats">
      <v-col class="column-stats" cols="6">
        <v-card class="justify-center">
          <v-card-text class="card-chart text-center">
            <sankey
              v-bind:width="x"
              v-bind:height="y"
              v-bind:caption="deviceTypesByCustomerCaption"
              v-bind:subCaption="deviceTypesByCustomerSubCaption"
              v-bind:nodes="deviceTypesByCustomerNodes"
              v-bind:links="deviceTypesByCustomerLinks"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="column-stats" cols="6">
        <v-card class="justify-center">
          <v-card-text class="card-chart text-center">
            <ms-column-2d
              v-bind:width="x"
              v-bind:height="y"
              v-bind:caption="protoToProdCaption"
              v-bind:subCaption="protoToProdSubCaption"
              v-bind:categories="protoToProdCategories"
              v-bind:dataset="protoToProdDataset"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="row-stats">
      <v-col class="column-stats" cols="4">
        <v-card class="justify-center">
          <v-card-text class="card-chart text-center">
            <doughnut-2d
              v-bind:width="x"
              v-bind:height="y"
              v-bind:caption="weekCaptionTotal"
              v-bind:subCaption="weekSubCaption"
              v-bind:data="weekTotalData"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="column-stats" cols="8">
        <v-card class="justify-center">
          <v-card-text class="card-chart text-center">
            <ms-column-2d
              v-bind:width="x"
              v-bind:height="y"
              v-bind:caption="weekCaptionByDay"
              v-bind:subCaption="weekCustomer"
              v-bind:categories="weekCategories"
              v-bind:dataset="weekDataset"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col class="column-stats" cols="4">
        <ms-column-2d
          v-bind:width="x"
          v-bind:height="y"
          v-bind:caption="weekCaption"
          v-bind:subCaption="weekSubCaption"
          v-bind:categories="weekCategories"
          v-bind:dataset="weekDataset"
        />
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import MsColumn2d from "@/components/charts/general/MsColumn2d.vue";
import Sankey from "@/components/charts/general/Sankey.vue";
import Doughnut2d from "@/components/charts/general/Doughnut2d.vue";

export default {
  name: "AdminDashboard",
  components: {
    MsColumn2d,
    Sankey,
    Doughnut2d,
  },
  data: () => ({
    // windowSize: {
    //   x: 0,
    //   y: 0,
    // },
    x: "100%",
    y: String((window.innerHeight - 200) * 0.33),
    totalCustomersCaption: "Customers",
    totalCustomersSubCaption: "Total 10",
    totalCustomersData: [
      {
        label: "Currently testing",
        color: "#005599",
        value: "6",
      },
      {
        label: "Inactive",
        color: "#ff0000",
        value: "4",
      },
    ],
    deviceTypesCaption: "Device types",
    deviceTypesSubCaption: "Total 15",
    deviceTypesData: [
      {
        label: "Became products",
        color: "#ffbb33",
        value: "12",
      },
      {
        label: "Failed",
        color: "#ff0000",
        value: "3",
      },
    ],
    testcasesCaption: "Testcases",
    testcasesSubCaption: "Total 17 - total prototype devices 55",
    testcasesData: [
      {
        label: "Active testing",
        color: "#005599",
        value: "8",
      },
      {
        label: "Testing on hold",
        color: "#ff0000",
        value: "2",
      },
      {
        label: "Testing finished",
        color: "#548975",
        value: "7",
      },
      {
        label: "Became product",
        color: "#eeaa33",
        value: "5",
      },
    ],
    weekCustomer: "",
    weekCaptionTotal: "Last week testing",
    weekCaptionByDay: "Last week testing by day",
    weekSubCaption: "",
    weekTotalData: [
      {
        label: "Gain",
        color: "#337700",
        value: "112",
      },
      {
        label: "Drain",
        color: "#ff2703",
        value: "108",
      },
    ],
    deviceTypesByCustomerCaption: "Device Types By Customer",
    deviceTypesByCustomerSubCaption: "",
    deviceTypesByCustomerNodes: [
      {
        label: "Adidas",
        color: "#ff0000",
      },
      {
        label: "Urbanista",
        color: "#eeaa33",
      },
      {
        label: "Headphones",
        // color: "#3345f7",
      },
      {
        label: "Tws",
        // color: "#77bb33",
      },
    ],
    deviceTypesByCustomerLinks: [
      {
        from: "Headphones",
        to: "Adidas",
        // color: "#ff0000",
        value: 20,
      },
      {
        from: "Headphones",
        to: "Urbanista",
        value: 50,
      },
      {
        from: "Tws",
        to: "Urbanista",
        value: 5,
      },
    ],
    protoToProdCaption: "Prototypes to Products",
    protoToProdSubCaption: "",
    protoToProdCategories: [
      {
        category: [
          {
            label: "Headphones",
          },
          {
            label: "Tws",
          },
          {
            label: "Dog harness",
          },
        ],
      },
    ],
    protoToProdDataset: [
      {
        seriesname: "Prototypes",
        color: "#334599",
        data: [
          {
            value: 15,
          },
          {
            value: 20,
          },
          {
            value: 17,
          },
        ],
      },
      {
        seriesname: "Products",
        color: "#eeaa33",
        data: [
          {
            value: 12,
          },
          {
            value: 10,
          },
          {
            value: 15,
          },
        ],
      },
    ],
    weekCategories: [
      {
        category: [
          {
            label: "Mon",
          },
          {
            label: "Tue",
          },
          {
            label: "Wed",
          },
          {
            label: "Thu",
          },
          {
            label: "Fri",
          },
          {
            label: "Sat",
          },
          {
            label: "Sun",
          },
        ],
      },
    ],
    weekDataset: [
      {
        seriesname: "Gain",
        color: "#337700",
        data: [
          {
            value: 15,
          },
          {
            value: 20,
          },
          {
            value: 17,
          },
          {
            value: 12,
          },
          {
            value: 11,
          },
          {
            value: 22,
          },
          {
            value: 25,
          },
        ],
      },
      {
        seriesname: "Drain",
        color: "#ff2703",
        data: [
          {
            value: 12,
          },
          {
            value: 10,
          },
          {
            value: 15,
          },
          {
            value: 11,
          },
          {
            value: 17,
          },
          {
            value: 20,
          },
          {
            value: 23,
          },
        ],
      },
    ],
  }),

  // created() {
  //   window.addEventListener("resize", this.onResize);
  // },
  // destroyed() {
  //   window.removeEventListener("resize", this.onResize);
  // },
  // mounted() {
  //   this.onResize();
  //   console.log("x: " + this.windowSize.x + ", y: " + this.windowSize.y);
  // },

  // methods: {
  //   onResize() {
  //     this.windowSize = { x: window.innerWidth, y: window.innerHeight };
  //     console.log('x: ', this.windowSize.x);
  //     console.log('y: ', this.windowSize.y);
  //   },
  // },
};
</script>

<style>
.row-stats {
  height: 100%;
  width: 100%;
  /* margin-left: -80px;
  margin-right: -50px; */
}
.column-stats {
  border-width: 1px !important;
  border-style: solid;
  border-color: #c7c7c7 !important;
}
.card-title {
  font-size: 0.9rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: -10px;
  margin-top: -20px;
}
.card-chart {
  margin-top: -20px;
  margin-bottom: -28px;
}
.stats-card-text {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}
</style>
