<template>
  <v-container>
    <v-row class="alert-container">
      <v-alert v-if="showMessage" class="alert" :type="messageType" dark>
        {{ message }}
      </v-alert>
    </v-row>
    <v-row class="upload-file-container" justify="center">
      <v-col cols="10">
        <form action="#">
          <v-card class="card" :disabled="uploading">
            <v-overlay
              v-if="uploading"
              class="d-flex flex-column justify-center align-center class-absolute"
              :value="uploading"
              :opacity="1"
            >
              <v-progress-circular :size="100" :width="10" color="lightgrey" indeterminate></v-progress-circular>
            </v-overlay>
            <h2 class="text-center">{{ customerName }}</h2>
            <v-card-title class="card-title">Upload Logger file</v-card-title>
            <v-file-input
              class="file-input"
              chips
              counter
              show-size
              small-chips
              truncate-length="15"
              v-model="file"
              accept=".ll4"
            ></v-file-input>
            <!-- <v-expand-transition> -->
            <v-card v-if="sessionList != null && sessionList.length > 0" class="card-details" style="border: 1px solid black;">
              <v-card-title class="card-title">
                File Details
              </v-card-title>
              <v-row>
                <v-col cols="4">
                  <!-- <v-text-field
                      label="File type"
                      class="text-field"
                      readonly
                      maxlength="30"
                      dense
                      v-model="fileType"
                    ></v-text-field> -->
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Customer"
                        class="text-field"
                        readonly
                        maxlength="30"
                        dense
                        v-model="customerName"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <span>Customer name</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="4">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Test-case"
                        class="text-field"
                        readonly
                        maxlength="30"
                        dense
                        v-model="testcaseName"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <span>Testcase name</span>
                  </v-tooltip>
                  <!-- <v-text-field
                    label="First Logging Date"
                    class="text-field"
                    readonly
                    dense
                    maxlength="50"
                    v-model="startDate"
                    outlined
                  ></v-text-field> -->
                </v-col>
                <v-col cols="4">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Device Id"
                        class="text-field"
                        readonly
                        maxlength="30"
                        dense
                        v-model="deviceId"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <span>Device ID</span>
                  </v-tooltip>
                  <!-- <v-text-field
                    label="Last Logging Date"
                    class="text-field"
                    readonly
                    dense
                    v-model="endDate"
                    outlined
                  ></v-text-field> -->
                </v-col>
              </v-row>
            </v-card>
            <!-- </v-expand-transition> -->
            <v-card v-if="sessionList != null && sessionList.length > 0" class="card-sessions" style="border: 1px solid black;">
              <v-card-title class="card-title">
                Sessions
              </v-card-title>
              <v-card v-for="(s, index) in sessionList" :key="index" class="card-session">
              <v-card-title
                :class="s.errors > 0 ? 'card-title-invalid' : 'card-title-valid'">{{s.errors > 0 ? "Invalid session" : "Valid session"}}</v-card-title>
                <v-row>
                  <v-col cols="4">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Session Start"
                          class="text-field"
                          readonly
                          dense
                          v-model="s.startUtcStr"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <span>Session Start: {{s.startUtcStr}}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="4" offset="4">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Session End"
                          class="text-field"
                          readonly
                          dense
                          v-model="s.endUtcStr"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <span>Session End: {{s.endUtcStr}}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-textarea
                          label="Session Description"
                          maxlength="200"
                          counter="200"
                          :disabled="s.errors > 0"
                          dense
                          outlined
                          rows="1"
                          v-model="s.description"
                          v-bind="attrs"
                          v-on="on"
                        ></v-textarea>
                      </template>
                      <span>Session Description: {{s.description}}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="2" offset="2">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Samples"
                          class="text-field"
                          readonly
                          dense
                          v-model="s.numberOfSamples"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <span>Number of samples: {{s.numberOfSamples}}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="2" offset="2">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Session Errors"
                          :class="(s.errors > 0) ? 'text-field text-field-errors' : 'text-field'"
                          readonly
                          dense
                          v-model="s.errors"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <span>Error: {{ s.errorDescription }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>
            <v-row class="upload-button" justify="end" dense>
              <v-btn color="green" :disabled="uploading || !file" @click="uploadFile">Upload</v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
    <!-- <v-progress-linear>
    </v-progress-linear> -->
  </v-container>
</template>

<script>
// import JSZip from 'JSZip';

export default {
  components: {},

  data: () => ({
    fileType: null,
    file: null,
    fwVersion: null,
    deviceId: null,
    testcaseId: null,
    session: {
      startUtc: null,
      endUtc: null,
      numberOfSamples: 0,
    },
    sessionList: [],
    cellId: null,
    customerName: null,
    testcaseName: null,
    startDate: null,
    endDate: null,
    message: null,
    messageType: null,
    showMessage: false,
    uploading: false,
  }),

  created() {
    this.fetchUserDetails();
  },

  computed: {
    accessToken() {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    },
    userId() {
      return JSON.parse(localStorage.getItem("user")).id;
    },
    apiUrl() {
      return localStorage.getItem("apiUrl");
    },
  },

  watch: {
    file(newValue) {
      this.showMessage = false;
      this.message = "";
      this.uploading = false;
      this.fileType = "";
      this.deviceId = "";
      this.cellId = "";
      this.customerName = "";
      this.testcaseName = "";
      this.startDate = "";
      this.endDate = "";
      this.sessionList = [];
      if (newValue != "" && newValue != null) {
        this.parseBinaryFileHeader();
      }
    },
    async testcaseId(newValue) {
      const testcase = await this.fetchTestcaseById(newValue);
      if (testcase != null) {
        this.testcaseName = testcase.name;
        this.customerName = testcase.customer_name;
      }
    },
    // deviceId() {
    //   this.fetchDeviceDetailsFromDeviceId();
    // },
  },

  methods: {
    // getFileTypeByCode(code) {
    //   switch (code) {
    //     case "HP":
    //       return "headphones";
    //     default:
    //       "";
    //   }
    // },

    blockCopy(src, dst, count) {
      for (let i = 0, j = 0; i < count; i += 4, j += 1) {
        dst[j] = (src[i + 3] << 24) | (src[i + 2] << 16) | (src[i + 1] << 8) | src[i];
      }
      return dst;
    },

    byteArrayToInt(rawData, startPos) {
      return (rawData[startPos + 1] << 8) | rawData[startPos];
    },
    byteArrayToLong(rawData, startPos) {
      return (
        (rawData[startPos + 3] << 24) | (rawData[startPos + 2] << 16) | (rawData[startPos + 1] << 8) | rawData[startPos]
      );
    },
    parseFileHeaders(rawData, decodedData) {
      let isDataCorrect = false;
      let idxFwVersion = 28;
      let idxDeviceId = 20;
      let idxTestcaseId = 24;
      let idxDtSessionStart = -1;
      const FILE_DATA_MagicWord_START_1 = 0x91111111;
      const FILE_DATA_MagicWord_START_2 = 0xa2222222;
      const FILE_DATA_MagicWord_START_3 = 0xc4444444;
      const FILE_DATA_MagicWord_START_4 = 0x88888888;
      const FILE_DATA_MagicWord_END_1 = 0x45524548;
      const FILE_DATA_MagicWord_END_2 = 0x5f53444e;
      const FILE_DATA_MagicWord_END_3 = 0x455f4b43;
      const FILE_DATA_MagicWord_END_4 = 0x4f4c4200;

      //        Session session = null;
      //        List<LL4BlockData> blockDataList = new ArrayList<>();
      //        LL4BlockData blockData;
      //        let sessionErrorCnt = 0;
      //        let blockDataErrorCnt = 0;

      this.fwVersion = this.byteArrayToInt(rawData, idxFwVersion);
      this.deviceId = this.byteArrayToLong(rawData, idxDeviceId);
      this.testcaseId = this.byteArrayToLong(rawData, idxTestcaseId);
      console.log("fwVersion: ", this.fwVersion);
      console.log("deviceId: ", this.deviceId);
      console.log("testcaseId: ", this.testcaseId);
      console.log("start1: ", FILE_DATA_MagicWord_START_1);
      console.log("start2: ", FILE_DATA_MagicWord_START_2);
      console.log("decodeData[0]: ", decodedData[10]);
      if (decodedData[10] == FILE_DATA_MagicWord_START_1) {
        console.log("equals");
      }
      console.log("continued");
      let session = {
        startUtc: null,
        endUtc: null,
        startUtcStr: null,
        endUtcStr: null,
        description: null,
        numberOfSamples: 0,
        errors: 0,
        errorDescription: null,
      };
      for (let i = 0; i < decodedData.length - 1; i++) {
        if (decodedData[i] == FILE_DATA_MagicWord_START_1 && decodedData[i + 1] == FILE_DATA_MagicWord_START_2) {
          idxDtSessionStart = i + 3;
          console.log("idxDtSessionStart: ", idxDtSessionStart);
        }
        if (decodedData[i] == FILE_DATA_MagicWord_START_3 && decodedData[i + 1] == FILE_DATA_MagicWord_START_4) {
          // Found the start of data header
          // blockData.setIdxDataStart(i + 2);
          let currentSessionDt;
          let firstBlockDt;
          let endBlockDt;
          let wrongStartDate = false;
          currentSessionDt = this.decodeRtcToDt(decodedData[idxDtSessionStart]);
          if (currentSessionDt.getTime() <= new Date(2000, 0, 1).getTime()) {
            session.errors++;
            wrongStartDate = true;
            session.errorDescription = "Invalid start date value!";
            // sessionErrorCnt++;
          }
          console.log("currentSessionDt: ", currentSessionDt.getTime());
          if (this.sessionList.length > 0) {
            console.log("session list length: ", this.sessionList.length);
            console.log("last sessionDt: ", this.sessionList[this.sessionList.length - 1].startUtc.getTime());
          }
          if (
            this.sessionList.length == 0 ||
            this.sessionList[this.sessionList.length - 1].startUtc.getTime() != currentSessionDt.getTime()
          ) {
            session = {
              startUtc: null,
              endUtc: null,
              startUtcStr: null,
              endUtcStr: null,
              description: null,
              numberOfSamples: 0,
              errors: 0,
              errorDescription: null,
            };
            if ((this.startDate == null || this.startDate == "") && !wrongStartDate) {
              this.startDate = new Date(session.startUtc);
              // console.log("start datetime local: ", this.startDate.toLocaleString("sv-SE"));
            }
            this.sessionList.push(session);
          }

          firstBlockDt = this.decodeRtcToDt(decodedData[idxDtSessionStart + 1]);
          if (firstBlockDt.getTime() <= new Date(2000, 0, 1).getTime()) {
            session.errors++;
            wrongStartDate = true;
            session.errorDescription = "Invalid start session date value!";
            session.startUtcStr = "Invalid date";
            // sessionErrorCnt++;
          } else {
            session.startUtc = firstBlockDt;
            session.startUtcStr = firstBlockDt.toLocaleString("sv-SE");
          }

          // blockData.setDtStartBlock(firstBlockDt);
          endBlockDt = this.decodeRtcToDt(decodedData[idxDtSessionStart + 2]);
          if (endBlockDt.getTime() <= new Date(2000, 0, 1).getTime()) {
            session.errors++;
            session.errorDescription = "Invalid end session date value!";
            session.endUtcStr = "Invalid date";
            // sessionErrorCnt++;
          } else {
            this.endDate = new Date(session.endUtc);
            session.endUtcStr = endBlockDt.toLocaleString("sv-SE");
            session.endUtc = endBlockDt;
          }
          // blockData.setDtEndBlock(endBlockDt);

          let blockFirstSampleIdx = decodedData[idxDtSessionStart + 3]; // The index of the data sample of the first sample in current block
          let blockLastSampleIdx = decodedData[idxDtSessionStart + 4]; // The index of the data sample of the last sample in current block
          console.log("first sample idx: ", blockFirstSampleIdx);
          console.log("last sample idx: ", blockLastSampleIdx);

          session.numberOfSamples += blockLastSampleIdx - blockFirstSampleIdx + 1;

          console.log("number of samples: ", session.numberOfSamples);
          console.log("sessionlist: ", this.sessionList);

          // Search for the end of data header
          for (i = i + 2; i < decodedData.length - 1; i++) {
            if (
              decodedData[i] == FILE_DATA_MagicWord_END_1 &&
              decodedData[i + 1] == FILE_DATA_MagicWord_END_2 &&
              decodedData[i + 3] == FILE_DATA_MagicWord_END_3 &&
              decodedData[i + 4] == FILE_DATA_MagicWord_END_4
            ) {
              // blockData.setIdxDataEnd(i - 1);
              isDataCorrect = true;
              if (i + 5 < decodedData.length) {
                i = i + 4;
              } else {
                i = decodedData.length - 1;
              }
              break;
            }
          }
          //if(session != null) {
          //    session.blockDataList.add(blockData);
          //}
        }
      }
      if (!isDataCorrect) {
        return false;
      }

      return isDataCorrect;
    },

    decodeRtcToDt(dateLong) {
      let year = 0;
      let month = 0;
      let day = 0;
      let hour = 0;
      let minute = 0;
      let second = 0;

      try {
        year = ((dateLong >> 26) & 0b0000000000111111) + 2000;
        month = (dateLong >> 22) & 0b0000000000001111;
        day = (dateLong >> 17) & 0b0000000000011111;
        hour = (dateLong >> 12) & 0b0000000000011111;
        minute = (dateLong >> 6) & 0b0000000000111111;
        second = (dateLong >> 0) & 0b0000000000111111;

        if (year > 2000 && year <= 2040) {
          if (month > 0 && month <= 12) {
            if (day > 0 && day <= 31) {
              if (hour >= 0 && hour < 24) {
                if (minute >= 0 && minute < 60) {
                  if (second >= 0 && second < 60) {
                    let dt = new Date();
                    dt.setUTCFullYear(year);
                    dt.setUTCMonth(month - 1);
                    dt.setUTCDate(day);
                    dt.setUTCHours(hour);
                    dt.setUTCMinutes(minute);
                    dt.setUTCSeconds(second);

                    return dt; // new Date(year, month - 1, day, hour, minute, second);
                  }
                }
              }
            }
          }
        }
      } catch (Exception) {
        //throw;
      }
      return new Date(0, 0, 0);
    },

    parseBinaryFileHeader() {
      let reader = new FileReader();

      reader.readAsArrayBuffer(this.file);

      reader.onload = function() {
        const rawData = new Uint8Array(reader.result);
        let decodedData = new Uint32Array(rawData.length / 4);
        console.log("rawData length: ", rawData.length);
        console.log("rawData: ", rawData);
        this.blockCopy(rawData, decodedData, rawData.length);
        console.log("decodedData: ", decodedData);
        this.parseFileHeaders(rawData, decodedData);
      }.bind(this);
    },

    // parseTextFileHeader() {
    //   let reader = new FileReader();

    //   reader.readAsText(this.file);

    //   let res = [];
    //   reader.onload = function() {
    //     var endIndex = reader.result.lastIndexOf(";");
    //     const contents = reader.result.substr(0, endIndex);
    //     res = contents.split(";");
    //     this.fileType = this.getFileTypeByCode(res[0]);
    //     this.deviceId = res[2];
    //     this.cellId = res[3];
    //     this.startDate =
    //       res[4].substr(0, 4) +
    //       "-" +
    //       res[4].substr(4, 2) +
    //       "-" +
    //       res[4].substr(6, 2) +
    //       " " +
    //       res[4].substr(8, 2) +
    //       ":" +
    //       res[4].substr(10, 2) +
    //       ":" +
    //       res[4].substr(12, 2);
    //     this.endDate =
    //       res[7].substr(0, 4) +
    //       "-" +
    //       res[7].substr(4, 2) +
    //       "-" +
    //       res[7].substr(6, 2) +
    //       " " +
    //       res[7].substr(8, 2) +
    //       ":" +
    //       res[7].substr(10, 2) +
    //       ":" +
    //       res[7].substr(12, 2);
    //   }.bind(this);
    // },

    async fetchUserDetails() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      // const apiUrl = (this.$globals.ENV == "prototype" ? this.$globals.PROTO_API_URL : this.$globals.PROD_API_URL);
      console.log("env: ", this.$globals.ENV);
      reqEndpoint = this.apiUrl + this.$globals.RES_USER + `/${this.userId}`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }

      // const data = await response.json();
      // this.customerName = data.customer_name;
    },

    async fetchDeviceDetailsFromDeviceId() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      // const apiUrl = (this.$globals.ENV == "prototype" ? this.$globals.PROTO_API_URL : this.$globals.PROD_API_URL);
      reqEndpoint = this.apiUrl + this.$globals.RES_DEVICE + `/${this.deviceId}`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }

      this.testcaseName = null;
      const data = await response.json();
      console.log("device data: ", data);
      const testcase = await this.fetchTestcaseById(data.testcase_id);
      if (testcase != null) {
        this.testcaseName = testcase.name;
      }
      this.customerName = data.customer_name;
    },

    async fetchTestcaseById(id) {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      // const apiUrl = (this.$globals.ENV == "prototype" ? this.$globals.PROTO_API_URL : this.$globals.PROD_API_URL);
      reqEndpoint = this.apiUrl + this.$globals.RES_TESTCASE + `/${id}`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }

      const data = await response.json();
      return data;
    },

    async checkExistingData() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);

      let path = "";
      path += "/" + this.deviceId;
      if (this.startDate != null && this.endDate != "") {
        console.log("start: ", this.startDate);
        console.log("end: ", this.endDate);
        // const fromTime =
        //   this.startDate
        //     .getHours()
        //     .toString()
        //     .padStart(2, "0") +
        //   ":" +
        //   this.startDate
        //     .getMinutes()
        //     .toString()
        //     .padStart(2, "0") +
        //   ":" +
        //   this.startDate
        //     .getSeconds()
        //     .toString()
        //     .padStart(2, "0");
        // const toTime =
        //   this.endDate
        //     .getHours()
        //     .toString()
        //     .padStart(2, "0") +
        //   ":" +
        //   this.endDate
        //     .getMinutes()
        //     .toString()
        //     .padStart(2, "0") +
        //   ":" +
        //   this.endDate
        //     .getSeconds()
        //     .toString()
        //     .padStart(2, "0");
        path += "?fromDate=" + this.startDate.toISOString().substr(0, 19) + ".000Z"; //.toLocaleDateString("sv-SE") + "T" + fromTime + ".000Z";
        console.log("?fromDate: ", path);
        path += "&toDate=" + this.endDate.toISOString(); //.toLocaleDateString("sv-SE") + "T" + toTime + ".000Z";
      } else {
        // Empty or corrupted file
      }

      let reqEndpoint = "";
      reqEndpoint = this.apiUrl + `/device-events${path}`;
      console.log("checkExistingData reqEndpoint: ", reqEndpoint);
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      let totalDatapoints = 0;
      this.sessionList.forEach((session) => {
        totalDatapoints += session.numberOfSamples;
      });
      console.log("checkExistingData datapoint: ", data);
      console.log("data length: ", data.length);
      console.log("totalDatapoints: ", totalDatapoints);
      if (data.length == totalDatapoints) {
        return true;
      }
      return false;
    },

    async uploadFile() {
      const fromDayTime = this.startDate.toLocaleString("sv-SE");
      const toDayTime = this.endDate.toLocaleString("sv-SE");
      const dataExist = await this.checkExistingData();
      if (dataExist) {
        this.showMessage = true;
        this.message = "The data has already been uploaded";
        this.messageType = "success";
        console.log("commit start date: ", this.startDate.toLocaleString("sv-SE"));
        window.setTimeout(() => {
          this.showMessage = false;
          this.$store.commit("setStoreDeviceId", this.deviceId);
          this.$store.commit("setStoreDeviceType", this.fileType.toLowerCase());
          this.$store.commit("setStoreBrandName", this.customerName);
          this.$store.commit("setStoreSession", this.session);
          this.$store.commit("setStoreDeviceId", this.deviceId);
          this.$store.commit("setStoreStartDate", fromDayTime);
          this.$store.commit("setStoreEndDate", toDayTime);
          this.$router.push("/activity");
        }, 5000);
        return;
      }

      // console.log("SessionList: ", this.sessionList);
      var formdata = new FormData();
      formdata.append("file", this.file);
      // formdata.append("sessions", JSON.stringify(this.sessionList));

      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);

      var requestOptions = {
        method: "POST",
        headers: h,
        body: formdata,
        redirect: "follow",
      };

      this.uploading = true;

      try {
        const response = await fetch(this.apiUrl + `/device-logger-events-upload`, requestOptions);
        if (response.status == 401) {
          this.$store.dispatch("auth/logout").then(
            () => {
              this.$router.push("/login");
            },
            (error) => {
              this.loading = false;
              this.message = (error.response && error.response.data) || error.message || error.toString();
            }
          );
        }
        this.showMessage = true;
        const data = await response.json();
        this.message = data.message;
        this.uploading = false;
        if (data.success) {
          this.messageType = "success";
        } else {
          this.messageType = "error";
        }
        window.setTimeout(() => {
          this.showMessage = false;
          if (data.success) {
            this.$store.commit("setStoreDeviceId", this.deviceId);
            this.$store.commit("setStoreDeviceType", this.fileType.toLowerCase());
            this.$store.commit("setStoreBrandName", this.customerName);
            this.$store.commit("setStoreSession", this.session);
            this.$store.commit("setStoreDeviceId", this.deviceId);
            this.$store.commit("setStoreStartDate", fromDayTime);
            this.$store.commit("setStoreEndDate", toDayTime);
            this.$router.push("/activity");
          }
        }, 5000);
      } catch (error) {
        console.log("error", error);
        this.uploading = false;
        this.message = "Error: " + error;
        this.uploading = false;
        this.messageType = "error";
        window.setTimeout(() => {
          this.showMessage = false;
        }, 5000);
      }
      // fetch(
      //   this.apiUrl +
      //     `/device-logger-events-upload`,
      //   requestOptions
      // )
      //   .then(async (response) => {
      //     if (response.status == 401) {
      //       this.$store.dispatch("auth/logout").then(
      //         () => {
      //           this.$router.push("/login");
      //         },
      //         (error) => {
      //           this.loading = false;
      //           this.message = (error.response && error.response.data) || error.message || error.toString();
      //         }
      //       );
      //     }
      //     this.showMessage = true;
      //     const data = await response.json();
      //     this.message = data.message;
      //     this.uploading = false;
      //     if (data.success) {
      //       this.messageType = "success";
      //     } else {
      //       this.messageType = "error";
      //     }
      //     window.setTimeout(() => {
      //       this.showMessage = false;
      //       if (data.success) {
      //         this.$store.commit("setStoreDeviceType", this.fileType.toLowerCase());
      //         this.$store.commit("setStoreBrandName", this.customerName);
      //         this.$store.commit("setStoreSession", this.session);
      //         this.$store.commit("setStoreDeviceId", this.deviceId);
      //         this.$store.commit("setStoreStartDate", this.startDate.substr(0, 10));
      //         this.$store.commit("setStoreEndDate", this.endDate.substr(0, 10));
      //         this.$router.push("/activity");
      //       }
      //     }, 5000);
      //   })
      //   .catch((error) => {
      //     console.log("error", error);
      //     this.uploading = false;
      //     this.message = "Error: " + error;
      //     this.uploading = false;
      //     this.messageType = "error";
      //     window.setTimeout(() => {
      //       this.showMessage = false;
      //     }, 5000);
      //   });
    },
    getUploadProgress() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let options = {
        method: "GET",
        headers: h,
        redirect: "follow",
      };

      fetch(this.apiUrl + "/upload_progress", options)
        .then(async (response) => {
          if (response.status == 401) {
            this.$store.dispatch("auth/logout").then(
              () => {
                this.$router.push("/login");
              },
              (error) => {
                this.loading = false;
                this.message = (error.response && error.response.data) || error.message || error.toString();
              }
            );
          }
          const data = await response.json();
          // console.log(`data: ${JSON.stringify(data)}`);
          for (let i = 0; i < data.length; i += 1) {
            this.deviceTypes.push(data[i]);
          }
        })
        .catch((e) => {
          console.log(`Error : ${e}`);
          this.$router.push("login");
        });
    },
  },
};
</script>

<style>
.alert-container {
  width: 100%;
  position: absolute;
  z-index: 1;
}
.alert {
  width: 100%;
}
.upload-file-container {
  padding-top: 30px;
  position: relative;
  z-index: 0;
}
.file-input {
  padding: 0.8em;
}
.card-title {
  justify-content: center;
  font-size: 1.3em;
}
.card-title-valid {
  justify-content: center;
  color: green;
}
.card-title-invalid {
  justify-content: center;
  color: red;
}
.card-details {
  margin-bottom: 10px;
}
.card-sessions {
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 10px;
  border: "1px solid black";
}
.card-session {
  margin-bottom: 2px;
}
.text-field {
  margin: 0.8em;
  padding-left: 2vmin;
}
.text-field-errors input {
  color: red !important;
}
.upload-button {
  padding: 0.8em;
  margin-top: 10px;
}
.class-absolute {
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>
