<template>
  <v-container>
    <!-- <v-row>
      <v-col class="heat-map" cols="12" sm="4" md="4" lg="4" xl="4">
        <heat-map-chart
          v-bind:width="x"
          v-bind:height="y"
          v-bind:data="heatMapEuropeData"
          v-bind:title="heatMapEuropeTitle"
        />
      </v-col>
      <v-col class="heat-map" cols="12" sm="4" md="4" lg="4" xl="4">
        <heat-map-chart v-bind:width="x" v-bind:height="y" v-bind:data="heatMapUSData" v-bind:title="heatMapUSTitle" />
      </v-col>
      <v-col class="heat-map" cols="12" sm="4" md="4" lg="4" xl="4">
        <heat-map-chart
          v-bind:width="x"
          v-bind:height="y"
          v-bind:data="heatMapSouthAmericaData"
          v-bind:title="heatMapSouthAmericaTitle"
        />
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
        <v-container class="graph-container">
          <v-overlay
            v-if="fetchingTotalCustomers"
            class="d-flex justify-center graph-absolute"
            :value="fetchingTotalCustomers"
            :opacity="0.3"
          >
            <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
          </v-overlay>
          <v-card color="#e0e0c0" :min-height="minTotalsHeight">
            <v-card-title class="card-title"> Total Customers: {{ totalCustomers }} </v-card-title>
          </v-card>
        </v-container>
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
        <v-container class="graph-container">
          <v-overlay
            v-if="fetchingTotalUsers"
            class="d-flex justify-center graph-absolute"
            :value="fetchingTotalUsers"
            :opacity="0.3"
          >
            <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
          </v-overlay>
          <v-card color="#e0e0c0" :min-height="minTotalsHeight" @click="onNewUsersData">
            <v-card-title class="card-title"> Total Users: {{ totalUsers }} </v-card-title>
          </v-card>
        </v-container>
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
        <v-container class="graph-container">
          <v-overlay
            v-if="fetchingTotalDevices"
            class="d-flex justify-center graph-absolute"
            :value="fetchingTotalDevices"
            :opacity="0.3"
          >
            <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
          </v-overlay>
          <v-card color="#e0e0c0" :min-height="minTotalsHeight" @click="onNewDevicesData">
            <v-card-title class="card-title"> Total Devices: {{ totalDevices }} </v-card-title>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-model="showNewUsersData" max-width="50%" :overlay-opacity="0.8">
      <v-container class="graph-container">
        <v-overlay
          v-if="fetchingNewUsersData"
          class="d-flex justify-center graph-absolute"
          :value="fetchingNewUsersData"
          :opacity="0.3"
        >
          <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
        </v-overlay>
        <v-card class="card-graph" color="#e0e0c0" :min-height="minGraphHeight" width="100%">
          <line-2d
            v-bind:width="newUsersGraphWidth"
            v-bind:height="newUsersGraphHeight"
            v-bind:dataSourceData="newUsersDataSource"
            v-bind:dataEmptyMessageData="newUsersDataEmptyMessage"
          />
        </v-card>
      </v-container>
    </v-dialog>
    <v-dialog v-model="showNewDevicesData" max-width="50%" :overlay-opacity="0.8">
      <v-container class="graph-container">
        <v-overlay
          v-if="fetchingNewDevicesData"
          class="d-flex justify-center graph-absolute"
          :value="fetchingNewDevicesData"
          :opacity="0.3"
        >
          <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
        </v-overlay>
        <v-card class="card-graph" color="#e0e0c0" :min-height="minGraphHeight" width="100%">
          <line-2d
            v-bind:width="newDevicesGraphWidth"
            v-bind:height="newDevicesGraphHeight"
            v-bind:dataSourceData="newDevicesDataSource"
            v-bind:dataEmptyMessageData="newDevicesDataEmptyMessage"
          />
        </v-card>
      </v-container>
    </v-dialog>
    <v-row>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-container class="graph-container">
          <v-overlay
            v-if="fetchingYearlyData"
            class="d-flex justify-center graph-absolute"
            :value="fetchingYearlyData"
            :opacity="0.3"
          >
            <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
          </v-overlay>
          <v-card color="#e0e0c0" :min-height="minAggregatedHeight" @click="onShowYearlyData">
            <v-card-title class="card-title">
              Yearly Data
            </v-card-title>
            <v-card-text class="card-text">
              <b>Gain:</b> {{ yearlyGainStr }}<br />
              <b>Drain:</b> {{ yearlyDrainStr }}
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-container class="graph-container">
          <v-overlay
            v-if="fetchingMonthlyData"
            class="d-flex justify-center graph-absolute"
            :value="fetchingMonthlyData"
            :opacity="0.3"
          >
            <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
          </v-overlay>
          <v-card color="#e0e0c0" :min-height="minAggregatedHeight">
            <v-card-title class="card-title">
              Monthly Data
            </v-card-title>
            <v-card-text class="card-text">
              <b>Gain:</b> {{ monthlyGainStr }}<br />
              <b>Drain:</b> {{ monthlyDrainStr }}
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-container class="graph-container">
          <v-overlay
            v-if="fetchingWeeklyData"
            class="d-flex justify-center graph-absolute"
            :value="fetchingWeeklyData"
            :opacity="0.3"
          >
            <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
          </v-overlay>
          <v-card color="#e0e0c0" :min-height="minAggregatedHeight">
            <v-card-title class="card-title">
              Weekly Data
            </v-card-title>
            <v-card-text class="card-text">
              <b>Gain:</b> {{ weeklyGainStr }}<br />
              <b>Drain:</b> {{ weeklyDrainStr }}
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-container class="graph-container">
          <v-overlay
            v-if="fetchingDailyData"
            class="d-flex justify-center graph-absolute"
            :value="fetchingDailyData"
            :opacity="0.3"
          >
            <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
          </v-overlay>
          <v-card color="#e0e0c0" :min-height="minAggregatedHeight" @click="onShowDailyData">
            <v-card-title class="card-title">
              Daily Data
            </v-card-title>
            <v-card-text class="card-text">
              <b>Gain:</b> {{ dailyGainStr }}<br />
              <b>Drain:</b> {{ dailyDrainStr }}
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
    <!-- <v-expand-transition :duration="{ enter: 1000, leave: 1000 }">
      <v-row v-if="showYearlyData">
        <v-col class="doughnut-graph shrink" cols="12"> -->
    <v-dialog v-model="showYearlyData" max-width="30%" :overlay-opacity="0.8">
      <v-container class="graph-container">
        <v-overlay
          v-if="fetchingYearlyData"
          class="d-flex justify-center graph-absolute"
          :value="fetchingYearlyData"
          :opacity="0.3"
        >
          <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
        </v-overlay>
        <v-card class="card-graph" color="#e0e0c0" :min-height="minGraphHeight" width="100%">
          <doughnut-2d
            v-bind:width="yearlyGraphWidth"
            v-bind:height="yearlyGraphHeight"
            v-bind:caption="yearlyGraphCaption"
            v-bind:subCaption="yearlyGraphSubCaption"
            v-bind:data="yearlyGraphDataset"
          />
        </v-card>
      </v-container>
    </v-dialog>
    <!-- </v-col>
      </v-row>
    </v-expand-transition> -->
    <!-- <v-expand-transition :duration="{ enter: 1000, leave: 1000 }">
      <v-row v-if="showDailyData">
        <v-col class="column-graph shrink" cols="12"> -->
    <v-dialog v-model="showDailyData" max-width="50%" :overlay-opacity="0.8">
      <v-container class="graph-container">
        <v-overlay
          v-if="fetchingDailyData"
          class="d-flex justify-center graph-absolute"
          :value="fetchingDailyData"
          :opacity="0.3"
        >
          <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
        </v-overlay>
        <v-card class="card-graph" color="#e0e0c0" :min-height="minGraphHeight" width="100%">
          <ms-column-2d
            v-bind:width="dailyGraphWidth"
            v-bind:height="dailyGraphHeight"
            v-bind:caption="dailyGraphCaption"
            v-bind:subCaption="dailyGraphSubCaption"
            v-bind:categories="dailyGraphCategories"
            v-bind:dataset="dailyGraphDataset"
            v-bind:yAxisName="dailyYAxisName"
          />
        </v-card>
      </v-container>
    </v-dialog>
    <!-- </v-col>
      </v-row>
    </v-expand-transition> -->
    <!-- <v-row>
      <v-col class="doughnut-graph" cols="3">
      <v-container class="graph-container">
        <v-overlay
          v-if="fetchingYearlyData"
          class="d-flex justify-center graph-absolute"
          :value="fetchingYearlyData"
          :opacity="0.3"
        >
          <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
        </v-overlay>
        <v-card class="card-graph" color="#e0e0c0" :min-height="minGraphHeight" width="100%">
          <doughnut-2d
            v-bind:width="yearlyGraphWidth"
            v-bind:height="yearlyGraphHeight"
            v-bind:caption="yearlyGraphCaption"
            v-bind:subCaption="yearlyGraphSubCaption"
            v-bind:data="yearlyGraphDataset"
          />
        </v-card>
      </v-container>
      </v-col>
      <v-col class="column-graph" cols="9">
      <v-container class="graph-container">
        <v-overlay
          v-if="fetchingDailyData"
          class="d-flex justify-center graph-absolute"
          :value="fetchingDailyData"
          :opacity="0.3"
        >
          <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
        </v-overlay>
        <v-card class="card-graph" color="#e0e0c0" :min-height="minGraphHeight" width="100%">
          <ms-column-2d
            v-bind:width="dailyGraphWidth"
            v-bind:height="dailyGraphHeight"
            v-bind:caption="dailyGraphCaption"
            v-bind:subCaption="dailyGraphSubCaption"
            v-bind:categories="dailyGraphCategories"
            v-bind:dataset="dailyGraphDataset"
            v-bind:yAxisName="dailyYAxisName"
          />
        </v-card>
      </v-container>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12">
        <v-container class="graph-container">
          <v-overlay
            v-if="fetchingWorldMapData"
            class="d-flex justify-center graph-absolute"
            :value="fetchingWorldMapData"
            :opacity="0.3"
          >
            <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
          </v-overlay>
          <v-card class="card-graph" color="#e0e0c0" :min-height="minMapHeight" width="100%">
            <world-map-chart
              v-if="mapId == 'world'"
              v-bind:width="mapWidth"
              v-bind:height="mapHeight"
              v-bind:data="worldMapData"
              v-bind:maxGainValue="maxGainValue"
              v-bind:minGainValue="minGainValue"
              v-bind:linkeddata="linkedMapData"
              v-bind:title="worldMapTitle"
              v-bind:subTitle="worldMapSubTitle"
            />
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import HeatMapChart from "@/components/charts/map/HeatMapChart.vue";
import WorldMapChart from "@/components/charts/map/WorldMap.vue";
import MsColumn2d from "@/components/charts/general/MsColumn2d.vue";
import Doughnut2d from "@/components/charts/general/Doughnut2d.vue";
import Line2d from "@/components/charts/general/Line2d.vue";
// import Geography from "@/services/geography.service.js";
import { shortenNumber } from "../../../utils/numberFormat.js";
import { getEuropeCountriesIds } from "@/components/charts/map/helpers/EuropeMapIdsToCountryCode.js";

export default {
  name: "AdminDashboard",
  components: {
    // HeatMapChart,
    WorldMapChart,
    MsColumn2d,
    Line2d,
    Doughnut2d,
  },
  data: () => ({
    mapWidth: "100%",
    mapHeight: "100%",
    fetchingTotalCustomers: false,
    fetchingTotalUsers: false,
    fetchingTotalDevices: false,
    totalUsers: null,
    totalDevices: null,
    totalCustomers: null,

    show: true,

    showNewUsersData: false,
    showNewDevicesData: false,
    showDailyData: false,
    dailyData: [],
    showYearlyData: false,

    dailyGain: null,
    dailyDrain: null,
    dailyGainStr: null,
    dailyDrainStr: null,
    weeklyGain: null,
    weeklyDrain: null,
    weeklyGainStr: null,
    weeklyDrainStr: null,
    monthlyGain: null,
    monthlyDrain: null,
    monthlyGainStr: null,
    monthlyDrainStr: null,
    yearlyGain: null,
    yearlyDrain: null,
    yearlyGainStr: null,
    yearlyDrainStr: null,

    worldMapTitle: "Global Gain total values",
    worldMapSubTitle: null,
    europeMapTitle: "Europe Gain total values",
    // heatMapEuropeTitle: "Gain values in Europe",
    // heatMapUSTitle: "Gain values in US",
    // heatMapSouthAmericaTitle: "Gain values in South America",
    mapId: "world",
    fetchingWorldMapData: false,
    worldMapData: [],
    minGainValue: null,
    maxGainValue: null,

    linkedMapData: [
      {
        id: "EU",
        linkedchart: {
          chart: {
            caption: "Europe's Gain values by country",
            // subcaption: "",
            bgColor: "#e0e0c0",
            theme: "fusion",
            formatNumberScale: "1",
            decimals: "1",
            showLabels: "0",
            nullEntityColor: "#C2C2D6",
            nullEntityAlpha: "50",
            hoverOnNull: "0",
            useSNameInLabels: "0",
            entityFillColor: "#A8A8A8",
            entityFillHoverColor: "#E5E5E9",
            chartLeftMargin: 0,
            chartRightMargin: 0,
            chartTopMargin: 0,
            chartBottomMargin: 0,
            showToolTip: 1,
            toolTipSepChar: ": ",
            numberSuffix: " mAh",
            entitytooltext: "<b>$lName</b><br>Gain: <b>$dataValue</b>",
            exportEnabled: 1,
            exportFormats: "PNG|JPG|PDF|XLSX",
          },
          colorrange: {
            gradient: 0,
            color: [
              {
                minvalue: 0,
                maxvalue: 2,
                displayvalue: "Lowest Gain Values",
                code: "#e44a00",
              },
              {
                minvalue: 2,
                maxvalue: 4,
                displayvalue: "Average Gain Values",
                code: "#f8bd19",
              },
              {
                minvalue: 4,
                maxvalue: 6,
                displayvalue: "Highest Gain Values",
                code: "#6baa01",
              },
            ],
          },
          data: null,
        },
      },
      {
        id: "AF",
        linkedchart: {
          chart: {
            caption: "Africa's Gain values by country",
            // subcaption: "",
            bgColor: "#e0e0c0",
            theme: "fusion",
            formatNumberScale: "1",
            decimals: "1",
            showLabels: "0",
            nullEntityColor: "#C2C2D6",
            nullEntityAlpha: "50",
            hoverOnNull: "0",
            useSNameInLabels: "0",
            entityFillColor: "#A8A8A8",
            entityFillHoverColor: "#E5E5E9",
            chartLeftMargin: 0,
            chartRightMargin: 0,
            chartTopMargin: 0,
            chartBottomMargin: 0,
            showToolTip: 1,
            toolTipSepChar: ": ",
            numberSuffix: " mAh",
            entitytooltext: "<b>$lName</b><br>Gain: <b>$dataValue</b>",
            exportEnabled: 1,
            exportFormats: "PNG|JPG|PDF|XLSX",
          },
          colorrange: {
            gradient: 0,
            color: [
              {
                minvalue: 0,
                maxvalue: 2,
                displayvalue: "Lowest Gain Values",
                code: "#e44a00",
              },
              {
                minvalue: 2,
                maxvalue: 4,
                displayvalue: "Average Gain Values",
                code: "#f8bd19",
              },
              {
                minvalue: 4,
                maxvalue: 6,
                displayvalue: "Highest Gain Values",
                code: "#6baa01",
              },
            ],
          },
          data: null,
        },
      },
      {
        id: "NA",
        linkedchart: {
          chart: {
            caption: "North America's Gain values by country",
            // subcaption: "",
            bgColor: "#e0e0c0",
            theme: "fusion",
            formatNumberScale: "1",
            decimals: "1",
            showLabels: "0",
            nullEntityColor: "#C2C2D6",
            nullEntityAlpha: "50",
            hoverOnNull: "0",
            useSNameInLabels: "0",
            entityFillColor: "#A8A8A8",
            entityFillHoverColor: "#E5E5E9",
            chartLeftMargin: 0,
            chartRightMargin: 0,
            chartTopMargin: 0,
            chartBottomMargin: 0,
            showToolTip: 1,
            toolTipSepChar: ": ",
            numberSuffix: " mAh",
            entitytooltext: "<b>$lName</b><br>Gain: <b>$dataValue</b>",
            exportEnabled: 1,
            exportFormats: "PNG|JPG|PDF|XLSX",
          },
          colorrange: {
            gradient: 0,
            color: [
              {
                minvalue: 0,
                maxvalue: 2,
                displayvalue: "Lowest Gain Values",
                code: "#e44a00",
              },
              {
                minvalue: 2,
                maxvalue: 4,
                displayvalue: "Average Gain Values",
                code: "#f8bd19",
              },
              {
                minvalue: 4,
                maxvalue: 6,
                displayvalue: "Highest Gain Values",
                code: "#6baa01",
              },
            ],
          },
          data: null,
        },
      },
      {
        id: "SA",
        linkedchart: {
          chart: {
            caption: "South America's Gain values by country",
            // subcaption: "",
            bgColor: "#e0e0c0",
            theme: "fusion",
            formatNumberScale: "1",
            decimals: "1",
            showLabels: "0",
            nullEntityColor: "#C2C2D6",
            nullEntityAlpha: "50",
            hoverOnNull: "0",
            useSNameInLabels: "0",
            entityFillColor: "#A8A8A8",
            entityFillHoverColor: "#E5E5E9",
            chartLeftMargin: 0,
            chartRightMargin: 0,
            chartTopMargin: 0,
            chartBottomMargin: 0,
            showToolTip: 1,
            toolTipSepChar: ": ",
            numberSuffix: " mAh",
            entitytooltext: "<b>$lName</b><br>Gain: <b>$dataValue</b>",
            exportEnabled: 1,
            exportFormats: "PNG|JPG|PDF|XLSX",
          },
          colorrange: {
            gradient: 0,
            color: [
              {
                minvalue: 0,
                maxvalue: 2,
                displayvalue: "Lowest Gain Values",
                code: "#e44a00",
              },
              {
                minvalue: 2,
                maxvalue: 4,
                displayvalue: "Average Gain Values",
                code: "#f8bd19",
              },
              {
                minvalue: 4,
                maxvalue: 6,
                displayvalue: "Highest Gain Values",
                code: "#6baa01",
              },
            ],
          },
          data: null,
        },
      },
      {
        id: "AS",
        linkedchart: {
          chart: {
            caption: "Asia's Gain values by country",
            // subcaption: "",
            bgColor: "#e0e0c0",
            theme: "fusion",
            formatNumberScale: "1",
            decimals: "1",
            showLabels: "0",
            nullEntityColor: "#C2C2D6",
            nullEntityAlpha: "50",
            hoverOnNull: "0",
            useSNameInLabels: "0",
            entityFillColor: "#A8A8A8",
            entityFillHoverColor: "#E5E5E9",
            chartLeftMargin: 0,
            chartRightMargin: 0,
            chartTopMargin: 0,
            chartBottomMargin: 0,
            showToolTip: 1,
            toolTipSepChar: ": ",
            numberSuffix: " mAh",
            entitytooltext: "<b>$lName</b><br>Gain: <b>$dataValue</b>",
            exportEnabled: 1,
            exportFormats: "PNG|JPG|PDF|XLSX",
          },
          colorrange: {
            gradient: 0,
            color: [
              {
                minvalue: 0,
                maxvalue: 2,
                displayvalue: "Lowest Gain Values",
                code: "#e44a00",
              },
              {
                minvalue: 2,
                maxvalue: 4,
                displayvalue: "Average Gain Values",
                code: "#f8bd19",
              },
              {
                minvalue: 4,
                maxvalue: 6,
                displayvalue: "Highest Gain Values",
                code: "#6baa01",
              },
            ],
          },
          data: null,
        },
      },
      {
        id: "AU",
        linkedchart: {
          chart: {
            caption: "Oceania's Gain values by country",
            // subcaption: "",
            bgColor: "#e0e0c0",
            theme: "fusion",
            formatNumberScale: "1",
            decimals: "1",
            showLabels: "0",
            nullEntityColor: "#C2C2D6",
            nullEntityAlpha: "50",
            hoverOnNull: "0",
            useSNameInLabels: "0",
            entityFillColor: "#A8A8A8",
            entityFillHoverColor: "#E5E5E9",
            chartLeftMargin: 0,
            chartRightMargin: 0,
            chartTopMargin: 0,
            chartBottomMargin: 0,
            showToolTip: 1,
            toolTipSepChar: ": ",
            numberSuffix: " mAh",
            entitytooltext: "<b>$lName</b><br>Gain: <b>$dataValue</b>",
            exportEnabled: 1,
            exportFormats: "PNG|JPG|PDF|XLSX",
          },
          colorrange: {
            gradient: 0,
            color: [
              {
                minvalue: 0,
                maxvalue: 2,
                displayvalue: "Lowest Gain Values",
                code: "#e44a00",
              },
              {
                minvalue: 2,
                maxvalue: 4,
                displayvalue: "Average Gain Values",
                code: "#f8bd19",
              },
              {
                minvalue: 4,
                maxvalue: 6,
                displayvalue: "Highest Gain Values",
                code: "#6baa01",
              },
            ],
          },
          data: null,
        },
      },
    ],
    /*    heatMapEuropeData: [
      {
        rowid: "hp",
        columnid: "WI",
        value: "60.10",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hp",
        columnid: "SP",
        displayValue: "64.5",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hp",
        columnid: "SU",
        displayValue: "68.2",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hp",
        columnid: "AU",
        displayValue: "65.7",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hl",
        columnid: "WI",
        displayValue: "33.7",
        colorRangeLabel: "Bad",
      },
      {
        rowid: "hl",
        columnid: "SP",
        displayValue: "57.8",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hl",
        columnid: "SU",
        displayValue: "74.49",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "hl",
        columnid: "AU",
        displayValue: "57.6",
        colorRangeLabel: "Average",
      },
      {
        rowid: "sk",
        columnid: "WI",
        displayValue: "22.89",
        colorRangeLabel: "Bad",
      },
      {
        rowid: "sk",
        columnid: "SP",
        displayValue: "55.7",
        colorRangeLabel: "Average",
      },
      {
        rowid: "sk",
        columnid: "SU",
        displayValue: "72.2",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "sk",
        columnid: "AU",
        displayValue: "51.6",
        colorRangeLabel: "Average",
      },
      {
        rowid: "dt",
        columnid: "WI",
        displayValue: "53.0",
        colorRangeLabel: "Average",
      },
      {
        rowid: "dt",
        columnid: "SP",
        displayValue: "72.7",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "dt",
        columnid: "SU",
        displayValue: "83.3",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "dt",
        columnid: "AU",
        displayValue: "53.0",
        colorRangeLabel: "Average",
      },
    ],
    heatMapUSData: [
      {
        rowid: "hp",
        columnid: "WI",
        value: "60.10",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hp",
        columnid: "SP",
        displayValue: "64.5",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hp",
        columnid: "SU",
        displayValue: "68.2",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hp",
        columnid: "AU",
        displayValue: "65.7",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hl",
        columnid: "WI",
        displayValue: "33.7",
        colorRangeLabel: "Bad",
      },
      {
        rowid: "hl",
        columnid: "SP",
        displayValue: "57.8",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hl",
        columnid: "SU",
        displayValue: "74.49",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "hl",
        columnid: "AU",
        displayValue: "57.6",
        colorRangeLabel: "Average",
      },
      {
        rowid: "sk",
        columnid: "WI",
        displayValue: "22.89",
        colorRangeLabel: "Bad",
      },
      {
        rowid: "sk",
        columnid: "SP",
        displayValue: "55.7",
        colorRangeLabel: "Average",
      },
      {
        rowid: "sk",
        columnid: "SU",
        displayValue: "72.2",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "sk",
        columnid: "AU",
        displayValue: "51.6",
        colorRangeLabel: "Average",
      },
      {
        rowid: "dt",
        columnid: "WI",
        displayValue: "53.0",
        colorRangeLabel: "Average",
      },
      {
        rowid: "dt",
        columnid: "SP",
        displayValue: "72.7",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "dt",
        columnid: "SU",
        displayValue: "83.3",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "dt",
        columnid: "AU",
        displayValue: "53.0",
        colorRangeLabel: "Average",
      },
    ],
    heatMapSouthAmericaData: [
      {
        rowid: "hp",
        columnid: "WI",
        value: "60.10",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hp",
        columnid: "SP",
        displayValue: "64.5",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hp",
        columnid: "SU",
        displayValue: "68.2",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hp",
        columnid: "AU",
        displayValue: "65.7",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hl",
        columnid: "WI",
        displayValue: "33.7",
        colorRangeLabel: "Bad",
      },
      {
        rowid: "hl",
        columnid: "SP",
        displayValue: "57.8",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hl",
        columnid: "SU",
        displayValue: "74.49",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "hl",
        columnid: "AU",
        displayValue: "57.6",
        colorRangeLabel: "Average",
      },
      {
        rowid: "sk",
        columnid: "WI",
        displayValue: "22.89",
        colorRangeLabel: "Bad",
      },
      {
        rowid: "sk",
        columnid: "SP",
        displayValue: "55.7",
        colorRangeLabel: "Average",
      },
      {
        rowid: "sk",
        columnid: "SU",
        displayValue: "72.2",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "sk",
        columnid: "AU",
        displayValue: "51.6",
        colorRangeLabel: "Average",
      },
      {
        rowid: "dt",
        columnid: "WI",
        displayValue: "53.0",
        colorRangeLabel: "Average",
      },
      {
        rowid: "dt",
        columnid: "SP",
        displayValue: "72.7",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "dt",
        columnid: "SU",
        displayValue: "83.3",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "dt",
        columnid: "AU",
        displayValue: "53.0",
        colorRangeLabel: "Average",
      },
    ],
*/

    // newCustomersDataSource: {},
    // newCustomersDataEmptyMessage: "",
    // newCustomersGraphWidth: "100%",
    // newCustomersGraphHeight: "100%",

    fetchingNewUsersData: false,
    newUsersDataSource: null,
    newUsersDataEmptyMessage: "",
    newUsersGraphWidth: "100%",
    newUsersGraphHeight: "100%",

    fetchingNewDevicesData: false,
    newDevicesDataSource: null,
    newDevicesDataEmptyMessage: "",
    newDevicesGraphWidth: "100%",
    newDevicesGraphHeight: "100%",

    fetchingYearlyData: false,
    fetchingMonthlyData: false,
    fetchingWeeklyData: false,
    fetchingDailyData: false,

    fetchingYearlyGraphData: false,
    fetchingDailyGraphData: false,

    dailyGraphCaption: "Daily Data",
    dailyGraphSubCaption: "",
    dailyGraphCategories: [],
    dailyGraphDataset: [
      {
        seriesname: "Gain",
        data: [],
      },
      {
        seriesname: "Drain",
        data: [],
      },
    ],
    dailyYAxisName: "Current (mAh)",
    dailyGraphWidth: "100%",
    dailyGraphHeight: "100%",
    weeklyGraphCaption: "Weekly Data",
    weeklyGraphSubCaption: "",
    weeklyGraphCategories: [],
    weeklyGraphDataset: [
      {
        seriesname: "Gain",
        data: [],
      },
      {
        seriesname: "Drain",
        data: [],
      },
    ],
    yearlyGraphCaption: "Yearly Data",
    yearlyGraphSubCaption: "",
    yearlyGraphDataset: null,
    yearlyGraphWidth: "100%",
    yearlyGraphHeight: "100%",
    minTotalsHeight: (((1 / 9) * 1) / 2) * window.innerHeight,
    minAggregatedHeight: (((1 / 8) * 1) / 2) * window.innerHeight,
    minGraphHeight: (((1 / 2) * 1) / 2) * window.innerHeight,
    minMapHeight: (2 / 3) * window.innerHeight,
  }),

  mounted() {
    this.onResize();
    this.fetchDailyMeasurements();
    this.fetchWeeklyMeasurements();
    this.fetchMonthlyMeasurements();
    this.fetchYearlyMeasurements();
    this.fetchTotalUsers();
    this.fetchTotalDevices();
    this.fetchTotalCustomers();
    // this.fetchNewCustomersForLastPeriod();
    this.fetchNewUsersForLastPeriod();
    this.fetchNewDevicesForLastPeriod();
  },
  computed: {
    accessToken() {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    },
  },

  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    onClickContinent(e) {
      console.log("event value: ", e);
    },
    onNewUsersData() {
      if (!this.newUsersDataSource) {
        this.fetchNewUsersForLastPeriod();
      }
      this.showNewUsersData = !this.showNewUsersData;
    },
    onNewDevicesData() {
      if (!this.newDevicesDataSource) {
        this.fetchNewDevicesForLastPeriod();
      }
      this.showNewDevicesData = !this.showNewDevicesData;
    },
    onShowYearlyData() {
      this.fetchingYearlyData = true;
      this.showYearlyData = !this.showYearlyData;
      this.fetchingYearlyData = false;
    },
    onShowDailyData() {
      this.showDailyData = !this.showDailyData;
      this.fetchingDailyData = true;
      this.dailyGain = this.dailyData[this.dailyData.length - 1].gain_sum.toFixed(2) + " mAh";
      this.dailyDrain = this.dailyData[this.dailyData.length - 1].drain_sum.toFixed(2) + " mAh";
      let category = [];
      let gainData = [];
      let drainData = [];
      this.dailyData.forEach((datapoint) => {
        category.push({
          label: datapoint.aggregated_id.year + "-" + datapoint.aggregated_id.month + "-" + datapoint.aggregated_id.day,
        });
        gainData.push({ value: datapoint.gain_sum });
        drainData.push({ value: datapoint.drain_sum });
      });
      this.dailyGraphSubCaption = "Week " + this.dailyData[this.dailyData.length - 1].aggregated_id.week.toString();
      this.dailyGraphCategories = [{ category }];
      this.dailyGraphDataset[0].title = gainData;
      this.dailyGraphDataset[0].data = gainData;
      this.dailyGraphDataset[1].data = drainData;
      this.fetchingDailyData = false;
    },

    async fetchTotalUsers() {
      this.fetchingTotalUsers = true;
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      // reqEndpoint = this.$globals.API_URL + this.$globals.RES_DEVICE_EVENTS_WEEKLY_MEASUREMENTS + `?customerName=${this.customerName}`;
      reqEndpoint = this.$globals.PROD_API_URL + this.$globals.RES_DEVICE_EVENTS_TOTAL_USERS;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      this.totalUsers = data[data.length - 1].sum_users;
      this.fetchingTotalUsers = false;
    },
    async fetchTotalDevices() {
      this.fetchingTotalDevices = true;
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      // reqEndpoint = this.$globals.API_URL + this.$globals.RES_DEVICE_EVENTS_WEEKLY_MEASUREMENTS + `?customerName=${this.customerName}`;
      reqEndpoint = this.$globals.PROD_API_URL + this.$globals.RES_DEVICE_EVENTS_TOTAL_DEVICES;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      this.totalDevices = data[data.length - 1].sum_devices;
      this.fetchingTotalDevices = false;
    },
    async fetchTotalCustomers() {
      this.fetchingTotalCustomers = true;
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      // reqEndpoint = this.$globals.API_URL + this.$globals.RES_DEVICE_EVENTS_WEEKLY_MEASUREMENTS + `?customerName=${this.customerName}`;
      reqEndpoint = this.$globals.PROD_API_URL + this.$globals.RES_DEVICE_EVENTS_TOTAL_CUSTOMERS;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      this.totalCustomers = data[data.length - 1].sum_customers;
      this.fetchingTotalCustomers = false;
    },

    // async fetchNewCustomersForLastPeriod() {
    //   this.loadingUsers = true;
    //   const h = new Headers();
    //   h.append("Authorization", "Bearer " + this.accessToken);
    //   let reqEndpoint = "";
    //   reqEndpoint = this.$globals.PROD_API_URL + this.$globals.RES_DEVICE_EVENTS_TOTAL_NEW_CUSTOMERS + "?days=10";
    //   const response = await fetch(reqEndpoint, { headers: h });
    //   if (response.status == 401) {
    //     this.$store.dispatch("auth/logout").then(
    //       () => {
    //         this.$router.push("/login");
    //       },
    //       (error) => {
    //         this.message = (error.response && error.response.data) || error.message || error.toString();
    //       }
    //     );
    //   }
    //   const data = await response.json();
    //   console.log('new customers: ', data);
    //   let newCustomersMap = new Map();
    //   data.forEach(newCustomer => {
    //     let count = 0;
    //     if (newCustomersMap.has(newCustomer.registered_date.toString().substring(0, 10))) {
    //       count = newCustomersMap.get(newCustomer.registered_date.toString().substring(0, 10));
    //     }
    //     newCustomersMap.set(newCustomer.registered_date.toString().substring(0, 10), count + 1);
    //   });
    //   var mapNewCustomersSorted = new Map([...newCustomersMap.entries()].sort());
    //   console.log(mapNewCustomersSorted);

    //   let category = [];
    //   let newCustomersData = [];
    //   mapNewCustomersSorted.forEach((value, id) => {
    //     category.push({label: id });
    //     newCustomersData.push({value: value});
    //   });
    //   if (newCustomersData.length == 0) {
    //     this.newCustomersDataEmptyMessage = "No New Customers In The Last 10 Days"
    //   } else {
    //     this.newCustomersDataSource = {
    //       chart: {
    //         caption: "New Customers",
    //         subCaption: "For the last 10 Days",
    //         xAxisName: "Date",
    //         yAxisName: "Number of New Customers",
    //         bgColor: "#e0e0c0",
    //         showhovereffect: "1",
    //         numbersuffix: "",
    //         drawcrossline: "1",
    //         showValues: "1",
    //         showLegend: true,
    //         labelDisplay: 'ROTATE',
    //         slantLabels: true,
    //         anchorSides: 4,
    //         lineColor: "#3300ee",
    //         anchorBgColor: "#3300ee",
    //         anchorBorderColor: "#3300ee",
    //         plotHighlightEffect: "fadeout|anchorBgColor=ff0000, anchorBgAlpha=50",
    //         legendPosition: "bottom-right",
    //         chartLeftMargin: 0,
    //         chartRightMargin: 0,
    //         chartTopMargin: 0,
    //         chartBottomMargin: 0,
    //         exportEnabled: true,
    //         plottooltext: "<b>$dataValue</b> $seriesName",
    //         theme: "fusion",
    //       },
    //       categories: [{ category }],
    //       dataset: [
    //         {
    //           seriesname: "Users",
    //           data: newCustomersData,
    //         },
    //       ],
    //     };
    //   }
    // },

    async fetchNewUsersForLastPeriod() {
      this.fetchingNewUsersData = true;
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      reqEndpoint = this.$globals.PROD_API_URL + this.$globals.RES_DEVICE_EVENTS_TOTAL_NEW_USERS + "?days=10";
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      let newUsersMap = new Map();
      data.forEach((newUser) => {
        // console.log('new user: ', newUser.registered_date.toString().substring(0, 10));
        let count = 0;
        if (newUsersMap.has(newUser.registered_date.toString().substring(0, 10))) {
          // console.log('found: ', newUsersMap.get(newUser.registered_date.toString().substring(0, 10)));
          count = newUsersMap.get(newUser.registered_date.toString().substring(0, 10));
          // console.log('count: ', count);
        }
        newUsersMap.set(newUser.registered_date.toString().substring(0, 10), count + 1);
      });
      var mapNewUsersSorted = new Map([...newUsersMap.entries()].sort());
      // console.log(mapNewUsersSorted);

      let category = [];
      let newUsersData = [];
      mapNewUsersSorted.forEach((value, id) => {
        // console.log('id: ', id);
        // console.log('value: ', value);
        category.push({ label: id });
        newUsersData.push({ value: value });
      });
      if (newUsersData.length == 0) {
        this.newUsersDataEmptyMessage = "No New Users In The Last 10 Days";
      } else {
        this.newUsersDataSource = {
          chart: {
            caption: "New Users",
            subCaption: "For the last 10 Days",
            xAxisName: "Date",
            yAxisName: "Number of New Users",
            bgColor: "#e0e0c0",
            showhovereffect: 1,
            numbersuffix: "",
            drawCrossLine: 1,
            crossLineColor: "#777777",
            showValues: 1,
            showLegend: 1,
            labelDisplay: "ROTATE",
            slantLabels: 1,
            anchorSides: 4,
            lineColor: "#3300ee",
            anchorBgColor: "#3300ee",
            anchorBorderColor: "#3300ee",
            plotHighlightEffect: "fadeout|anchorBgColor=ff0000, anchorBgAlpha=50",
            legendPosition: "bottom-right",
            chartLeftMargin: 0,
            chartRightMargin: 10,
            chartTopMargin: 0,
            chartBottomMargin: 0,
            exportEnabled: 1,
            plottooltext: "<b>$label</b><br>New $seriesName: <b>$dataValue</b>",
            theme: "fusion",
          },
          categories: [{ category }],
          dataset: [
            {
              seriesname: "Users",
              data: newUsersData,
            },
          ],
        };
      }
      this.fetchingNewUsersData = false;
    },

    async fetchNewDevicesForLastPeriod() {
      this.fetchingNewDevicesData = true;
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      reqEndpoint = this.$globals.PROD_API_URL + this.$globals.RES_DEVICE_EVENTS_TOTAL_NEW_DEVICES + "?days=10";
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      // console.log('new devices: ', data);
      let newDevicesMap = new Map();
      data.forEach((newDevice) => {
        // console.log('new device: ', newDevice.registered_date.toString().substring(0, 10));
        let count = 0;
        if (newDevicesMap.has(newDevice.registered_date.toString().substring(0, 10))) {
          // console.log('found: ', newDevicesMap.get(newDevice.registered_date.toString().substring(0, 10)));
          count = newDevicesMap.get(newDevice.registered_date.toString().substring(0, 10));
          // console.log('count: ', count);
        }
        newDevicesMap.set(newDevice.registered_date.toString().substring(0, 10), count + 1);
      });
      var mapNewDevicesSorted = new Map([...newDevicesMap.entries()].sort());
      // console.log(mapNewDevicesSorted);

      let category = [];
      let newDevicesData = [];
      mapNewDevicesSorted.forEach((value, id) => {
        // console.log('id: ', id);
        // console.log('value: ', value);
        category.push({ label: id });
        newDevicesData.push({ value: value });
      });
      if (newDevicesData.length == 0) {
        this.newDevicesDataEmptyMessage = "No New Devices In The Last 10 Days";
      } else {
        this.newDevicesDataSource = {
          chart: {
            caption: "New Devices",
            subCaption: "For the last 10 Days",
            xAxisName: "Date",
            yAxisName: "Number of New Devices",
            bgColor: "#e0e0c0",
            showhovereffect: 1,
            numbersuffix: "",
            drawCrossLine: 1,
            crossLineColor: "#777777",
            showValues: 1,
            showLegend: 1,
            labelDisplay: "ROTATE",
            slantLabels: 1,
            anchorSides: 4,
            lineColor: "#ee9900",
            anchorBgColor: "#ee9900",
            anchorBorderColor: "#ee9900",
            plotHighlightEffect: "fadeout|anchorBgColor=ff0000, anchorBgAlpha=50",
            legendPosition: "bottom-right",
            chartLeftMargin: 0,
            chartRightMargin: 10,
            chartTopMargin: 0,
            chartBottomMargin: 0,
            exportEnabled: 1,
            plottooltext: "<b>$label</b><br>New $seriesName: <b>$dataValue</b>",
            theme: "fusion",
          },
          categories: [{ category }],
          dataset: [
            {
              seriesname: "Devices",
              data: newDevicesData,
            },
          ],
        };
      }
      this.fetchingNewDevicesData = false;
    },

    async fetchDailyMeasurements() {
      this.fetchingDailyData = true;
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      // reqEndpoint = this.$globals.API_URL + this.$globals.RES_DEVICE_EVENTS_WEEKLY_MEASUREMENTS + `?customerName=${this.customerName}`;
      reqEndpoint = this.$globals.PROD_API_URL + this.$globals.RES_DEVICE_EVENTS_DAILY_MEASUREMENTS; // + `?fromDate=2022-12-01T00:00:00.000Z&toDate=2023-01-01T23:59:59.000Z`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      this.dailyData = data;
      // console.log("daily data: ", data);
      this.dailyGain = data[data.length - 1].gain_sum.toFixed(2);
      this.dailyDrain = data[data.length - 1].drain_sum.toFixed(2);
      this.dailyGainStr = shortenNumber(this.dailyGain, 1) + " mAh";
      this.dailyDrainStr = shortenNumber(this.dailyDrain, 1) + " mAh";
      this.fetchingDailyData = false;
    },
    async fetchWeeklyMeasurements() {
      this.fetchingWeeklyData = true;
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      // reqEndpoint = this.$globals.API_URL + this.$globals.RES_DEVICE_EVENTS_WEEKLY_MEASUREMENTS + `?customerName=${this.customerName}`;
      reqEndpoint = this.$globals.PROD_API_URL + this.$globals.RES_DEVICE_EVENTS_WEEKLY_MEASUREMENTS; // + `?fromDate=2022-12-01T00:00:00.000Z&toDate=2023-01-01T23:59:59.000Z`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      this.weeklyGain = data[data.length - 1].gain_sum.toFixed(2);
      this.weeklyDrain = data[data.length - 1].drain_sum.toFixed(2);
      this.weeklyGainStr = shortenNumber(this.weeklyGain, 1) + " mAh";
      this.weeklyDrainStr = shortenNumber(this.weeklyDrain, 1) + " mAh";
      this.fetchingWeeklyData = false;
    },
    async fetchMonthlyMeasurements() {
      this.fetchingMonthlyData = true;
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      // reqEndpoint = this.$globals.API_URL + this.$globals.RES_DEVICE_EVENTS_WEEKLY_MEASUREMENTS + `?customerName=${this.customerName}`;
      reqEndpoint = this.$globals.PROD_API_URL + this.$globals.RES_DEVICE_EVENTS_MONTHLY_MEASUREMENTS; // + `?fromDate=2022-12-01T00:00:00.000Z&toDate=2023-01-01T23:59:59.000Z`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      this.monthlyGain = data[data.length - 1].gain_sum.toFixed(2);
      this.monthlyDrain = data[data.length - 1].drain_sum.toFixed(2);
      this.monthlyGainStr = shortenNumber(this.monthlyGain, 1) + " mAh";
      this.monthlyDrainStr = shortenNumber(this.monthlyDrain, 1) + " mAh";
      this.fetchingMonthlyData = false;
    },
    async fetchYearlyMeasurements() {
      this.fetchingYearlyData = true;
      this.fetchingWorldMapData = true;
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      // reqEndpoint = this.$globals.API_URL + this.$globals.RES_DEVICE_EVENTS_WEEKLY_MEASUREMENTS + `?customerName=${this.customerName}`;
      reqEndpoint = this.$globals.PROD_API_URL + this.$globals.RES_DEVICE_EVENTS_YEARLY_MEASUREMENTS; // + `?fromDate=2022-12-01T00:00:00.000Z&toDate=2023-01-01T23:59:59.000Z`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      // console.log("world data: ", data);
      let worldDataByRegion = new Map();

      let currentYearGain = 0;
      let currentYearDrain = 0;
      let currentYear = data[data.length - 1].aggregated_id.year;
      this.worldMapSubTitle = currentYear.toString();
      let maxGainValue = 0;
      let minGainValue = 0;
      let euLinkedData = new Map();
      let afLinkedData = new Map();
      let naLinkedData = new Map();
      let saLinkedData = new Map();
      let asLinkedData = new Map();
      let auLinkedData = new Map();
      // console.log("year: ", currentYear);
      for (let i = data.length - 1; i >= 0; i -= 1) {
        if (data[i].aggregated_id.year != currentYear) {
          break;
        }
        currentYearGain += data[i].gain_sum;
        currentYearDrain += data[i].drain_sum;

        let subRegion = data[i].aggregated_id.region;
        if (data[i].aggregated_id.region == "Americas") {
          if (data[i].aggregated_id.sub_region == "South America") {
            subRegion = "South America";
          } else {
            subRegion = "North America";
          }
        } else if (data[i].aggregated_id.region == "Oceania") {
          subRegion = "Australia";
        }

        let gainSum = 0;
        if (worldDataByRegion.has(subRegion)) {
          gainSum = worldDataByRegion.get(subRegion);
        }
        worldDataByRegion.set(subRegion, gainSum + data[i].gain_sum);
        gainSum = worldDataByRegion.get(subRegion);
        maxGainValue = Math.round(Math.max(maxGainValue, gainSum));
        minGainValue = Math.round(Math.min(minGainValue, gainSum));

        switch (subRegion) {
          case "Europe": {
            let countryGainSum = 0;
            // console.log('country: ', data[i].aggregated_id.country);
            if (data[i].aggregated_id.country == "GB") {
              data[i].aggregated_id.country = "UK";
            }
            let countryId = getEuropeCountriesIds(data[i].aggregated_id.country).countryId;
            // console.log('countryId: ', countryId);
            if (euLinkedData.has(countryId)) {
              countryGainSum = euLinkedData.get(countryId);
            }

            euLinkedData.set(countryId, countryGainSum + data[i].gain_sum);
            break;
          }
          case "Africa": {
            let countryGainSum = 0;
            if (afLinkedData.has(data[i].aggregated_id.country)) {
              countryGainSum = afLinkedData.get(data[i].aggregated_id.country);
            }
            afLinkedData.set(data[i].aggregated_id.country, countryGainSum + data[i].gain_sum);
            break;
          }
          case "North America": {
            let countryGainSum = 0;
            if (naLinkedData.has(data[i].aggregated_id.country)) {
              countryGainSum = naLinkedData.get(data[i].aggregated_id.country);
            }
            naLinkedData.set(data[i].aggregated_id.country, countryGainSum + data[i].gain_sum);
            break;
          }
          case "South America": {
            let countryGainSum = 0;
            if (saLinkedData.has(data[i].aggregated_id.country)) {
              countryGainSum = saLinkedData.get(data[i].aggregated_id.country);
            }
            saLinkedData.set(data[i].aggregated_id.country, countryGainSum + data[i].gain_sum);
            break;
          }
          case "Asia": {
            let countryGainSum = 0;
            if (asLinkedData.has(data[i].aggregated_id.country)) {
              countryGainSum = asLinkedData.get(data[i].aggregated_id.country);
            }
            asLinkedData.set(data[i].aggregated_id.country, countryGainSum + data[i].gain_sum);
            break;
          }
          case "Australia": {
            let countryGainSum = 0;
            if (auLinkedData.has(data[i].aggregated_id.country)) {
              countryGainSum = auLinkedData.get(data[i].aggregated_id.country);
            }
            auLinkedData.set(data[i].aggregated_id.country, countryGainSum + data[i].gain_sum);
            break;
          }
          default:
            break;
        }
      }

      this.maxGainValue = (maxGainValue + maxGainValue * 0.05).toString();
      this.minGainValue = (minGainValue - minGainValue * 0.05).toString();

      worldDataByRegion.forEach((value, id) => {
        this.worldMapData.push({ id, value });
      });

      // for (let i = 0; i < this.worldMapData.length; i += 1) {
      //   switch (this.worldMapData[i].id) {
      //     case "Europe":
      //       this.worldMapData[i].displayValue = "Europe";
      //       this.worldMapData[i].link = "newchart-json-EU";
      //       break;
      //     case "Africa":
      //       this.worldMapData[i].displayValue = "Africa";
      //       this.worldMapData[i].link = "newchart-json-AF";
      //       break;
      //     case "North America":
      //       this.worldMapData[i].displayValue = "North America";
      //       this.worldMapData[i].link = "newchart-json-NA";
      //       break;
      //     case "South America":
      //       this.worldMapData[i].displayValue = "South America";
      //       this.worldMapData[i].link = "newchart-json-SA";
      //       break;
      //     case "Asia":
      //       this.worldMapData[i].displayValue = "Asia";
      //       this.worldMapData[i].link = "newchart-json-AS";
      //       break;
      //     case "Australia":
      //       this.worldMapData[i].displayValue = "Australia";
      //       this.worldMapData[i].link = "newchart-json-AU";
      //       break;
      //     default:
      //       break;
      //   }
      // }
      // let euLinkedDataFixed = [];
      // euLinkedData.forEach((value, id) => {
      //   euLinkedDataFixed.push({ id, value });
      // });
      // let afLinkedDataFixed = [];
      // afLinkedData.forEach((value, id) => {
      //   afLinkedDataFixed.push({ id, value });
      // });
      // let naLinkedDataFixed = [];
      // naLinkedData.forEach((value, id) => {
      //   naLinkedDataFixed.push({ id, value });
      // });
      // let saLinkedDataFixed = [];
      // saLinkedData.forEach((value, id) => {
      //   saLinkedDataFixed.push({ id, value });
      // });
      // let asLinkedDataFixed = [];
      // asLinkedData.forEach((value, id) => {
      //   asLinkedDataFixed.push({ id, value });
      // });
      // let auLinkedDataFixed = [];
      // auLinkedData.forEach((value, id) => {
      //   auLinkedDataFixed.push({ id, value });
      // });
      // this.linkedMapData[0].linkedchart.data = euLinkedDataFixed;
      // this.linkedMapData[1].linkedchart.data = afLinkedDataFixed;
      // this.linkedMapData[2].linkedchart.data = naLinkedDataFixed;
      // this.linkedMapData[3].linkedchart.data = saLinkedDataFixed;
      // this.linkedMapData[4].linkedchart.data = asLinkedDataFixed;
      // this.linkedMapData[5].linkedchart.data = auLinkedDataFixed;
      // console.log("world map data: ", this.worldMapData);
      // console.log("world map data by region: ", this.linkedMapData);
      this.fetchingWorldMapData = false;

      this.yearlyGain = currentYearGain.toFixed(2);
      this.yearlyDrain = currentYearDrain.toFixed(2);
      this.yearlyGainStr = shortenNumber(this.yearlyGain, 1) + " mAh";
      this.yearlyDrainStr = shortenNumber(this.yearlyDrain, 1) + " mAh";
      this.yearlyGraphSubCaption = currentYear.toString();
      this.yearlyGraphDataset = [
        {
          label: "Gain",
          value: this.yearlyGain,
        },
        {
          label: "Drain",
          value: this.yearlyDrain,
        },
      ];
      this.fetchingYearlyData = false;
    },
  },
};
</script>

<style>
.card-title {
  font-size: 0.1em;
}
.card-text {
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.graph-container {
  padding: 0;
  margin: 0;
  position: relative;
}
.graph-absolute {
  margin: 0px;
  position: absolute;
  border-radius: 7px;
}
.pop-up-graph-container {
  top: 30%;
  left: 30%;
  width: 30%;
  height: 30%;
  border-radius: 7px;
}
.card-graph {
}
.doughnut-graph {
}
.column-graph {
}
</style>
