<template>
  <v-container>
    <v-container class="overall-container">
      <div class="group-title">
        All my Powerfoyle devices
      </div>
      <v-row>
        <v-col class="gain-drain-chart" cols="12" sm="12" md="4" lg="4" xl="4">
          <overlapped-column-2d
            v-bind:width="totalX"
            v-bind:height="totalY"
            v-bind:caption="weekOverallGraphTitle"
            v-bind:categories="weekOverallGraphCategories"
            v-bind:dataset="weekOverallGraphData"
          />
        </v-col>
        <v-col class="gain-drain-chart" cols="12" sm="12" md="4" lg="4" xl="4">
          <ms-column-2d
            v-bind:width="totalX"
            v-bind:height="totalY"
            v-bind:caption="monthGraphTitle"
            v-bind:categories="monthGraphCategories"
            v-bind:dataset="monthGraphData"
          />
        </v-col>
        <v-col class="gain-drain-chart" cols="12" sm="12" md="4" lg="4" xl="4">
          <angular-gauge
            v-bind:width="totalX"
            v-bind:height="totalY"
            v-bind:caption="monthGraphTitle"
            v-bind:categories="monthGraphCategories"
            v-bind:dataset="monthGraphData"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container class="day-container">
      <div class="group-title">
        Favorite Powerfoyle device
      </div>
      <v-row>
        <v-col class="gain-drain-chart" cols="12" sm="12" md="4" lg="4" xl="4">
          <ms-column2d
            v-bind:width="dayX"
            v-bind:height="dayY"
            v-bind:caption="weekGraphTitle"
            v-bind:categories="weekGraphCategories"
            v-bind:dataset="weekGraphData"
          />
        </v-col>
        <v-col class="gain-drain-chart" cols="12" sm="12" md="4" lg="4" xl="4">
          <ms-column2d
            v-bind:width="dayX"
            v-bind:height="dayY"
            v-bind:caption="monthGraphTitle"
            v-bind:categories="monthGraphCategories"
            v-bind:dataset="monthGraphData"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container class="live-container">
      <div class="group-title">
        Current Gain-Drain from my favorite device
      </div>
      <v-row>
        <v-col class="gain-drain-chart" cols="12">
          <line-2d v-bind:width="liveX" v-bind:height="liveY" v-bind:caption="liveTitle" v-bind:data="liveData" />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import MsColumn2d from "@/components/charts/general/MsColumn2d.vue";
import OverlappedColumn2d from '@/components/charts/general/OverlappedColumn2d.vue';
import Line2d from "@/components/charts/general/Line2d.vue";
import AngularGauge from '@/components/charts/widget/AngularGauge.vue';
// import { fetchLastDayTotalByUserEmail } from "@/services/device_functions.js";

export default {
  name: "UserDashboard",
  components: {
    MsColumn2d,
    OverlappedColumn2d,
    Line2d,
    AngularGauge,
  },
  data: () => ({
    totalX: "100%",
    totalY: "25%",
    dayX: "100%",
    dayY: "25%",
    liveX: "100%",
    liveY: "25%",
    dayFavoriteGraphTitle: "",
    weekOverallGraphTitle: "asdfasfd",
    weekOverallGraphCategories: [
    {
      category: [
        {
          label: "Oliver"
        },
        {
          label: "Andy"
        },
        {
          label: "Peter"
        },
        {
          label: "Natasha"
        },
        {
          label: "Robert"
        },
        {
          label: "Bruce"
        },
        {
          label: "Wanda"
        }
      ]
    }
  ],
    weekOverallGraphData: [
    {
      seriesname: "Target",
      data: [
        {
          value: "250000"
        },
        {
          value: "200000"
        },
        {
          value: "300000"
        },
        {
          value: "200000"
        },
        {
          value: "270000"
        },
        {
          value: "350000"
        },
        {
          value: "200000"
        }
      ]
    },
    {
      seriesname: "Achieved",
      data: [
        {
          value: "260000"
        },
        {
          value: "180000"
        },
        {
          value: "290000"
        },
        {
          value: "195000"
        },
        {
          value: "300000"
        },
        {
          value: "380000"
        },
        {
          value: "210000"
        }
      ]
    }
  ],
    weekGraphTitle: "Last 7 days Gain-Drain",
    monthGraphTitle: "Last month Gain-Drain",
    liveTitle: "Gain-Drain for favorite Device",

    angularGauge: {
  chart: {
    caption: "Walmart's Customer Satisfaction Score",
    subcaption: "2017",
    lowerlimit: "0",
    upperlimit: "100",
    showvalue: "1",
    numbersuffix: "%",
    theme: "fusion"
  },
  colorrange: {
    color: [
      {
        minvalue: "0",
        maxvalue: "50",
        code: "#ff2703"
      },
      {
        minvalue: "50",
        maxvalue: "75",
        code: "#FFC533"
      },
      {
        minvalue: "75",
        maxvalue: "100",
        code: "#337700"
      }
    ]
  },
  dials: {
    dial: [
      {
        value: "71",
        tooltext: "<b>9%</b> lesser that target"
      }
    ]
  },
  trendpoints: {
    point: [
      {
        startvalue: "80",
        displayvalue: "Target",
        thickness: "2",
        color: "#E15A26",
        usemarker: "1",
        markerbordercolor: "#E15A26",
        markertooltext: "80%"
      }
    ]
  }
    },

    weekGraphCategories: [
      {
        category: [
          {
            label: "",
          },
        ],
      },
    ],
    weekGraphData: [
      {
        seriesname: "Gain",
        data: [
          {
            value: 60,
            color: "#337700",
          },
        ],
      },
      {
        seriesname: "Goal",
        data: [
          {
            value: 60,
            color: "#333777",
          },
        ],
      },
      {
        seriesname: "Drain",
        data: [
          {
            value: 35,
            color: "#ff2703",
          },
        ],
      },
    ],
    monthGraphCategories: [
      {
        category: [
          {
            label: "",
          },
        ],
      },
    ],
    monthGraphData: [
      {
        seriesname: "Gain",
        data: [
          {
            value: 60,
            color: "#337700",
          },
        ],
      },
      {
        seriesname: "Drain",
        data: [
          {
            value: 35,
            color: "#ff2703",
          },
        ],
      },
    ],
    liveData: [
      {
        label: "Mon",
        value: "15123",
      },
      {
        label: "Tue",
        value: "14233",
      },
      {
        label: "Wed",
        value: "23507",
      },
      {
        label: "Thu",
        value: "9110",
      },
      {
        label: "Fri",
        value: "15529",
      },
      {
        label: "Sat",
        value: "20803",
      },
      {
        label: "Sun",
        value: "19202",
      },
    ],
  }),

  computed: {
    accessToken() {
      return this.$store.state.auth.user.accessToken;
    },
    currentUserEmail() {
      return this.$store.state.auth.user.email;
    },
  },

  created() {
    this.fetch7DaysTotalByUserEmail();
    this.fetchLastMonthTotalByUserEmail();
  },

  methods: {
    async fetch7DaysTotalByUserEmail() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      const today = new Date();
      let fromDay = new Date();
      fromDay.setDate(fromDay.getDate() - 6);
      today.setDate(today.getDate() + 1);
      reqEndpoint =
        this.$globals.API_URL +
        this.$globals.RES_DEVICE_EVENTS +
        `/sum?email=` +
        this.currentUserEmail +
        `&fromDate=${fromDay.toISOString().substring(0, 10)}&toDate=${today.toISOString().substring(0, 10)}`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      console.log("data: ", data);
      let date;
      if (data.length == 0) {
        this.weekGraphCategories = [];
        this.weekGraphData = [];
        return [];
      }

      let category = [];
      let gainData = [];
      let gainGoalData = [];
      let drainData = [];
      this.weekOverallGraphCategories[0].category = [];
      this.weekOverallGraphData[0].data = [];
      this.weekOverallGraphData[1].data = [];
      for (let i = 0; i < data.length; i += 1) {
        data[i].gain_aggregated = data[i].gain_aggregated.toFixed(3);
        data[i].drain_aggregated = data[i].drain_aggregated.toFixed(3);
        date = new Date(data[i].id); // new Date(data[i].id).toLocaleString("sv-SE");
        category.push({ label: date.getDate().toString() });
        gainData.push({ value: data[i].gain_aggregated, color: "#337700" });
        gainGoalData.push({ value: data[i].gain_aggregated, color: "#333777" });
        drainData.push({ value: data[i].drain_aggregated, color: "#ff2703" });
      }
      this.weekGraphCategories[0].category = category;
      this.weekGraphData[0].data = gainData;
      this.weekGraphData[1].data = drainData;
      console.log("date: ", category);
    },

    async fetchLastMonthTotalByUserEmail() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      const today = new Date();
      let fromDay = new Date();
      fromDay.setDate(1);
      today.setDate(today.getDate() + 1);
      reqEndpoint =
        this.$globals.API_URL +
        this.$globals.RES_DEVICE_EVENTS +
        `/sum?email=` +
        this.currentUserEmail +
        `&fromDate=${fromDay.toISOString().substring(0, 10)}&toDate=${today.toISOString().substring(0, 10)}`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      console.log("data: ", data);
      let date;
      if (data.length == 0) {
        this.monthGraphCategories = [];
        this.monthGraphData = [];
        return [];
      }

      let category = [];
      let gainData = [];
      let drainData = [];
      this.monthGraphCategories[0].category = [];
      this.monthGraphData[0].data = [];
      this.monthGraphData[1].data = [];
      for (let i = 0; i < data.length; i += 1) {
        data[i].gain_aggregated = data[i].gain_aggregated.toFixed(3);
        data[i].drain_aggregated = data[i].drain_aggregated.toFixed(3);
        date = new Date(data[i].id); // new Date(data[i].id).toLocaleString("sv-SE");
        category.push({ label: date.getDate().toString() });
        gainData.push({ value: data[i].gain_aggregated, color: "#337700" });
        drainData.push({ value: data[i].drain_aggregated, color: "#ff2703" });
      }
      this.monthGraphCategories[0].category = category;
      this.monthGraphData[0].data = gainData;
      this.monthGraphData[1].data = drainData;
      console.log("date: ", category);
    },
  },
};
</script>

<style>
.overall-container {
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
  margin-top: -20px;
}
.day-container {
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
}
.live-container {
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
}
.group-title {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 10px;
}
.gain-drain-chart {
  /* margin: 0px; */
  /* padding: 0px; */
  border-radius: 10px;
}
.heat-map-us {
  /* margin: 0px; */
  /* padding: 0px; */
}
</style>
