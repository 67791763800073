<template>
  <div class="main-container">
    <v-expand-transition>
      <v-alert v-if="showMessage" class="alert" :type="messageType" dark>
        {{ message }}
      </v-alert>
    </v-expand-transition>
    <h2 class="page-title">User Settings</h2>
    <v-row class="cards-container">
      <v-col class="card-container">
        <v-card class="card">
          <h2 class="heading">Powerfoyle SDK API Key</h2>
          <v-card-actions>
            <v-row justify="center">
              <v-btn type="submit" :disabled="invalid" color="primary" @click="getSdkApiKey">
                Get API KEY
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
        <v-dialog v-model="sdkApiKeyErrorShow" width="50%">
          <v-card class="pa-2">
            <v-card-title class="justify-center red-text">Error</v-card-title>
            <v-card-text>
              <v-text-area class="red-text">{{ sdkApiKeyError }}</v-text-area>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="sdkApiKeyShow" width="25%">
          <v-card class="pa-2">
            <v-card-title class="justify-center">API Key</v-card-title>
            <v-card-text>
              <v-text-field
                :value="displayValue"
                text="test"
                outlined
                readonly
                dense
                :class="{ 'green-field': sdkApiKeyCopied }"
              >
                <template v-slot:append-outer>
                  <v-btn icon @click="copyApiKeyToClipboard" density="compact">
                    <v-icon v-if="!sdkApiKeyCopied" color="blue">mdi-content-copy</v-icon>
                    <v-icon v-else color="green">mdi-checkbox-marked-circle-outline</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col class="card-container">
        <v-card class="card">
          <h2 class="heading">Change your password</h2>
          <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="handleUpdatePassword">
              <div class="form-fields">
                <ValidationProvider name="Current Password" vid="currentPassword" rules="required" v-slot="{ errors }">
                  <v-text-field
                    v-model="currentPassword"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    placeholder="Current Password"
                    required
                    solo
                    dense
                    :error-messages="errors[0]"
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>
                </ValidationProvider>
              </div>
              <div class="form-fields">
                <ValidationProvider
                  name="New Password"
                  vid="newPassword"
                  rules="required|min:8|newPassword:@currentPassword"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="newPassword"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    placeholder="New Password"
                    required
                    solo
                    dense
                    hint="At least 8 characters"
                    :error-messages="errors[0]"
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </ValidationProvider>
              </div>
              <div class="form-fields">
                <ValidationProvider
                  name="Confirm New Password"
                  rules="required|confirmNewPassword:@newPassword"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="confirmNewPassword"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    placeholder="Retype New Password"
                    required
                    solo
                    dense
                    :error-messages="errors[0]"
                    @click:append="show2 = !show2"
                  ></v-text-field>
                </ValidationProvider>
              </div>
              <div class="form-fields">
                <v-btn class="button" type="submit" :disabled="invalid" color="primary" @click="handleUpdatePassword">
                  Update Password
                </v-btn>
              </div>
            </form>
          </ValidationObserver>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { extend } from "vee-validate";

extend("newPassword", {
  params: ["currentPassword"],
  validate: (value, { currentPassword }) => {
    return value !== currentPassword;
  },
  message: "The new password must be different from the current",
});

extend("confirmNewPassword", {
  params: ["newPassword"],
  validate(value, { newPassword }) {
    return value === newPassword;
  },
  message: "Password confirmation does not match",
});

export default {
  name: "UserSettings",

  data() {
    return {
      activeEnv: null,
      apiUrl: null,
      message: null,
      messageType: "success",
      showMessage: false,
      loading: false,
      sdkApiKey: null,
      sdkApiKeyShow: false,
      sdkApiKeyCopied: false,
      sdkApiKeyError: null,
      sdkApiKeyErrorShow: false,
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      show1: false,
      show2: false,
      showPassword: false,
      newPasswordError: null,
      confirmNewPasswordError: null,
    };
  },
  computed: {
    environments() {
      return localStorage.getItem("userEnvironments");
    },
    environment() {
      return localStorage.getItem("activeEnvironment");
    },
    currentUser() {
      return JSON.parse(localStorage.getItem("user"));
    },
    accessToken() {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    },
    displayValue() {
      return this.sdkApiKeyCopied ? 'Copied to clipboard' : this.sdkApiKey;
    },
  },
  created() {
    // this.getSdkApiKey();
  },
  methods: {
    getActiveEnvironment() {
      const environments = this.environments.split(",");
      environments.forEach((environment) => {
        switch (environment) {
          case this.$globals.PROD_ENV: {
            this.apiUrl = this.$globals.PROD_API_URL;
            break;
          }
          case this.$globals.STAGE_ENV: {
            this.apiUrl = this.$globals.STAGE_API_URL;
            break;
          }
          case this.$globals.PROTO_ENV: {
            this.apiUrl = this.$globals.PROTO_API_URL;
            break;
          }
          default:
            this.apiUrl = this.$globals.DEV_API_URL;
            break;
        }
      });
    },

    async getSdkApiKey() {
      this.sdkApiKeyShow = false;
      this.sdkApiKeyErrorShow = false;
      this.sdkApiKeyCopied = false;
      this.getActiveEnvironment();
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      reqEndpoint = this.apiUrl + this.$globals.RES_CUSTOMER_SDK_API_KEY;
      // console.log("request: ", reqEndpoint);
      const response = await fetch(reqEndpoint, { headers: h });
      const data = await response.json();
      // console.log('data: ', data);
      switch (response.status) {
        case 401:
          this.$store.dispatch("auth/logout").then(
            () => {
              this.$router.push("/login");
            },
            (error) => {
              this.message = (error.response && error.response.data) || error.message || error.toString();
            }
          );
          break;
        case 404:
        case 410: // Expired
          this.sdkApiKeyError = data.message;
          this.sdkApiKeyErrorShow = true;
          break;
        default:
          break;
      }
      if (data.success == true) {
        this.sdkApiKey = data.sdk_api_key;
        this.sdkApiKeyShow = true;
        // console.log("sdkApiKey: ", this.sdkApiKey);
      }
    },

    copyApiKeyToClipboard() {
      navigator.clipboard.writeText(this.sdkApiKey);
      this.sdkApiKeyCopied = true;
      setTimeout(() => {
        this.sdkApiKeyShow = false;
      }, 3000);
    },

    async updatePassword(apiUrl) {
      try {
        const h = new Headers();
        h.append("Authorization", "Bearer " + this.accessToken);
        h.append("Content-Type", "application/json");
        h.append("Accept", "application/json");

        var payload = JSON.stringify({
          old_password: this.currentPassword,
          new_password: this.newPassword,
        });

        var requestOptions = {
          method: "PUT",
          headers: h,
          body: payload,
        };

        var reqEndpoint = "";
        reqEndpoint = apiUrl + this.$globals.RES_USER_UPDATE_PASSWORD;
        const response = await fetch(reqEndpoint, requestOptions);
        return response;
      } catch (e) {
        console.error("error: ", e);
        return e;
      }
    },

    async handleUpdatePassword() {
      this.environments.forEach(async (environment) => {
        let apiUrl = null;
        switch (environment) {
          case this.$globals.PROD_ENV: {
            apiUrl = this.$globals.PROD_API_URL;
            break;
          }
          case this.$globals.STAGE_ENV: {
            apiUrl = this.$globals.STAGE_API_URL;
            break;
          }
          case this.$globals.PROTO_ENV: {
            apiUrl = this.$globals.PROTO_API_URL;
            break;
          }
          default:
            break;
        }
        const res = await this.updatePassword(apiUrl);
        const data = await res.json();
        // console.log(data);
        this.message = data.message;
        switch (res.status) {
          case 200:
            this.messageType = "success";
            break;
          case 417:
            this.messageType = "error";
            break;
          default:
            this.messageType = "success";
            break;
        }
        this.showMessage = true;
        window.setTimeout(() => {
          this.showMessage = false;
        }, 3000);
      });
    },
  },
};
</script>

<style scoped>
.main-container {
  margin-top: 3%;
  width: 100%;
}
.alert-container {
  width: 100%;
  position: absolute;
}
.alert {
  width: 100%;
  position: absolute;
}
.cards-container {
  margin-left: 10%;
  margin-right: 10%;
}
.card-container {
  margin-left: 20px;
  margin-right: 20px;
}
.card {
  border-radius: 8px;
}
.error-message {
  color: red;
}
.v-text-field__details .v-messages__wrapper .v-messages__message {
  font-size: 20px;
  font-weight: 500;
  color: green;
}
.green-field /deep/ input {
  color: darkgreen;
}
.red-text {
  color: red;
}
.box-container {
  padding: 20px;
  margin: 0 auto;
  width: 25rem;
  box-shadow: beige;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
}
.page-title {
  text-align: center;
  font-weight: 300;
  color: #444;
  margin: 0 auto 45px;
  font-size: 35px;
  line-height: 38px;
  text-transform: none;
  letter-spacing: 0;
}
.heading {
  text-align: center;
  font-weight: 300;
  color: #444;
  margin: 0 auto 45px;
  font-size: 25px;
  line-height: 38px;
  text-transform: none;
  letter-spacing: 0;
}
.title-center {
  justify-content: center;
}
.form-fields,
.form-fields button {
  width: 100%;
  margin: 5px 0;
  line-height: 28px;
  border-radius: 5px;
}
</style>
