<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <v-container class="graph-container">
          <v-overlay
            v-if="fetchingTotalUsers"
            class="d-flex justify-center graph-absolute"
            :value="fetchingTotalUsers"
            :opacity="0.3"
          >
            <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
          </v-overlay>
          <v-card color="#e0e0c0" :min-height="minTotalsHeight">
          <!-- <v-card color="#e0e0c0" :min-height="minTotalsHeight" @click="onNewUsersData"> -->
            <v-card-title class="card-title"> Total Users: {{ totalUsers }} </v-card-title>
          </v-card>
        </v-container>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <v-container class="graph-container">
          <v-overlay
            v-if="fetchingTotalDevices"
            class="d-flex justify-center graph-absolute"
            :value="fetchingTotalDevices"
            :opacity="0.3"
          >
            <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
          </v-overlay>
          <v-card color="#e0e0c0" :min-height="minTotalsHeight">
          <!-- <v-card color="#e0e0c0" :min-height="minTotalsHeight" @click="onNewDevicesData"> -->
            <v-card-title class="card-title"> Total Devices: {{ totalDevices }} </v-card-title>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
    <!-- <v-dialog v-model="showNewUsersData" max-width="50%" :overlay-opacity="0.8">
      <v-container class="graph-container">
        <v-overlay
          v-if="fetchingNewUsersData"
          class="d-flex justify-center graph-absolute"
          :value="fetchingNewUsersData"
          :opacity="0.3"
        >
          <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
        </v-overlay>
        <v-card class="card-graph" color="#e0e0c0" :min-height="minGraphHeight" width="100%">
          <line-2d
            v-bind:width="newUsersGraphWidth"
            v-bind:height="newUsersGraphHeight"
            v-bind:dataSourceData="newUsersDataSource"
            v-bind:dataEmptyMessageData="newUsersDataEmptyMessage"
          />
        </v-card>
      </v-container>
    </v-dialog>
    <v-dialog v-model="showNewDevicesData" max-width="50%" :overlay-opacity="0.8">
      <v-container class="graph-container">
        <v-overlay
          v-if="fetchingNewDevicesData"
          class="d-flex justify-center graph-absolute"
          :value="fetchingNewDevicesData"
          :opacity="0.3"
        >
          <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
        </v-overlay>
        <v-card class="card-graph" color="#e0e0c0" :min-height="minGraphHeight" width="100%">
          <line-2d
            v-bind:width="newDevicesGraphWidth"
            v-bind:height="newDevicesGraphHeight"
            v-bind:dataSourceData="newDevicesDataSource"
            v-bind:dataEmptyMessageData="newDevicesDataEmptyMessage"
          />
        </v-card>
      </v-container>
    </v-dialog> -->
    <v-row>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-container class="graph-container">
          <v-overlay
            v-if="fetchingYearlyData"
            class="d-flex justify-center graph-absolute"
            :value="fetchingYearlyData"
            :opacity="0.3"
          >
            <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
          </v-overlay>
          <!-- <v-card color="#e0e0c0" :min-height="minAggregatedHeight" @click="onShowYearlyData"> -->
          <v-card color="#e0e0c0" :min-height="minAggregatedHeight">
            <v-card-title class="card-title">
              Yearly Data
            </v-card-title>
            <v-card-text class="card-text">
              <b>Gain:</b> {{ yearlyGainStr }}<br />
              <b>Drain:</b> {{ yearlyDrainStr }}
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-container class="graph-container">
          <v-overlay
            v-if="fetchingMonthlyData"
            class="d-flex justify-center graph-absolute"
            :value="fetchingMonthlyData"
            :opacity="0.3"
          >
            <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
          </v-overlay>
          <v-card color="#e0e0c0" :min-height="minAggregatedHeight">
            <v-card-title class="card-title">
              Monthly Data
            </v-card-title>
            <v-card-text class="card-text">
              <b>Gain:</b> {{ monthlyGainStr }}<br />
              <b>Drain:</b> {{ monthlyDrainStr }}
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-container class="graph-container">
          <v-overlay
            v-if="fetchingWeeklyData"
            class="d-flex justify-center graph-absolute"
            :value="fetchingWeeklyData"
            :opacity="0.3"
          >
            <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
          </v-overlay>
          <v-card color="#e0e0c0" :min-height="minAggregatedHeight">
            <v-card-title class="card-title">
              Weekly Data
            </v-card-title>
            <v-card-text class="card-text">
              <b>Gain:</b> {{ weeklyGainStr }}<br />
              <b>Drain:</b> {{ weeklyDrainStr }}
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-container class="graph-container">
          <v-overlay
            v-if="fetchingDailyData"
            class="d-flex justify-center graph-absolute"
            :value="fetchingDailyData"
            :opacity="0.3"
          >
            <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
          </v-overlay>
          <!-- <v-card color="#e0e0c0" :min-height="minAggregatedHeight" @click="onShowDailyData"> -->
          <v-card color="#e0e0c0" :min-height="minAggregatedHeight">
            <v-card-title class="card-title">
              Daily Data
            </v-card-title>
            <v-card-text class="card-text">
              <b>Gain:</b> {{ dailyGainStr }}<br />
              <b>Drain:</b> {{ dailyDrainStr }}
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-model="showYearlyData" max-width="30%" :overlay-opacity="0.8">
      <v-container class="graph-container">
        <v-overlay
          v-if="fetchingYearlyData"
          class="d-flex justify-center graph-absolute"
          :value="fetchingYearlyData"
          :opacity="0.3"
        >
          <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
        </v-overlay>
        <v-card class="card-graph" color="#e0e0c0" :min-height="minGraphHeight" width="100%">
          <doughnut-2d
            v-bind:width="yearlyGraphWidth"
            v-bind:height="yearlyGraphHeight"
            v-bind:caption="yearlyGraphCaption"
            v-bind:subCaption="yearlyGraphSubCaption"
            v-bind:data="yearlyGraphDataset"
          />
        </v-card>
      </v-container>
    </v-dialog>
    <v-dialog v-model="showDailyData" max-width="50%" :overlay-opacity="0.8">
      <v-container class="graph-container">
        <v-overlay
          v-if="fetchingDailyData"
          class="d-flex justify-center graph-absolute"
          :value="fetchingDailyData"
          :opacity="0.3"
        >
          <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
        </v-overlay>
        <v-card class="card-graph" color="#e0e0c0" :min-height="minGraphHeight" width="100%">
          <ms-column-2d
            v-bind:width="dailyGraphWidth"
            v-bind:height="dailyGraphHeight"
            v-bind:caption="dailyGraphCaption"
            v-bind:subCaption="dailyGraphSubCaption"
            v-bind:categories="dailyGraphCategories"
            v-bind:dataset="dailyGraphDataset"
            v-bind:yAxisName="dailyYAxisName"
          />
        </v-card>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
// import MsColumn2d from "@/components/charts/general/MsColumn2d.vue";
// import Doughnut2d from "@/components/charts/general/Doughnut2d.vue";
// import Line2d from "@/components/charts/general/Line2d.vue";
import { shortenNumber } from "../../../utils/numberFormat.js";

export default {
  name: "StagingDashboard",
  components: {
    // MsColumn2d,
    // Line2d,
    // Doughnut2d,
  },
  data: () => ({
    mapWidth: "100%",
    mapHeight: "100%",
    fetchingTotalUsers: false,
    fetchingTotalDevices: false,
    totalUsers: null,
    totalDevices: null,

    show: true,

    showNewUsersData: false,
    showNewDevicesData: false,
    showDailyData: false,
    dailyData: [],
    showYearlyData: false,

    dailyGain: null,
    dailyDrain: null,
    dailyGainStr: null,
    dailyDrainStr: null,
    weeklyGain: null,
    weeklyDrain: null,
    weeklyGainStr: null,
    weeklyDrainStr: null,
    monthlyGain: null,
    monthlyDrain: null,
    monthlyGainStr: null,
    monthlyDrainStr: null,
    yearlyGain: null,
    yearlyDrain: null,
    yearlyGainStr: null,
    yearlyDrainStr: null,

    minGainValue: null,
    maxGainValue: null,

    fetchingNewUsersData: false,
    newUsersDataSource: null,
    newUsersDataEmptyMessage: "",
    newUsersGraphWidth: "100%",
    newUsersGraphHeight: "100%",

    fetchingNewDevicesData: false,
    newDevicesDataSource: null,
    newDevicesDataEmptyMessage: "",
    newDevicesGraphWidth: "100%",
    newDevicesGraphHeight: "100%",

    fetchingYearlyData: false,
    fetchingMonthlyData: false,
    fetchingWeeklyData: false,
    fetchingDailyData: false,

    fetchingYearlyGraphData: false,
    fetchingDailyGraphData: false,

    dailyGraphCaption: "Daily Data",
    dailyGraphSubCaption: "",
    dailyGraphCategories: [],
    dailyGraphDataset: [
      {
        seriesname: "Gain",
        data: [],
      },
      {
        seriesname: "Drain",
        data: [],
      },
    ],
    dailyYAxisName: "Current (mAh)",
    dailyGraphWidth: "100%",
    dailyGraphHeight: "100%",
    weeklyGraphCaption: "Weekly Data",
    weeklyGraphSubCaption: "",
    weeklyGraphCategories: [],
    weeklyGraphDataset: [
      {
        seriesname: "Gain",
        data: [],
      },
      {
        seriesname: "Drain",
        data: [],
      },
    ],
    yearlyGraphCaption: "Yearly Data",
    yearlyGraphSubCaption: "",
    yearlyGraphDataset: null,
    yearlyGraphWidth: "100%",
    yearlyGraphHeight: "100%",
    minTotalsHeight: (((1 / 9) * 1) / 2) * window.innerHeight,
    minAggregatedHeight: (((1 / 8) * 1) / 2) * window.innerHeight,
    minGraphHeight: (((1 / 2) * 1) / 2) * window.innerHeight,
    minMapHeight: (2 / 3) * window.innerHeight,
  }),

  mounted() {
    // this.onResize();
    // this.fetchDailyMeasurements();
    // this.fetchWeeklyMeasurements();
    // this.fetchMonthlyMeasurements();
    // this.fetchYearlyMeasurements();
    // this.fetchTotalUsers();
    // this.fetchTotalDevices();
    // this.fetchNewUsersForLastPeriod();
    // this.fetchNewDevicesForLastPeriod();
  },
  computed: {
    accessToken() {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    },
  },

  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    onClickContinent(e) {
      console.log("event value: ", e);
    },
    onNewUsersData() {
      if (!this.newUsersDataSource && this.totalUsers > 0) {
        this.fetchNewUsersForLastPeriod();
      }
      this.showNewUsersData = !this.showNewUsersData;
    },
    onNewDevicesData() {
      if (!this.newDevicesDataSource && this.totalDevices > 0) {
        this.fetchNewDevicesForLastPeriod();
      }
      this.showNewDevicesData = !this.showNewDevicesData;
    },
    onShowYearlyData() {
      this.fetchingYearlyData = true;
      this.showYearlyData = !this.showYearlyData;
      this.fetchingYearlyData = false;
    },
    onShowDailyData() {
      this.showDailyData = !this.showDailyData;
      this.fetchingDailyData = true;
      this.dailyGain = this.dailyData[this.dailyData.length - 1].gain_sum.toFixed(2) + " mAh";
      this.dailyDrain = this.dailyData[this.dailyData.length - 1].drain_sum.toFixed(2) + " mAh";
      let category = [];
      let gainData = [];
      let drainData = [];
      this.dailyData.forEach((datapoint) => {
        category.push({
          label: datapoint.aggregated_id.year + "-" + datapoint.aggregated_id.month + "-" + datapoint.aggregated_id.day,
        });
        gainData.push({ value: datapoint.gain_sum });
        drainData.push({ value: datapoint.drain_sum });
      });
      this.dailyGraphSubCaption = "Week " + this.dailyData[this.dailyData.length - 1].aggregated_id.week.toString();
      this.dailyGraphCategories = [{ category }];
      this.dailyGraphDataset[0].title = gainData;
      this.dailyGraphDataset[0].data = gainData;
      this.dailyGraphDataset[1].data = drainData;
      this.fetchingDailyData = false;
    },

    async fetchTotalUsers() {
      this.fetchingTotalUsers = true;
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      // reqEndpoint = this.$globals.API_URL + this.$globals.RES_DEVICE_EVENTS_WEEKLY_MEASUREMENTS + `?customerName=${this.customerName}`;
      reqEndpoint = this.$globals.STAGE_API_URL + this.$globals.RES_DEVICE_EVENTS_TOTAL_USERS;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      this.totalUsers = data.length > 0 ? data[data.length - 1].sum_users : 0;
      this.fetchingTotalUsers = false;
    },
    async fetchTotalDevices() {
      this.fetchingTotalDevices = true;
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      // reqEndpoint = this.$globals.API_URL + this.$globals.RES_DEVICE_EVENTS_WEEKLY_MEASUREMENTS + `?customerName=${this.customerName}`;
      reqEndpoint = this.$globals.STAGE_API_URL + this.$globals.RES_DEVICE_EVENTS_TOTAL_DEVICES;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();

      this.totalDevices = data.length > 0 ? data[data.length - 1].sum_devices : 0;
      this.fetchingTotalDevices = false;
    },

    async fetchNewUsersForLastPeriod() {
      if (this.totalUsers == 0) {
        return;
      }
      this.fetchingNewUsersData = true;
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      reqEndpoint = this.$globals.STAGE_API_URL + this.$globals.RES_DEVICE_EVENTS_TOTAL_NEW_USERS + "?days=10";
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      if (data.length == 0) {
        return;
      }
      let newUsersMap = new Map();
      data.forEach((newUser) => {
        // console.log('new user: ', newUser.registered_date.toString().substring(0, 10));
        let count = 0;
        if (newUsersMap.has(newUser.registered_date.toString().substring(0, 10))) {
          // console.log('found: ', newUsersMap.get(newUser.registered_date.toString().substring(0, 10)));
          count = newUsersMap.get(newUser.registered_date.toString().substring(0, 10));
          // console.log('count: ', count);
        }
        newUsersMap.set(newUser.registered_date.toString().substring(0, 10), count + 1);
      });
      var mapNewUsersSorted = new Map([...newUsersMap.entries()].sort());
      // console.log(mapNewUsersSorted);

      let category = [];
      let newUsersData = [];
      mapNewUsersSorted.forEach((value, id) => {
        // console.log('id: ', id);
        // console.log('value: ', value);
        category.push({ label: id });
        newUsersData.push({ value: value });
      });
      if (newUsersData.length == 0) {
        this.newUsersDataEmptyMessage = "No New Users In The Last 10 Days";
      } else {
        this.newUsersDataSource = {
          chart: {
            caption: "New Users",
            subCaption: "For the last 10 Days",
            xAxisName: "Date",
            yAxisName: "Number of New Users",
            bgColor: "#e0e0c0",
            showhovereffect: 1,
            numbersuffix: "",
            drawCrossLine: 1,
            crossLineColor: "#777777",
            showValues: 1,
            showLegend: 1,
            labelDisplay: "ROTATE",
            slantLabels: 1,
            anchorSides: 4,
            lineColor: "#3300ee",
            anchorBgColor: "#3300ee",
            anchorBorderColor: "#3300ee",
            plotHighlightEffect: "fadeout|anchorBgColor=ff0000, anchorBgAlpha=50",
            legendPosition: "bottom-right",
            chartLeftMargin: 0,
            chartRightMargin: 10,
            chartTopMargin: 0,
            chartBottomMargin: 0,
            exportEnabled: 1,
            plottooltext: "<b>$label</b><br>New $seriesName: <b>$dataValue</b>",
            theme: "fusion",
          },
          categories: [{ category }],
          dataset: [
            {
              seriesname: "Users",
              data: newUsersData,
            },
          ],
        };
      }
      this.fetchingNewUsersData = false;
    },

    async fetchNewDevicesForLastPeriod() {
      this.fetchingNewDevicesData = true;
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      reqEndpoint = this.$globals.STAGE_API_URL + this.$globals.RES_DEVICE_EVENTS_TOTAL_NEW_DEVICES + "?days=10";
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      // console.log('new devices: ', data);
      if (data.length == 0) {
        return;
      }
      let newDevicesMap = new Map();
      data.forEach((newDevice) => {
        // console.log('new device: ', newDevice.registered_date.toString().substring(0, 10));
        let count = 0;
        if (newDevicesMap.has(newDevice.registered_date.toString().substring(0, 10))) {
          // console.log('found: ', newDevicesMap.get(newDevice.registered_date.toString().substring(0, 10)));
          count = newDevicesMap.get(newDevice.registered_date.toString().substring(0, 10));
          // console.log('count: ', count);
        }
        newDevicesMap.set(newDevice.registered_date.toString().substring(0, 10), count + 1);
      });
      var mapNewDevicesSorted = new Map([...newDevicesMap.entries()].sort());
      // console.log(mapNewDevicesSorted);

      let category = [];
      let newDevicesData = [];
      mapNewDevicesSorted.forEach((value, id) => {
        // console.log('id: ', id);
        // console.log('value: ', value);
        category.push({ label: id });
        newDevicesData.push({ value: value });
      });
      if (newDevicesData.length == 0) {
        this.newDevicesDataEmptyMessage = "No New Devices In The Last 10 Days";
      } else {
        this.newDevicesDataSource = {
          chart: {
            caption: "New Devices",
            subCaption: "For the last 10 Days",
            xAxisName: "Date",
            yAxisName: "Number of New Devices",
            bgColor: "#e0e0c0",
            showhovereffect: 1,
            numbersuffix: "",
            drawCrossLine: 1,
            crossLineColor: "#777777",
            showValues: 1,
            showLegend: 1,
            labelDisplay: "ROTATE",
            slantLabels: 1,
            anchorSides: 4,
            lineColor: "#ee9900",
            anchorBgColor: "#ee9900",
            anchorBorderColor: "#ee9900",
            plotHighlightEffect: "fadeout|anchorBgColor=ff0000, anchorBgAlpha=50",
            legendPosition: "bottom-right",
            chartLeftMargin: 0,
            chartRightMargin: 10,
            chartTopMargin: 0,
            chartBottomMargin: 0,
            exportEnabled: 1,
            plottooltext: "<b>$label</b><br>New $seriesName: <b>$dataValue</b>",
            theme: "fusion",
          },
          categories: [{ category }],
          dataset: [
            {
              seriesname: "Devices",
              data: newDevicesData,
            },
          ],
        };
      }
      this.fetchingNewDevicesData = false;
    },

    async fetchDailyMeasurements() {
      this.fetchingDailyData = true;
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      // reqEndpoint = this.$globals.API_URL + this.$globals.RES_DEVICE_EVENTS_WEEKLY_MEASUREMENTS + `?customerName=${this.customerName}`;
      reqEndpoint = this.$globals.STAGE_API_URL + this.$globals.RES_DEVICE_EVENTS_DAILY_MEASUREMENTS; // + `?fromDate=2022-12-01T00:00:00.000Z&toDate=2023-01-01T23:59:59.000Z`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      this.dailyData = data.length > 0 ? data : [];
      // console.log("daily data: ", data);
      this.dailyGain = data.length > 0 ? data[data.length - 1].gain_sum.toFixed(2) : 0;
      this.dailyDrain = data.length > 0 ? data[data.length - 1].drain_sum.toFixed(2) : 0;
      this.dailyGainStr = data.length > 0 ? shortenNumber(this.dailyGain, 1) : 0 + " mAh";
      this.dailyDrainStr = data.length > 0 ? shortenNumber(this.dailyDrain, 1) : 0 + " mAh";
      this.fetchingDailyData = false;
    },
    async fetchWeeklyMeasurements() {
      this.fetchingWeeklyData = true;
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      // reqEndpoint = this.$globals.API_URL + this.$globals.RES_DEVICE_EVENTS_WEEKLY_MEASUREMENTS + `?customerName=${this.customerName}`;
      reqEndpoint = this.$globals.STAGE_API_URL + this.$globals.RES_DEVICE_EVENTS_WEEKLY_MEASUREMENTS; // + `?fromDate=2022-12-01T00:00:00.000Z&toDate=2023-01-01T23:59:59.000Z`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      this.weeklyGain = data.length > 0 ? data[data.length - 1].gain_sum.toFixed(2) : 0;
      this.weeklyDrain = data.length > 0 ? data[data.length - 1].drain_sum.toFixed(2) : 0;
      this.weeklyGainStr = data.length > 0 ? shortenNumber(this.weeklyGain, 1) : 0 + " mAh";
      this.weeklyDrainStr = data.length > 0 ? shortenNumber(this.weeklyDrain, 1) : 0 + " mAh";
      this.fetchingWeeklyData = false;
    },
    async fetchMonthlyMeasurements() {
      this.fetchingMonthlyData = true;
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      // reqEndpoint = this.$globals.API_URL + this.$globals.RES_DEVICE_EVENTS_WEEKLY_MEASUREMENTS + `?customerName=${this.customerName}`;
      reqEndpoint = this.$globals.STAGE_API_URL + this.$globals.RES_DEVICE_EVENTS_MONTHLY_MEASUREMENTS; // + `?fromDate=2022-12-01T00:00:00.000Z&toDate=2023-01-01T23:59:59.000Z`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      this.monthlyGain = data.length > 0 ? data[data.length - 1].gain_sum.toFixed(2) : 0;
      this.monthlyDrain = data.length > 0 ? data[data.length - 1].drain_sum.toFixed(2) : 0;
      this.monthlyGainStr = data.length > 0 ? shortenNumber(this.monthlyGain, 1) : 0 + " mAh";
      this.monthlyDrainStr = data.length > 0 ? shortenNumber(this.monthlyDrain, 1) : 0 + " mAh";
      this.fetchingMonthlyData = false;
    },
    async fetchYearlyMeasurements() {
      this.fetchingYearlyData = true;
      this.fetchingWorldMapData = true;
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      // reqEndpoint = this.$globals.API_URL + this.$globals.RES_DEVICE_EVENTS_WEEKLY_MEASUREMENTS + `?customerName=${this.customerName}`;
      reqEndpoint = this.$globals.STAGE_API_URL + this.$globals.RES_DEVICE_EVENTS_YEARLY_MEASUREMENTS; // + `?fromDate=2022-12-01T00:00:00.000Z&toDate=2023-01-01T23:59:59.000Z`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      // console.log("world data: ", data);
      let currentYearGain = 0;
      let currentYearDrain = 0;
      let currentYear = data.length > 0 ? data[data.length - 1].aggregated_id.year : 0;
      let maxGainValue = 0;
      let minGainValue = 0;

      this.maxGainValue = (maxGainValue + maxGainValue * 0.05).toString();
      this.minGainValue = (minGainValue - minGainValue * 0.05).toString();


      this.yearlyGain = currentYearGain.toFixed(2);
      this.yearlyDrain = currentYearDrain.toFixed(2);
      this.yearlyGainStr = shortenNumber(this.yearlyGain, 1) + " mAh";
      this.yearlyDrainStr = shortenNumber(this.yearlyDrain, 1) + " mAh";
      this.yearlyGraphSubCaption = currentYear.toString();
      this.yearlyGraphDataset = [
        {
          label: "Gain",
          value: this.yearlyGain,
        },
        {
          label: "Drain",
          value: this.yearlyDrain,
        },
      ];
      this.fetchingYearlyData = false;
    },
  },
};
</script>

<style>
.card-title {
  font-size: 0.1em;
}
.card-text {
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.graph-container {
  padding: 0;
  margin: 0;
  position: relative;
}
.graph-absolute {
  margin: 0px;
  position: absolute;
  border-radius: 7px;
}
.pop-up-graph-container {
  top: 30%;
  left: 30%;
  width: 30%;
  height: 30%;
  border-radius: 7px;
}
.card-graph {
}
.doughnut-graph {
}
.column-graph {
}
</style>
