var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container"},[_c('v-row',{staticClass:"alert-container"},[(_vm.showMessage)?_c('v-alert',{staticClass:"alert",attrs:{"type":_vm.messageType,"dark":""}},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()],1),_c('div',{staticClass:"box-container"},[_c('h2',{staticClass:"heading"},[_vm._v(_vm._s(_vm.environment == "development" ? "Development Mode" : ""))]),_c('h2',{staticClass:"heading"},[_vm._v("Change Password")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleUpdatePassword.apply(null, arguments)}}},[_c('div',{staticClass:"form-fields"},[_c('ValidationProvider',{attrs:{"name":"Current Password","vid":"currentPassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autofocus":"","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"placeholder":"Current Password","required":"","solo":"","dense":"","error-messages":errors[0]},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}})]}}],null,true)})],1),_c('div',{staticClass:"form-fields"},[_c('ValidationProvider',{attrs:{"name":"New Password","vid":"newPassword","rules":"required|min:8|newPassword:@currentPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"placeholder":"New Password","required":"","solo":"","dense":"","hint":"At least 8 characters","error-messages":errors[0]},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})]}}],null,true)})],1),_c('div',{staticClass:"form-fields"},[_c('ValidationProvider',{attrs:{"name":"Confirm New Password","rules":"required|confirmNewPassword:@newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show2 ? 'text' : 'password',"placeholder":"Retype New Password","required":"","solo":"","dense":"","error-messages":errors[0]},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.confirmNewPassword),callback:function ($$v) {_vm.confirmNewPassword=$$v},expression:"confirmNewPassword"}})]}}],null,true)})],1),_c('div',{staticClass:"form-fields"},[_c('v-btn',{staticClass:"button",attrs:{"type":"submit","disabled":invalid,"color":"primary"},on:{"click":_vm.handleUpdatePassword}},[_vm._v(" Update Password ")])],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }