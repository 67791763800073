<template>
  <v-container>
    <v-row>
      <v-col class="buttons-col">
        <v-btn class="bin-btn" v-if="showBtnYear" :disabled="!btnYear" color="primary" @click="onYear">Year View</v-btn>
        <v-btn class="bin-btn" v-if="showBtnMonth" :disabled="!btnMonth" color="primary" @click="onMonth"
          >Month View</v-btn
        >
        <v-btn class="bin-btn" v-if="showBtnDay" :disabled="!btnDay" color="primary" @click="onDay">Day View</v-btn>
        <v-btn class="bin-btn" v-if="showBtnHour" :disabled="!btnHour" color="primary" @click="onHour">Hour View</v-btn>
        <v-btn class="bin-btn" v-if="showBtnMinute" :disabled="!btnMinute" color="primary" @click="onMinute"
          >Minute View</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col class="timeseries-container" cols="12">
        <fusioncharts
          v-if="minuteGraph"
          :type="type"
          :width="width"
          :height="height"
          :dataFormat="dataFormat"
          :dataSource="minuteDataSource"
          ref="minuteChartRef"
        ></fusioncharts>
        <fusioncharts
          v-else-if="hourGraph"
          :type="type"
          :width="width"
          :height="height"
          :dataFormat="dataFormat"
          :dataSource="hourDataSource"
          ref="hourChartRef"
        ></fusioncharts>
        <fusioncharts
          v-else-if="dayGraph"
          :type="type"
          :width="width"
          :height="height"
          :loadMessage="loadingText"
          :dataFormat="dataFormat"
          :dataSource="dayDataSource"
          ref="dayChartRef"
        ></fusioncharts>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FusionCharts from "fusioncharts";

export default {
  name: "TimeSeries",
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    minuteData: {
      type: Array,
    },
    hourData: {
      type: Array,
    },
    dayData: {
      type: Array,
    },
  },
  data: function() {
    return {
      gainDrainData: [],
      loadingData: false,
      loadingText: "This is loading............",
      type: "timeseries",
      minuteGraph: false,
      hourGraph: false,
      dayGraph: false,
      monthGraph: false,
      yearGraph: false,
      showBtnYear: false,
      showBtnMonth: false,
      showBtnDay: false,
      showBtnHour: false,
      showBtnMinute: false,
      btnYear: false,
      btnMonth: false,
      btnDay: false,
      btnHour: false,
      btnMinute: false,
      daysSelected: null,
      hoursSelected: null,
      minutesSelected: null,
      loadMessage: "I-https://i.gifer.com/9viU.gif",
      loadMessageImageHAlign: "middle",
      loadMessageImageVAlign: "middle",
      loadStartMessageImageAlpha: 50,
      loadStartMessageImageScale: 100,
      dataLoadStartMessage: "<I-https://i.gifer.com/9viU.gif",
      dataLoadStartMessageImageHAlign: "middle",
      dataLoadStartMessageImageVAlign: "middle",
      dataLoadStartMessageImageAlpha: 50,
      dataLoadStartMessageImageScale: 100,
      dataFormat: "json",
      minuteDataSource: {
        navigator: {
          enabled: 1,
          window: {
            style: {
              handle: {
                padding: "1px",
              },
              "handle-grip": {
                padding: "2px",
              },
              mask: {
                color: "#ffffff",
              },
            },
          },
        },
        styleDefinition: {
          "txt-red": {
            fill: "red",
          },
          "caption-size": {
            "font-size": 20,
          },
          "background-color": {
            opacity: 0,
          },
          "x-axis-label": {
            "font-size": 15,
            fill: "#333333",
          },
          "x-axis-title": {
            "font-size": 16,
            "font-weight": "bold",
            fill: "#333333",
          },
          "y-axis-label": {
            "font-size": 14,
            fill: "#333333",
          },
          "y-axis-title": {
            "font-size": 12,
            fill: "#333333",
          },
        },
        caption: {
          text: this.title,
          style: {
            text: "caption-size",
          },
        },
        subcaption: {
          text: this.subTitle,
        },
        data: [],
        chart: {
          showlegend: 1,
          exportEnabled: true,
          paletteColors: ["#009900", "#ff0000", "#efcc11", "#bf3c25", "#132277", "#57a49f", "#bf3c25"],
          // paletteColors: ["#009900", "#ff0000", "#ccaa00", "#ff1927", "#57c4ec"],
          // paletteColors: ["#337700", "#ff0000", "#ccaa00", "#132277"],
        },
        tooltip: {
          enabled: true,
          // toolText: "<b>$bin</b><br>$series.plotIdentifier <h3>$series.name: $series.dataValue mAh<h3><br>",
          outputTimeFormat: {
            day: "%Y/%m/%d (%a)",
            hour: "%Y/%m/%d (%a)",
            minute: "%Y/%m/%d (%a) %H:%M",
          },
          style: {
            container: {
              "border-color": "#aaaaaa",
              "background-color": "#eeeeee",
              padding: "1px",
            },
            text: {
              // color: "#FFFFFF",
            },
            header: {
              // color: "#ff0000",
              "font-size": "13px",
              "font-weight": "bold",
            },
            body: {
              // color: "#ff00ff",
              "font-size": "12px",
              "font-weight": "bold",
            },
          },
        },
        xAxis: {
          title: "Minutes",
          binning: {
            outputTimeFormat: {
              day: "%Y/%m/%d (%a)",
              hour: "%Y/%m/%d (%a) %H:%M",
              minute: "%Y/%m/%d (%a) %H:%M",
            },
            year: [],
            month: [],
            day: [],
            hour: [],
            minute: [1],
            second: [],
            millisecond: [],
          },
          style: {
            label: "x-axis-label",
            title: "x-axis-title",
          },
          // initialInterval: {
          //   from: null,
          //   to: null,
          // },
        },
        yaxis: [
          {
            title: "Current in Ah",
            plot: [
              {
                value: "Gain",
                connectnulldata: false,
                type: "column",
                aggregation: "Avg",
              },
              {
                value: "Drain",
                connectnulldata: false,
                type: "column",
                aggregation: "Avg",
              },
            ],
            style: {
              label: "y-axis-label",
              title: "y-axis-title",
            },
          },
          {
            title: "Lux in lux",
            plot: [
              {
                value: "Lux",
                connectnulldata: false,
                type: "column",
              },
            ],
            style: {
              label: "y-axis-label",
              title: "y-axis-title",
            },
          },
          {
            title: "Voltage in V",
            plot: [
              {
                value: "Voltage",
                connectnulldata: false,
                type: "column",
              },
            ],
            style: {
              label: "y-axis-label",
              title: "y-axis-title",
            },
          },
          {
            title: "Temperature in ℃",
            plot: [
              {
                value: "Temperature",
                connectnulldata: false,
                type: "column",
              },
            ],
            style: {
              label: "y-axis-label",
              title: "y-axis-title",
            },
          },
          {
            title: "Humidity %",
            plot: [
              {
                value: "Humidity",
                connectnulldata: false,
                type: "column",
              },
            ],
            style: {
              label: "y-axis-label",
              title: "y-axis-title",
            },
          },
          {
            title: "Logger Voltage in V",
            plot: [
              {
                value: "LoggerVoltage",
                connectnulldata: false,
                type: "column",
              },
            ],
            style: {
              label: "y-axis-label",
              title: "y-axis-title",
            },
          },
        ],
      },
      hourDataSource: {
        navigator: {
          enabled: 0,
          window: {
            style: {
              handle: {
                padding: "1px",
              },
              "handle-grip": {
                padding: "2px",
              },
              mask: {
                color: "#ffffff",
              },
            },
          },
        },
        styleDefinition: {
          "txt-red": {
            fill: "red",
          },
          "caption-size": {
            "font-size": 20,
          },
          "background-color": {
            opacity: 0,
          },
          "x-axis-label": {
            "font-size": 15,
            fill: "#333333",
          },
          "x-axis-title": {
            "font-size": 16,
            "font-weight": "bold",
            fill: "#333333",
          },
          "y-axis-label": {
            "font-size": 14,
            fill: "#333333",
          },
          "y-axis-title": {
            "font-size": 12,
            fill: "#333333",
          },
        },
        caption: {
          text: this.title,
          style: {
            text: "caption-size",
          },
        },
        subcaption: {
          text: this.subTitle,
        },
        data: [],
        chart: {
          showlegend: 1,
          exportEnabled: true,
          paletteColors: ["#009900", "#ff0000", "#efcc11", "#bf3c25", "#132277", "#57a49f", "#bf3c25"],
          // paletteColors: ["#009900", "#ff0000", "#ccaa00", "#132277"],
          // paletteColors: ["#337700", "#ff0000", "#ccaa00", "#132277"],
        },
        tooltip: {
          enabled: true,
          // toolText: "<b>$bin</b><br>$series.plotIdentifier <h3>$series.name: $series.dataValue mAh<h3><br>",
          outputTimeFormat: {
            day: "%d/%m/%Y (%a)",
            hour: "%H:%M (%a)",
            minute: "%H:%M (%a)",
          },
          style: {
            container: {
              "border-color": "#aaaaaa",
              "background-color": "#eeeeee",
              padding: "1px",
            },
            text: {
              // color: "#FFFFFF",
            },
            header: {
              // color: "#ff0000",
              "font-size": "13px",
              "font-weight": "bold",
            },
            body: {
              // color: "#ff00ff",
              "font-size": "12px",
              "font-weight": "bold",
            },
          },
        },
        xAxis: {
          title: "Hours",
          binning: {
            outputTimeFormat: {
              day: "%Y/%m/%d (%a)",
              hour: "%Y/%m/%d (%a) %H:%M",
              // minute: "%Y/%m/%d (%a) %H:%M",
            },
            year: [],
            month: [],
            day: [],
            hour: [1],
            minute: [],
            millisecond: [],
          },
          style: {
            label: "x-axis-label",
            title: "x-axis-title",
          },
        },
        yaxis: [
          {
            title: "Current in mAh",
            plot: [
              {
                value: "Gain",
                connectnulldata: false,
                type: "column",
                aggregation: "Avg",
              },
              {
                value: "Drain",
                connectnulldata: false,
                type: "column",
                aggregation: "Avg",
              },
            ],
            style: {
              label: "y-axis-label",
              title: "y-axis-title",
            },
          },
          {
            title: "Lux in lux",
            plot: [
              {
                value: "Lux",
                connectnulldata: false,
                type: "column",
              },
            ],
            style: {
              label: "y-axis-label",
              title: "y-axis-title",
            },
          },
          {
            title: "Voltage in V",
            plot: [
              {
                value: "Voltage",
                connectnulldata: false,
                type: "column",
              },
            ],
            style: {
              label: "y-axis-label",
              title: "y-axis-title",
            },
          },
          {
            title: "Temperature in ℃",
            plot: [
              {
                value: "Temperature",
                connectnulldata: false,
                type: "column",
              },
            ],
            style: {
              label: "y-axis-label",
              title: "y-axis-title",
            },
          },
          {
            title: "Humidity %",
            plot: [
              {
                value: "Humidity",
                connectnulldata: false,
                type: "column",
              },
            ],
            style: {
              label: "y-axis-label",
              title: "y-axis-title",
            },
          },
          {
            title: "Logger Voltage in V",
            plot: [
              {
                value: "LoggerVoltage",
                connectnulldata: false,
                type: "column",
              },
            ],
            style: {
              label: "y-axis-label",
              title: "y-axis-title",
            },
          },
        ],
      },
      dayDataSource: {
        navigator: {
          enabled: 0,
          // outputTimeFormat: "%B %Y %d - %H:%M",
          window: {
            style: {
              handle: {
                padding: "1px",
              },
              "handle-grip": {
                padding: "2px",
              },
              mask: {
                color: "#ffffff",
              },
            },
          },
        },
        styleDefinition: {
          "txt-red": {
            fill: "red",
          },
          "caption-size": {
            "font-size": 20,
          },
          "background-color": {
            // fill: "#f7f7f7",
            opacity: 0,
          },
          "x-axis-label": {
            "font-size": 15,
            fill: "#333333",
          },
          "x-axis-title": {
            "font-size": 16,
            "font-weight": "bold",
            fill: "#333333",
          },
          "y-axis-label": {
            "font-size": 13,
            fill: "#333333",
          },
          "y-axis-title": {
            "font-size": 12,
            fill: "#333333",
          },
        },
        caption: {
          text: "Gain-Drain Data",
          style: {
            text: "caption-size",
          },
        },
        data: [],
        subcaption: {
          text: "",
        },
        chart: {
          style: {
            // background : "background-color",
            // canvas: "background-color",
          },
          showlegend: 1,
          // dataLoadStartMessage: "https://i.gifer.com/9viU.gif",
          exportEnabled: false,
          paletteColors: ["#009900", "#ff0000", "#efcc11", "#bf3c25", "#132277", "#57a49f", "#bf3c25"],
          // paletteColors: ["#009900", "#ff0000", "#ccaa00", "#132277"],"#ff9911"
        },
        tooltip: {
          enabled: true,
          // toolText: "<h3>$bin</br>$series.plotIdentifier$series.name: <b>$series.dataValue mAh</b></br>$series.plotIdentifier$series.name: <b>$series.dataValue mAh</b><h3><br>",
          outputTimeFormat: {
            day: "%d/%m/%Y (%a)",
            hour: "%H:%M (%a)",
            minute: "%H:%M (%a)",
          },
          style: {
            container: {
              "border-color": "#aaaaaa",
              "background-color": "#eeeeee",
              padding: "1px",
            },
            text: {
              // color: "#FFFFFF",
            },
            header: {
              // color: "#ff0000",
              "font-size": "13px",
              "font-weight": "bold",
            },
            body: {
              // color: "#ff00ff",
              "font-size": "12px",
              "font-weight": "bold",
            },
          },
        },
        xAxis: {
          title: "Days",
          binning: {
            outputTimeFormat: {
              day: "%Y/%m/%d (%a)",
              hour: "%Y/%m/%d (%a) %H:%M",
              minute: "%Y/%m/%d (%a) %H:%M",
            },
            year: [],
            month: [],
            day: [1],
            hour: [],
            minute: [],
            millisecond: [],
          },
          style: {
            label: "x-axis-label",
            title: "x-axis-title",
          },
        },
        yaxis: [
          {
            title: "Current in mAh",
            plot: [
              {
                value: "Gain",
                connectnulldata: false,
                type: "column",
                aggregation: "Sum",
              },
              {
                value: "Drain",
                connectnulldata: false,
                type: "column",
                aggregation: "Sum",
              },
            ],
            style: {
              label: "y-axis-label",
              title: "y-axis-title",
            },
          },
          {
            title: "Lux in lux",
            plot: [
              {
                value: "Lux",
                connectnulldata: false,
                type: "column",
              },
            ],
            style: {
              label: "y-axis-label",
              title: "y-axis-title",
            },
          },
          {
            title: "Voltage in V",
            plot: [
              {
                value: "Voltage",
                connectnulldata: false,
                type: "column",
              },
            ],
            style: {
              label: "y-axis-label",
              title: "y-axis-title",
            },
          },
          {
            title: "Temperature in ℃",
            plot: [
              {
                value: "Temperature",
                connectnulldata: false,
                type: "column",
              },
            ],
            style: {
              label: "y-axis-label",
              title: "y-axis-title",
            },
          },
          {
            title: "Humidity %",
            plot: [
              {
                value: "Humidity",
                connectnulldata: false,
                type: "column",
              },
            ],
            style: {
              label: "y-axis-label",
              title: "y-axis-title",
            },
          },
          {
            title: "Logger Voltage in V",
            plot: [
              {
                value: "LoggerVoltage",
                connectnulldata: false,
                type: "column",
              },
            ],
            style: {
              label: "y-axis-label",
              title: "y-axis-title",
            },
          },
        ],
      },
      schema: [
        {
          name: "Time",
          type: "date",
          format: "%Y-%m-%d %H:%M",
        },
        {
          name: "Gain",
          type: "number",
        },
        {
          name: "Drain",
          type: "number",
        },
        {
          name: "Voltage",
          type: "number",
        },
        {
          name: "Temperature",
          type: "number",
        },
        {
          name: "Humidity",
          type: "number",
        },
        {
          name: "Lux",
          type: "number",
        },
        {
          name: "LoggerVoltage",
          type: "number",
        },
      ],
      minuteSchema: [
        {
          name: "Time",
          type: "date",
          format: "%Y-%m-%d %H:%M:%S",
        },
        {
          name: "Gain",
          type: "number",
        },
        {
          name: "Drain",
          type: "number",
        },
        {
          name: "Voltage",
          type: "number",
        },
        {
          name: "Temperature",
          type: "number",
        },
        {
          name: "Humidity",
          type: "number",
        },
        {
          name: "Lux",
          type: "number",
        },
        {
          name: "LoggerVoltage",
          type: "number",
        },
      ],
    };
  },

  watch: {
    dayGraph(newValue) {
      if (newValue) {
        this.yearGraph = false;
        this.monthGraph = false;
        this.minuteGraph = false;
        this.hourGraph = false;

        this.btnYear = this.showBtnYear;
        this.btnMonth = this.showBtnMonth;
        this.btnDay = false;
        this.btnHour = this.showBtnHour;
        this.btnMinute = this.showBtnMinute;
      }
    },
    hourGraph(newValue) {
      if (newValue) {
        this.yearGraph = false;
        this.monthGraph = false;
        this.dayGraph = false;
        this.minuteGraph = false;

        this.btnYear = this.showBtnYear;
        this.btnMonth = this.showBtnMonth;
        this.btnDay = this.showBtnDay;
        this.btnHour = false;
        this.btnMinute = this.showBtnMinute;
      }
    },
    minuteGraph(newValue) {
      if (newValue) {
        this.yearGraph = false;
        this.monthGraph = false;
        this.dayGraph = false;
        this.hourGraph = false;

        this.btnYear = this.showBtnYear;
        this.btnMonth = this.showBtnMonth;
        this.btnDay = this.showBtnDay;
        this.btnHour = this.showBtnHour;
        this.btnMinute = false;

        if (this.minutesSelected > 30) {
          let newDs = this.minuteDataSource;
          newDs.xAxis.initialInterval = {
            from: "2022-07-15 11:49:42",
            to: "2022-07-27 17:45:54",
          };
          if (this.$refs && this.$refs.minuteChartRef && this.$refs.minuteChartRef.chartObj) {
            const chartObj = this.$refs.minuteChartRef.chartObj;
            chartObj.setChartData(newDs);
          }
        }
      }
    },
    minuteData() {
      if (this.minuteData.length == 0) {
        this.showBtnMinute = false;
      }
      //   this.loadingData = true;
      console.log("watch minute schema: ", this.minuteSchema);
      console.log("watch minute data: ", this.minuteData);
      // console.log("title: ", this.title);
      // console.log("subTitle: ", this.subTitle);
      const chartTable = new FusionCharts.DataStore().createDataTable(this.minuteData, this.minuteSchema);
      this.minuteDataSource.data = chartTable;
      let start = this.minuteData[0][0];
      start = start.slice(0, start.length - 3);
      let end = this.minuteData[this.minuteData.length - 1][0];
      end = end.slice(0, end.length - 3);
      // console.log("minute start: ", start);
      // console.log("minute end: ", end);
      this.minutesSelected = Math.floor(new Date(end) - new Date(start)) / (1000 * 60);
      console.log("minuteSelected: ", this.minutesSelected);
      if (this.minutesSelected > 2) {
        // this.showBtnDay = true;
        // this.showBtnHour = true;
        this.showBtnMinute = true;
        // this.btnDay = true;
        // this.btnHour = true;
        // this.btnMinute = true;
      }
      if (this.dayDataSource.data != null && this.hourDataSource.data != null) {
        this.decideGraph();
      }

      if (this.$refs && this.$refs.minuteChartRef && this.$refs.minuteChartRef.chartObj) {
        // console.log("set bin");
        const chartObj = this.$refs.minuteChartRef.chartObj;
        // console.log("chartObj: ", chartObj);
        // console.log("Before set, chartObj: ", chartObj.getCurrentBin());
        try {
          // chartObj.setBinning({
          //   year: [1],
          //   month: [1],
          //   day: [1],
          //   hour: [],
          //   minute: [],
          //   millisecond: [],
          // });
          chartObj.setCurrentBin({
            unit: "minute",
            multiplier: 1,
          });
        } catch (e) {
          console.log("Exception: ", e);
        }
        // console.log("After set, chartObj: ", chartObj.getCurrentBin());
        // console.log("chartObj id: ", chartObj.id);

        // FusionCharts.items[chartObj.id].setChartAttribute("exportEnabled", "false");
        // FusionCharts.items[chartObj.id].setJSONData(this.dayDataSource);
        // FusionCharts.items[chartObj.id].setChartData(this.dayData, "json");
        // const data = FusionCharts.items[chartObj.id].getChartData();
        // console.log("data: ", data);
      } //   this.loadingData = false;
    },
    hourData() {
      if (this.hourData.length == 0) {
        this.showBtnHour = false;
      }
      //   this.loadingData = true;

      // console.log("watch schema: ", this.schema);
      // console.log("watch hour data: ", this.hourData);
      // console.log("title: ", this.title);
      // console.log("subTitle: ", this.subTitle);
      // console.log(0);
      const chartTable = new FusionCharts.DataStore().createDataTable(this.hourData, this.schema);
      // console.log(1);
      this.hourDataSource.data = chartTable;
      // console.log(1);
      let start = this.hourData[0][0];
      start = start.slice(0, start.length - 6);
      let end = this.hourData[this.hourData.length - 1][0];
      end = end.slice(0, end.length - 6);
      this.hoursSelected = Math.floor(new Date(end) - new Date(start)) / (1000 * 60 * 60);
      if (this.hoursSelected > 2) {
        //this.showBtnMinute = false;
        // this.showBtnDay = true;
        this.showBtnHour = true;
        // this.btnDay = true;
        // this.btnHour = true;
        // this.btnMinute = false;
      }
      if (this.dayDataSource.data != null && this.minuteDataSource.data != null) {
        this.decideGraph();
      }

      if (this.$refs && this.$refs.hourChartRef && this.$refs.hourChartRef.chartObj) {
        // console.log("set bin");
        const chartObj = this.$refs.hourChartRef.chartObj;
        // console.log("chartObj: ", chartObj);
        // console.log("Before set, chartObj: ", chartObj.getCurrentBin());
        try {
          // chartObj.setBinning({
          //   year: [1],
          //   month: [1],
          //   day: [1],
          //   hour: [],
          //   minute: [],
          //   millisecond: [],
          // });
          chartObj.setCurrentBin({
            unit: "day",
            multiplier: 1,
          });
        } catch (e) {
          console.log("Exception: ", e);
        }
        // console.log("After set, chartObj: ", chartObj.getCurrentBin());
        // console.log("chartObj id: ", chartObj.id);

        // FusionCharts.items[chartObj.id].setChartAttribute("exportEnabled", "false");
        // FusionCharts.items[chartObj.id].setJSONData(this.dayDataSource);
        // FusionCharts.items[chartObj.id].setChartData(this.dayData, "json");
        // const data = FusionCharts.items[chartObj.id].getChartData();
        // console.log("data: ", data);
      } //   this.loadingData = false;
    },
    dayData() {
      if (this.dayData.length == 0) {
        this.showBtnYear = false;
        this.showBtnMonth = false;
        this.showBtnDay = false;
      }
      //   this.loadingData = true;
      // console.log("watch schema: ", this.schema);
      // console.log("watch day data: ", this.data);
      const chartTable = new FusionCharts.DataStore().createDataTable(this.dayData, this.schema);
      this.dayDataSource.data = chartTable;
      let start = this.dayData[0][0];
      start = start.slice(0, start.length - 6);
      let end = this.dayData[this.dayData.length - 1][0];
      end = end.slice(0, end.length - 6);
      this.daysSelected = Math.floor(new Date(end) - new Date(start)) / (1000 * 60 * 60 * 24);
      if (this.daysSelected > 2) {
        //this.showBtnMinute = false;
        this.showBtnDay = true;
        // this.showBtnHour = true;
        // this.btnDay = true;
        // this.btnHour = true;
        // this.btnMinute = false;
      }
      if (this.daysSelected > 30 * 2) {
        this.showBtnMonth = true;
      }
      if (this.daysSelected > 365 * 2) {
        this.showBtnYear = true;
      }
      if (this.hourDataSource.data != null && this.minuteDataSource.data != null) {
        this.decideGraph();
      }

      if (this.$refs && this.$refs.dayChartRef && this.$refs.dayChartRef.chartObj) {
        // console.log("set bin");
        const chartObj = this.$refs.dayChartRef.chartObj;
        // console.log("chartObj: ", chartObj);
        // console.log("Before set, chartObj: ", chartObj.getCurrentBin());
        try {
          chartObj.setCurrentBin({
            unit: "day",
            multiplier: 1,
          });
          // this.btnDay = false;
        } catch (e) {
          console.log("Exception: ", e);
        }
        // console.log("After set, chartObj: ", chartObj.getCurrentBin());
        // FusionCharts.items[chartObj.id].setChartAttribute("exportEnabled", "false");
        // // FusionCharts.items[chartObj.id].setJSONData(this.dayDataSource);
        // FusionCharts.items[chartObj.id].setChartData(this.dayData, "json");
        // const data = FusionCharts.items[chartObj.id].getChartData();
        // console.log("data: ", data);
      }
      //   this.loadingData = false;
    },
  },

  mounted: function() {
    // this.dayGraph = true;
    // console.log("mounted schema: ", this.schema);
    // console.log("mounted data: ", this.dayData);
    let chartTable = new FusionCharts.DataStore().createDataTable(this.hourData, this.schema);
    this.hourDataSource.data = chartTable;

    chartTable = new FusionCharts.DataStore().createDataTable(this.dayData, this.schema);
    this.dayDataSource.data = chartTable;

    chartTable = new FusionCharts.DataStore().createDataTable(this.minuteData, this.schema);
    this.minuteDataSource.data = chartTable;

    // let dayChartTable = new FusionCharts.DataStore().createDataTable(this.dayData, this.schema);
    // this.dayDataSource.data = dayChartTable;
    // if (this.$refs && this.$refs.hourChartRef && this.$refs.hourChartRef.chartObj) {
    //   console.log("set bin");
    //   const chartObj = this.$refs.hourChartRef.chartObj;
    //   console.log("chartObj: ", chartObj);
    //   console.log("Before set, chartObj: ", chartObj.getCurrentBin());
    //   try {
    //     chartObj.setCurrentBin({
    //       unit: "day",
    //       multiplier: 1,
    //     });
    //   } catch (e) {
    //     console.log("Exception: ", e);
    //   }
    //   console.log("After set, chartObj: ", chartObj.getCurrentBin());
    //   FusionCharts.items[chartObj.id].setChartAttribute("exportEnabled", "false");
    //   FusionCharts.items[chartObj.id].setChartData(this.dayData, "json");
    //   const data = FusionCharts.items[chartObj.id].getChartData();
    //   console.log("data: ", data);
    //   // FusionCharts.items[chartObj.id].setJSONData(this.dayDataSource);
    //   // FusionCharts.items[chartObj.id].render();
    // }
    // if (this.$refs && this.$refs.dayChartRef && this.$refs.dayChartRef.chartObj) {
    //   console.log("set bin");
    //   const chartObj = this.$refs.dayChartRef.chartObj;
    //   console.log("chartObj: ", chartObj.getCurrentBin());
    //   chartObj.setBinning({
    //     unit: "minute",
    //     multiplier: "2",
    //   });
    // }
    // setInterval(() => {
    //   if (this.$refs && this.$refs.chartRef && this.$refs.chartRef.chartObj) {
    //     const chartObj = this.$refs.chartRef.chartObj;
    //     chartObj.feedData([
    //       [
    //         (feedDataTime += 1000),
    //         getRandomInt(1, 20),
    //         getRandomInt(70, 100),
    //         getRandomInt(30, 50)
    //       ]
    //     ]);
    //   }
    // }, 3000);
  },

  methods: {
    onRenderComplete(e) {
      console.log("on render complete e: ", e);
      if (this.$refs && this.$refs.hourChartRef && this.$refs.hourChartRef.chartObj) {
        console.log("onRenderComplete: hour chart", e);
        console.log("set bin");
        // let chartTable = new FusionCharts.DataStore().createDataTable(this.data, this.schema);
        // this.hourDataSource.data = chartTable;
        const chartObj = this.$refs.hourChartRef.chartObj;
        console.log("chartObj: ", chartObj);
        console.log("Before set, chartObj: ", chartObj.getCurrentBin());
        try {
          // chartObj.setCurrentBin({
          //   unit: "day",
          //   multiplier: 1,
          // });
          // FusionCharts.items[chartObj.id].setChartAttribute("exportEnabled", "false");
        } catch (e) {
          console.log("Exception: ", e);
        }
        console.log("After set, chartObj: ", chartObj.getCurrentBin());
      }
    },

    onSelectionChange(e) {
      // var s = new Date(e.data.start);
      // var d = new Date(e.data.end);
      console.log("binUnit: ", e.data.binUnit);
      if (e.data.binUnit === "day" && this.hourGraph) {
        // this.hourGraph = false;
        // let chartTable = new FusionCharts.DataStore().createDataTable(this.dayData, this.schema);
        // this.hourDataSource.data = chartTable;
      }
      // console.log("e: ", e);
      // this.binUnit = e.data.binUnit;
      // if ((e.data.binUnit === "minute" || e.data.binUnit === "millisecond") && this.hourGraph) {
      //   // this.dataSource = this.dayDataSource;
      //   console.log("setting data = data");
      //   // this.dataSource.data = this.data;
      //   this.hourGraph = false;
      // } else if (!this.hourGraph && e.data.binUnit != "minute" && e.data.binUnit != "millisecond") {
      //   // this.dataSource = this.hourDataSource;
      //   console.log("setting data = dayData");
      //   // this.dataSource.data = this.dayData;
      //   this.hourGraph = true;
      // }
      // if(e.data.binUnit === 'minute' || e.data.binUnit === 'millisecond') {
      // this.dataSource = this.dayDataSource;
      // } else {
      // this.dataSource = this.hourDataSource;
      // }

      // console.log(
      //   "Current selection range : " +
      //     s.getDate() +
      //     "/" +
      //     (s.getMonth() + 1) +
      //     "/" +
      //     s.getFullYear() +
      //     " to " +
      //     d.getDate() +
      //     "/" +
      //     (d.getMonth() + 1) +
      //     "/" +
      //     d.getFullYear()
      // );
    },

    decideGraph() {
      if (this.daysSelected >= 365 * 2) {
        this.showBtnYear = true;
        this.showBtnMonth = true;
        this.showBtnDay = true;
        if (this.minutesSelected > 2) {
          this.showBtnMinute = true;
        }

        this.yearGraph = true;
        this.monthGraph = false;
        this.dayGraph = false;
        this.minuteGraph = false;
      } else if (this.daysSelected > 30 * 2) {
        this.showBtnMonth = true;
        this.showBtnDay = true;
        if (this.minutesSelected > 2) {
          this.showBtnMinute = true;
        }

        this.yearGraph = false;
        this.monthGraph = true;
        this.dayGraph = false;
        this.minuteGraph = false;
      } else if (this.daysSelected > 1 * 2) {
        this.showBtnDay = true;
        if (this.minutesSelected > 2) {
          this.showBtnMinute = true;
        }

        this.yearGraph = false;
        this.monthGraph = false;
        this.dayGraph = true;
        this.minuteGraph = false;
      } else {
        if (this.minutesSelected > 2) {
          this.showBtnMinute = true;
        }

        this.yearGraph = false;
        this.monthGraph = false;
        this.dayGraph = false;
        this.minuteGraph = true;
      }
    },

    onYear() {
      console.log("onYear");
      this.yearGraph = true;
      if (this.$refs && this.$refs.dayChartRef && this.$refs.dayChartRef.chartObj) {
        // let chartTable = new FusionCharts.DataStore().createDataTable(this.data, this.schema);
        // this.hourDataSource.data = chartTable;
        const chartObj = this.$refs.dayChartRef.chartObj;
        console.log("chartObj: ", chartObj);
        console.log("Before set, chartObj: ", chartObj.getCurrentBin());
        try {
          FusionCharts.items[chartObj.id].setBinning = {
            outputTimeFormat: {
              day: "%Y/%m/%d (%a)",
              hour: "%Y/%m/%d (%a) %H:%M",
              minute: "%Y/%m/%d (%a) %H:%M",
            },
            year: [1],
            month: [],
            day: [],
            hour: [],
            minute: [],
            millisecond: [],
          };
          // FusionCharts.items[chartObj.id].setCurrentBin({
          //   unit: "day",
          //   multiplier: "1",
          // });
          // FusionCharts.items[chartObj.id].setChartAttribute("exportEnabled", "false");
        } catch (e) {
          console.log("Exception: ", e);
        }
        console.log("After set, chartObj: ", chartObj.getCurrentBin());
      }
    },

    onMonth() {
      console.log("onMonth");
      this.monthGraph = true;
      if (this.$refs && this.$refs.dayChartRef && this.$refs.dayChartRef.chartObj) {
        // let chartTable = new FusionCharts.DataStore().createDataTable(this.data, this.schema);
        // this.hourDataSource.data = chartTable;
        const chartObj = this.$refs.dayChartRef.chartObj;
        console.log("chartObj: ", chartObj);
        console.log("Before set, chartObj: ", chartObj.getCurrentBin());
        try {
          FusionCharts.items[chartObj.id].setBinning = {
            outputTimeFormat: {
              day: "%Y/%m/%d (%a)",
              hour: "%Y/%m/%d (%a) %H:%M",
              minute: "%Y/%m/%d (%a) %H:%M",
            },
            year: [],
            month: [1],
            day: [],
            hour: [],
            minute: [],
            millisecond: [],
          };
          // FusionCharts.items[chartObj.id].setCurrentBin({
          //   unit: "day",
          //   multiplier: "1",
          // });
          // FusionCharts.items[chartObj.id].setChartAttribute("exportEnabled", "false");
        } catch (e) {
          console.log("Exception: ", e);
        }
        console.log("After set, chartObj: ", chartObj.getCurrentBin());
      }
    },

    onDay() {
      console.log("onDay");
      // this.hourGraph = false;
      // this.minuteGraph = false;
      this.dayGraph = true;
      // this.btnDay = false;
      // if (this.showBtnHour) {
      //   this.btnHour = true;
      // }
      // if (this.showBtnMonth) {
      //   this.btnMonth = true;
      // }
      // if (this.showBtnYear) {
      //   this.btnYear = true;
      // }
      // if (this.showBtnMinute) {
      //   this.btnMinute = true;
      // }
      if (this.$refs && this.$refs.dayChartRef && this.$refs.dayChartRef.chartObj) {
        // let chartTable = new FusionCharts.DataStore().createDataTable(this.dayData, this.schema);
        // this.dayDataSource.data = chartTable;
        const chartObj = this.$refs.dayChartRef.chartObj;
        // this.btnDay = false;
        console.log("chartObj: ", chartObj);
        console.log("Before set, chartObj: ", chartObj.getCurrentBin());
        try {
          FusionCharts.items[chartObj.id].setCurrentBin({
            unit: "day",
            multiplier: "1",
          });
          // FusionCharts.items[chartObj.id].setChartAttribute("exportEnabled", "false");
        } catch (e) {
          console.log("Exception: ", e);
        }
        console.log("After set, chartObj: ", chartObj.getCurrentBin());
      }
    },

    onHour() {
      console.log("onHour");
      this.hourGraph = true;
      // this.minuteGraph = false;
      // this.dayGraph = false;
      // this.btnHour = false;
      // if (this.showBtnDay) {
      //   this.btnDay = true;
      // }
      // if (this.showBtnMonth) {
      //   this.btnMonth = true;
      // }
      // if (this.showBtnYear) {
      //   this.btnYear = true;
      // }
      // if (this.showBtnMinute) {
      //   this.btnMinute = true;
      // }
      if (this.$refs && this.$refs.hourChartRef && this.$refs.hourChartRef.chartObj) {
        // let chartTable = new FusionCharts.DataStore().createDataTable(this.hourData, this.schema);
        // this.hourDataSource.data = chartTable;
        // const chartObj = this.$refs.hourChartRef.chartObj;
        // console.log("chartObj: ", chartObj);
        // console.log("Before set, chartObj: ", chartObj.getCurrentBin());
        try {
          // FusionCharts.items[chartObj.id].setCurrentBin({
          //   unit: "hour",
          //   multiplier: 1,
          // });
          // FusionCharts.items[chartObj.id].setChartAttribute("exportEnabled", "false");
        } catch (e) {
          console.log("Exception: ", e);
        }
        // console.log("After set, chartObj: ", chartObj.getCurrentBin());
      }
    },

    onMinute() {
      console.log("onMinute");
      this.minuteGraph = true;
      console.log("minute data: ", this.minuteDataSource.data[0]);
      // if (this.minuteDataSource.data.length > 30) {
      //   this.minuteDataSource = {
      //     ...{
      //       initialInterval: {
      //         from: "2016-01-01 12:00:00",
      //         to: "2016-01-31 12:00:00",
      //       },
      //     },
      //   };
      // }
      // this.hourGraph = false;
      // this.btnMinute = false;
      // if (this.showBtnHour) {
      //   this.btnHour = true;
      // }
      // if (this.showBtnDay) {
      //   this.btnDay = true;
      // }
      // if (this.showBtnMonth) {
      //   this.btnMonth = true;
      // }
      // if (this.showBtnYear) {
      //   this.btnYear = true;
      // }
      if (this.$refs && this.$refs.minuteChartRef && this.$refs.minuteChartRef.chartObj) {
        // let chartTable = new FusionCharts.DataStore().createDataTable(this.minuteData, this.minuteSchema);
        // this.minuteDataSource.data = chartTable;
        // const chartObj = this.$refs.minuteChartRef.chartObj;
        // console.log("chartObj: ", chartObj);
        // console.log("Before set, chartObj: ", chartObj.getCurrentBin());
        try {
          // FusionCharts.items[chartObj.id].setCurrentBin({
          //   unit: "hour",
          //   multiplier: 1,
          // });
          // FusionCharts.items[chartObj.id].setChartAttribute("exportEnabled", "false");
        } catch (e) {
          console.log("Exception: ", e);
        }
        // console.log("After set, chartObj: ", chartObj.getCurrentBin());
      }
    },
  },
};
</script>

<style scoped>
.row {
  margin-top: 5px;
}
.timeseries-container {
  margin-top: -15px;
  margin-left: -12px;
  margin-right: -120px;
}
.bin-buttons {
  text-align: center;
  margin: -20px;
  margin-top: -30px;
  position: absolute;
}
.buttons-col {
  text-align: center;
  margin: -20px;
  margin-top: -60px;
  position: absolute;
}
.bin-btn {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 0px;
  margin-bottom: -7px;
}
.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
  background-color: #ddd !important;
  color: darkcyan !important;
}
.class-absolute {
  margin: 10px;
  height: 350px;
  width: 100%;
  position: absolute;
}
</style>