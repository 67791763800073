<template>
  <div class="main-container">
    <v-row class="alert-container">
      <v-alert v-if="showMessage" class="alert" :type="messageType" dark>
        {{ message }}
      </v-alert>
    </v-row>
    <div class="box-container">
      <h2 class="heading">{{ environment == "development" ? "Development Mode" : "" }}</h2>
      <h2 class="heading">Change Password</h2>
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="handleUpdatePassword">
          <div class="form-fields">
            <ValidationProvider name="Current Password" vid="currentPassword" rules="required" v-slot="{ errors }">
              <v-text-field
                autofocus
                v-model="currentPassword"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                placeholder="Current Password"
                required
                solo
                dense
                :error-messages="errors[0]"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </ValidationProvider>
          </div>
          <div class="form-fields">
            <ValidationProvider
              name="New Password"
              vid="newPassword"
              rules="required|min:8|newPassword:@currentPassword"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="newPassword"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                placeholder="New Password"
                required
                solo
                dense
                hint="At least 8 characters"
                :error-messages="errors[0]"
                @click:append="show1 = !show1"
              ></v-text-field>
            </ValidationProvider>
          </div>
          <div class="form-fields">
            <ValidationProvider
              name="Confirm New Password"
              rules="required|confirmNewPassword:@newPassword"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="confirmNewPassword"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                placeholder="Retype New Password"
                required
                solo
                dense
                :error-messages="errors[0]"
                @click:append="show2 = !show2"
              ></v-text-field>
            </ValidationProvider>
          </div>
          <div class="form-fields">
            <v-btn class="button" type="submit" :disabled="invalid" color="primary" @click="handleUpdatePassword">
              Update Password
            </v-btn>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { extend } from "vee-validate";

extend("newPassword", {
  params: ["currentPassword"],
  validate: (value, { currentPassword }) => {
    return value !== currentPassword;
  },
  message: "The new password must be different from the current",
});

extend("confirmNewPassword", {
  params: ["newPassword"],
  validate(value, { newPassword }) {
    return value === newPassword;
  },
  message: "Password confirmation does not match",
});

export default {
  name: "ChangePassword",

  data() {
    return {
      message: null,
      messageType: null,
      showMessage: false,
      loading: false,
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      show1: false,
      show2: false,
      validEnv: {
        prototype: {
          valid: false,
          user: null,
        },
        product: {
          valid: false,
          user: null,
        },
      },
      showPassword: false,
      newPasswordError: null,
      confirmNewPasswordError: null,
    };
  },
  computed: {
    environments() {
      return this.$store.getters.getUserEnvironments;
    },
    environment() {
      return this.$globals.ENV;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    accessToken() {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {

    async updatePassword(apiUrl) {
      try {
        const h = new Headers();
        h.append("Authorization", "Bearer " + this.accessToken);
        h.append("Content-Type", "application/json");
        h.append("Accept", "application/json");

        var payload = JSON.stringify({
          old_password: this.currentPassword,
            new_password: this.newPassword,
        });

        var requestOptions = {
          method: "PUT",
          headers: h,
          body: payload,
        };

        var reqEndpoint = "";
        reqEndpoint = apiUrl + this.$globals.RES_USER_UPDATE_PASSWORD;
        const response = await fetch(reqEndpoint, requestOptions);
        return response;
      } catch (e) {
        console.error("error: ", e);
        return e;
      }
    },

    async handleUpdatePassword() {
      this.environments.forEach(async environment => {
        let apiUrl = null;
        switch (environment) {
          case this.$globals.PROD_ENV: {
            apiUrl = this.$globals.PROD_API_URL;
            break;
          }
          case this.$globals.PROTO_ENV: {
            apiUrl = this.$globals.PROTO_API_URL;
            break;
          }
          default:
            break;
        }
        const res = await this.updatePassword(apiUrl);
        const data = await res.json();
        console.log(data);
        this.message = data.message;
        switch (res.status) {
          case 200:
            this.messageType = "success";
            break;
          case 417:
            this.messageType = "error";
            break;
          default:
            break;
        }
        this.showMessage = true;
        window.setTimeout(() => {
          this.showMessage = false;
        }, 3000);
      });
    },
  },
};
</script>

<style scoped>
.main-container {
  margin-top: 3%;
  width: 100%;
}
.alert-container {
  width: 100%;
  position: relative;
}
.alert {
  width: 100%;
}
.box-container {
  padding: 20px;
  margin: 0 auto;
  width: 25rem;
  box-shadow: beige;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
}
.heading {
  text-align: center;
  font-weight: 300;
  color: #444;
  margin: 0 auto 45px;
  font-size: 35px;
  line-height: 38px;
  text-transform: none;
  letter-spacing: 0;
}
.form-fields,
.form-fields button {
  width: 100%;
  margin: 5px 0;
  line-height: 28px;
  border-radius: 5px;
}
</style>
