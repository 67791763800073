<template>
  <fusioncharts
    :type="type"
    :width="width"
    :height="height"
    :dataFormat="dataFormat"
    data-empty-message="Data is loading"
    :dataSource="dataSource"
    ref="chartRef"
  ></fusioncharts>
</template>

<script>
export default {
  name: "StackedColumn2d",
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    caption: {
      type: String,
    },
    categories: {
      type: Array,
    },
    dataset: {
      type: Array,
    },
  },
  data: function() {
    return {
      type: "stackedcolumn2d",
      dataFormat: "json",
      dataSource: {
        chart: {
          caption: this.caption,
          subCaption: "",
          xAxisName: "",
          yAxisName: "Current in mAh",
          showValues: "0",
          theme: "fusion",
          showLegend: false,
          exportEnabled: false,
          plottooltext: "$seriesName <b>$dataValue mAh</b>",
          decimals: "1",
        },
        categories: this.categories,
        dataset: this.dataset,
      },
    };
  },
};
</script>

<style scoped></style>
