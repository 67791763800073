function shortenNumber(number, decPlaces) {

  if (number >= 1e12) {
    return (number / 1e12).toFixed(decPlaces) + "T";
  } else if (number >= 1e9) {
    return (number / 1e9).toFixed(decPlaces) + "B";
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(decPlaces) + "M";
  } else if (number >= 1e3) {
    return (number / 1e3).toFixed(decPlaces) + "K";
  }

  return number;
}

export {shortenNumber}