<template>
  <v-container>
    <v-row class="alert-container">
      <v-alert v-if="showMessage" class="alert" :type="messageType" dark>
        {{ message }}
      </v-alert>
    </v-row>
    <v-dialog v-model="dialogAddTestcase" max-width="40%">
      <v-card>
        <v-card-title class="delete-text">{{ confirmationTitle }}</v-card-title>
        <v-card-text>{{ confirmationText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeAddTestcase">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="addTestcaseConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <h1 class="title">Testcase / Device</h1>
    <v-row style="margin: 30px" justify="center">
      <v-col cols="3">
        <v-card
          :disabled="selectedTestcaseName != null && selectedTestcaseName != ''"
          v-show="!newDevice"
          class="mx-auto"
        >
          <v-card-title class="justify-center" style="fontSize: 1em">
            Search by Device Id
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              v-model="selectedDeviceId"
              :items="filteredDevicesIds"
              small-chips
              dense
              label="Device"
              @change="onChangeDevice"
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-btn elevation="7" color="primary" absolute top right fab @click="onAddDevice">
              <v-icon>{{ addDeviceIcon }}</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="9">
        <v-card :disabled="selectedDeviceId != null && selectedDeviceId != ''" class="mx-auto">
          <v-card-title class="justify-center" style="fontSize: 1em">
            Search by Test-case name
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-autocomplete
                  v-model="selectedCustomer"
                  :items="customersNames"
                  :error-messages="selectedCustomerErrors"
                  :label="customerFieldLabel"
                  hide-selected
                  dense
                  small-chips
                  single-line
                  @change="onChangeCustomer"
                >
                </v-autocomplete>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="5">
                <v-combobox
                  :filter="testcaseFound"
                  v-model="selectedTestcaseName"
                  hint="Start typing to search or add testcase"
                  :items="filteredTestcasesNames"
                  :hide-no-data="!searchTestcaseName"
                  :counter="30"
                  :maxLength="30"
                  small-chips
                  dense
                  label="Testcase Name"
                  :search-input.sync="searchTestcaseName"
                  hide-selected
                  @change="onChangeTestcase"
                  ><template v-slot:no-data>
                    <v-list-item>
                      <span
                        class="subheading"
                        v-if="selectedTestcaseNameErrors.length == 0 && selectedCustomer != null && selectedCustomer != ''"
                      >
                        Add Testcase <v-chip label small>{{ searchTestcaseName }}</v-chip>
                      </span>
                      <span
                        class="subheading"
                        style="color: red;"
                        v-else-if="selectedCustomer == null || selectedCustomer == ''"
                      >
                        Select a customer before adding a testcase
                      </span>
                    </v-list-item>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-form>
      <v-row justify="center">
        <v-col cols="6">
          <v-fab-transition>
            <v-dialog v-model="dialogAddEditDevice" max-width="50%">
              <v-card class="mx-auto">
                <v-card-title style="fontSize: 1em">
                  New Device
                </v-card-title>
                <v-card-subtitle style="fontWeight: bold"> Device Id: {{ deviceId }} </v-card-subtitle>
                <v-card-text>
                  <v-autocomplete
                    autofocus
                    v-model="testcaseName"
                    :items="testcasesNames"
                    chips
                    small-chips
                    label="Testcase Name"
                  ></v-autocomplete>
                  <v-text-field v-model="editedDevice.loggerId" label="Logger Id"></v-text-field>
                  <v-text-field v-model="editedDevice.cellId" label="Cell Id"></v-text-field>
                  <v-autocomplete
                    v-model="editedDevice.deviceType"
                    :items="deviceTypes"
                    label="Device type"
                  ></v-autocomplete>
                  <v-autocomplete
                    v-model="editedDevice.customer"
                    :items="customersNames"
                    label="Customer"
                  ></v-autocomplete>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="red" bottom right @click="closeNewDevice">
                    Cancel
                  </v-btn>
                  <v-btn color="green" absolute bottom right @click="registerDevice">
                    Register
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-fab-transition>
        </v-col>
      </v-row>
    </v-form>

    <v-row justify="center">
      <v-col cols="8">
        <!-- <v-data-table
          v-if="selectedTestcaseName != null && selectedTestcaseName != '' && !invalidSelectedTestcaseName &&
                selectedCustomer != null && selectedCustomer != '' && !invalidSelectedCustomer"
          :headers="headers"
          :items="filteredDevices"
          :search="searchDevice"
          sort-by="deviceId"
          class="elevation-4"
        > -->
        <v-data-table
          v-if="showDevicesDataTable"
          :headers="headers"
          :items="filteredDevices"
          :search="searchDevice"
          sort-by="deviceId"
          class="elevation-4"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="7">
                  <v-text-field
                    v-model="searchDevice"
                    append-icon="mdi-magnify"
                    label="Search device"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialogAddEdit" max-width="40%">
                <template v-slot:activator="{ on }">
                  <v-btn elevation="7" color="primary" dark class="mb-2" v-on="on" fab>
                    <v-icon>{{ addDeviceIcon }}</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-subtitle> Device Id: {{ deviceId }} </v-card-subtitle>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            tabindex="1"
                            v-model="editedDevice.loggerId"
                            label="Logger Id"
                            :error-messages="loggerIdErrors"
                            :counter="3"
                            @input="$v.editedDevice.loggerId.$touch()"
                            @blur="$v.editedDevice.loggerId.$touch()"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            autofocus
                            tabindex="2"
                            v-model="editedDevice.cellId"
                            label="Cell Id"
                            :error-messages="cellIdErrors"
                            :counter="7"
                            @input="$v.editedDevice.cellId.$touch()"
                            @blur="$v.editedDevice.cellId.$touch()"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-select
                            tabindex="3"
                            v-model="editedDevice.deviceType"
                            label="Device Type"
                            small-chips
                            :items="deviceTypes"
                            :error-messages="deviceTypeErrors"
                            @input="$v.editedDevice.deviceType.$touch()"
                            @blur="$v.editedDevice.deviceType.$touch()"
                          ></v-select>
                        </v-col>
                        <v-col cols="6">
                          <v-select
                            tabindex="4"
                            v-model="editedDevice.customer"
                            label="Customer"
                            small-chips
                            :value="editedDevice.customer"
                            :items="customersNames"
                            :error-messages="customerErrors"
                            @input="$v.editedDevice.customer.$touch()"
                            @blur="$v.editedDevice.customer.$touch()"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="onSaveDevice">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDeleteDevice" max-width="40%">
                <v-card>
                  <v-card-title class="delete-text">Are you sure you want to delete this device?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteDeviceConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <v-icon small class="mr-2" @click="editDeviceDialog(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteDeviceDialog(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength, numeric } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    // selectedTestcaseId: { required, numeric, minLength: minLength(7), maxLength: maxLength(7) },
    selectedCustomer: { required },
    selectedTestcaseName: { required, maxLength: maxLength(30) },
    testcaseName: { required, maxLength: maxLength(30) },
    editedDevice: {
      // testcaseId: { required, numeric, minLength: minLength(7), maxLength: maxLength(7) },
      loggerId: { required, numeric, minLength: minLength(3), maxLength: maxLength(3) },
      cellId: { required, numeric, minLength: minLength(7), maxLength: maxLength(7) },
      deviceType: { required },
      customer: { required },
    },
  },
  data() {
    return {
      selectedCustomer: null,
      invalidSelectedCustomer: false,
      newDevice: false,
      showDevicesDataTable: false,
      dialogAddEditDevice: false,
      dialogAddEdit: false,
      dialogDeleteDevice: false,
      dialogAddTestcase: false,
      confirmationTitle: "",
      confirmationText: "",

      editedIndex: -1,
      editedDevice: {
        deviceId: "",
        testcaseId: "",
        loggerId: "",
        cellId: "",
        deviceType: "",
        customer: "",
      },
      defaultDevice: {
        deviceId: "",
        testcaseId: "",
        loggerId: "",
        cellId: "",
        deviceType: "",
        customer: "",
      },
      measurement_interval_sec: "",
      // extra_functionality: true,
      // enabled: true,
      // username: "admin@exeger.com",
      // created_utc: {
      //   $date: "2021-10-01T08:57:48.426Z",
      // },

      addDeviceIcon: "mdi-plus",
      headers: [
        { text: "Device Id", value: "deviceId" },
        { text: "Logger Id", value: "loggerId" },
        { text: "Cell Id", value: "cellId" },
        { text: "Device Type", value: "deviceType" },
        { text: "Customer", value: "customer" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      devices: [],
      filteredDevices: [],
      deviceTypes: [],
      selectedDeviceId: null,
      devicesIds: [],
      filteredDevicesIds: [],
      searchTestcaseName: "",
      searchDevice: "",
      selectedTestcaseName: null,
      invalidSelectedTestcaseName: false,
      testcases: [],
      testcasesNames: [],
      filteredTestcasesNames: [],
      customers: [],
      customersNames: [],
      message: "",
      messageType: null,
      showMessage: false,
    };
  },
  computed: {
    accessToken() {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    },
    customerFieldLabel() {
      return this.$globals.CUSTOMER_FIELD_LABEL;
    },
    userEmail() {
      return JSON.parse(localStorage.getItem("user")).email;
    },
    // customerName() {
    //   return JSON.parse(localStorage.getItem("user")).customer_name;
    // },
    currentUser() {
      return this.$store.state.auth.user;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Device" : "Edit Device";
    },
    deviceId() {
      return this.editedDevice.deviceId;
    },
    testcaseName() {
      return this.selectedTestcaseName;
    },
    loggerId() {
      return this.editedDevice.loggerId;
    },
    // selectedTestcaseIdErrors() {
    //   const errors = [];
    //   if (!this.$v.selectedTestcaseId.$dirty) return errors;
    //   !this.$v.selectedTestcaseId.numeric && errors.push("Testcase Id must be numeric and 7 digits long");
    //   !this.$v.selectedTestcaseId.maxLength && errors.push("Testcase id must be up to 7 digits long");
    //   !this.$v.selectedTestcaseId.minLength && errors.push("Testcase Id must be 7 digits long");
    //   !this.$v.selectedTestcaseId.required && errors.push("Testcase Id is required");
    //   return errors;
    // },
    selectedCustomerErrors() {
      const errors = [];
      if (!this.$v.selectedCustomer.$dirty) return errors;
      !this.$v.editedDevice.customer.required && errors.push("Customer name is required");
      return errors;
    },
    selectedTestcaseNameErrors() {
      const errors = [];
      if (!this.$v.selectedTestcaseName.$dirty) return errors;
      // !this.$v.selectedTestcaseName.required && errors.push("Testcase name is required");
      return errors;
    },
    loggerIdErrors() {
      const errors = [];
      if (!this.$v.editedDevice.loggerId.$dirty) return errors;
      !this.$v.editedDevice.loggerId.numeric && errors.push("Logger Id must be numeric");
      !this.$v.editedDevice.loggerId.maxLength && errors.push("Logger Id must be 3 digits long");
      !this.$v.editedDevice.loggerId.minLength && errors.push("Logger Id must be 3 digits long");
      !this.$v.editedDevice.loggerId.required && errors.push("Logger Id is required");
      return errors;
    },
    cellIdErrors() {
      const errors = [];
      if (!this.$v.editedDevice.cellId.$dirty) return errors;
      !this.$v.editedDevice.cellId.numeric && errors.push("Cell Id must be numeric");
      !this.$v.editedDevice.cellId.maxLength && errors.push("Cell Id must be 7 digits long");
      !this.$v.editedDevice.cellId.minLength && errors.push("Cell Id must be 7 digits long");
      !this.$v.editedDevice.cellId.required && errors.push("Cell Id is required");
      return errors;
    },
    deviceTypeErrors() {
      const errors = [];
      if (!this.$v.editedDevice.deviceType.$dirty) return errors;
      !this.$v.editedDevice.deviceType.required && errors.push("Device Type is required");
      return errors;
    },
    customerErrors() {
      const errors = [];
      if (!this.$v.editedDevice.customer.$dirty) return errors;
      !this.$v.editedDevice.customer.required && errors.push("Customer name is required");
      return errors;
    },
  },

  watch: {
    dialogAddEditDevice(val) {
      val || this.closeNewDevice();
    },
    dialogDeleteDevice(val) {
      val || this.closeDelete();
    },
    selectedCustomer(val) {
      if (val != null && val != "") {
        this.$v.selectedCustomer.$reset();
        if(this.selectedTestcaseName != null && this.selectedTestcaseName != '') {
          this.showDevicesDataTable = true;
        }
      } else {
        this.showDevicesDataTable = false;
      }
    },
    selectedTestcaseName(val) {
      if(val == null || val == '') {
        this.showDevicesDataTable = false;
      }
    },
    // loggerId() {
    //   if (this.testcaseId === null || this.testcaseId === "") return;
    //   this.editedDevice.deviceId = (this.testcaseId != null ? this.testcaseId : "") + this.loggerId;
    // },
    // testcaseId(val) {
    //   if (val === null || val === "") return;
    //   this.editedDevice.deviceId = this.testcaseId + (this.loggerId != null ? this.loggerId : "");

    //   // if (this.verifyAddCustomer(val)) {
    //   this.testcasesNames.push(val);
    //   // }
    // },
  },

  created() {
    this.fetchDevices();
    this.fetchTestcases();
    this.fetchDeviceTypes();
    this.fetchCustomers();
  },

  methods: {
    async fetchDevices() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      reqEndpoint = this.$globals.API_URL + `/device`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      // console.log("Devices: ", data);
      data.forEach((device) => {
        if (device.device_id != null) {
          device.customer = device.brand;
          this.devices.push(device);
          this.devicesIds.push(device.device_id);
        }
      });
      this.filteredDevices = this.devices;
      this.filteredDevicesIds = this.devicesIds;
    },

    async fetchTestcases() {
      this.testcases = [];
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      reqEndpoint = this.$globals.API_URL + `/testcase`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      // console.log("testcases: ", data);
      data.forEach((testcase) => {
        this.testcases.push(testcase);
        if (testcase.name != null) {
          this.testcasesNames.push(testcase.name);
        }
      });
      this.filteredTestcasesNames = this.testcasesNames;
    },

    async fetchDeviceTypes() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      h.append("Content-Type", "application/json");
      h.append("Accept", "application/json");
      let reqEndpoint = "";
      reqEndpoint = this.$globals.API_URL + `/device-types`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      // console.log("types: ", data);
      data.forEach((deviceType) => {
        if (deviceType != null && deviceType != "") {
          this.deviceTypes.push(deviceType);
        }
      });
    },

    async fetchCustomers() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      reqEndpoint = this.$globals.API_URL + this.$globals.RES_CUSTOMER_BRAND;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      data.forEach((customer) => {
        if (customer.name != null && customer.name != "") {
          this.customers.push(customer);
          this.customersNames.push(customer.name);
        }
      });
    },

    onChangeDevice() {
      if (this.selectedDeviceId == null || this.selectedDeviceId == "") {
        return;
      }
      this.dialogAddEditDevice = true;
      // console.log(this.selectedDeviceId);
      for (let i = 0; i < this.devices.length; i++) {
        if (this.devices[i].device_id == this.selectedDeviceId) {
          this.editedDevice.cellId = this.devices[i].cell_id;
          this.editedDevice.customer = this.devices[i].customer;
          this.editedDevice.deviceType = this.devices[i].type;
          this.editedDevice.loggerId = this.devices[i].logger_id;
          this.editedDevice.testcaseId = this.devices[i].test_id;
          break;
        }
      }
    },

    onAddDevice() {
      this.dialogAddEditDevice = true;
      this.resetEditedDeviceValues();
    },

    closeNewDevice() {
      this.resetEditedDeviceValues();
      this.$v.editedDevice.$reset();
      this.dialogAddEditDevice = false;
    },

    onChangeCustomer() {
      this.selectedTestcaseName = '';
      if(this.selectedCustomer === null || this.selectedCustomer === '') {
        this.filteredTestcasesNames = this.testcasesNames;
        return;
      }
      this.filteredTestcasesNames = [];
      this.testcases.forEach(testcase => {
        if(testcase.customer_name === this.selectedCustomer) {
          this.filteredTestcasesNames.push(testcase.name);
        }
      });
    },

    onChangeTestcase() {
      if (this.selectedTestcaseName == null || this.selectedTestcaseName == "") {
        this.filteredDevices = this.devices;
        this.filteredDevicesIds = this.devicesIds;
        return;
      }

      if (this.$v.selectedTestcaseName.$invalid) {
        this.$v.selectedTestcaseName.$touch();
        this.invalidSelectedTestcaseName = true;
        return;
      }

      this.filteredDevices = [];
      this.filteredDevicesIds = [];
      let dev = {
        deviceId: "",
        testcaseId: "",
        loggerId: "",
        cellId: "",
        deviceType: "",
        customer: "",
      };
      this.defaultDevice = {
        deviceId: "",
        testcaseId: "",
        loggerId: "",
        cellId: "",
        deviceType: "",
        customer: "",
      };
      this.resetEditedDeviceValues();

      let maxLoggerId = "000";
      const foundTestcase = this.findTestcase(this.selectedTestcaseName);
      if (foundTestcase === null) {
        this.verifyAddTestcase(this.selectedTestcaseName);
      } else {
        this.editedDevice.testcaseId = foundTestcase.id;
        this.selectedCustomer = foundTestcase.customer_name;
        this.devices.forEach((device) => {
          if (device.testcase_id === foundTestcase.id) {
            this.filteredDevicesIds.push(device.device_id);
            dev.deviceId = device.device_id;
            dev.testcaseId = device.testcase_id;
            dev.loggerId = device.logger_id;
            dev.cellId = device.cell_id;
            dev.deviceType = device.type;
            dev.customer = device.customer_name;
            maxLoggerId = Math.max(parseInt(maxLoggerId), parseInt(device.logger_id)) + 1;
            maxLoggerId = String(maxLoggerId).padStart(3, "0");
            this.filteredDevices.push(dev);
            this.editedDevice.testcaseId = foundTestcase.id;
            this.editedDevice.loggerId = maxLoggerId;
            this.editedDevice.deviceId = this.editedDevice.testcaseId + this.editedDevice.loggerId;
            this.editedDevice.deviceType = device.type;
            this.editedDevice.customer = device.customer_name;

            this.defaultDevice.loggerId = maxLoggerId;
            this.defaultDevice.deviceType = device.type;
            this.defaultDevice.customer = device.customer_name;

            dev = {
              deviceId: "",
              testcaseId: "",
              loggerId: "",
              cellId: "",
              deviceType: "",
              customer: "",
            };
          }
        });
        this.showDevicesDataTable = true;
        this.editedDevice.customer = this.selectedCustomer;
        if (this.editedDevice.loggerId == "") {
          this.editedDevice.loggerId = "001";
          this.editedDevice.deviceId = this.editedDevice.testcaseId + this.editedDevice.loggerId;
        }
      }
    },

    onAddTestcase() {
      this.resetEditedDeviceValues();
    },

    generateTestcaseId() {
      // The testcase id will be generated by the current date + the last testcase of the day increased by 1
      let dt = new Date();
      let month = dt.getMonth() + 1;
      dt =
        dt
          .getFullYear()
          .toString()
          .slice(2) +
        month.toString().padStart(2, "0") +
        dt
          .getDate()
          .toString()
          .padStart(2, "0");
      let maxId = "0";
      this.testcases.forEach((testcase) => {
        if (testcase.id.toString().substring(0, 6) == dt) {
          maxId = Math.max(parseInt(maxId), parseInt(testcase.id.toString().substring(6, 7))) + 1;
        }
      });
      return dt + maxId;
    },

    async registerDevice() {
      const myHeaders = new Headers();
      var payload = JSON.stringify({
        device_id: parseInt(this.editedDevice.deviceId),
        cell_id: parseInt(this.editedDevice.cellId),
        type: this.editedDevice.deviceType,
        customer: this.editedDevice.customer,
        testcase_id: this.editedDevice.testcaseId,
        logger_id: this.editedDevice.loggerId,
        username: this.currentUser.username,
      });

      // console.log("register device payload: ", payload);
      myHeaders.append("Authorization", "Bearer " + this.accessToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Accept", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: payload,
      };

      let reqEndpoint = "";
      reqEndpoint = this.$globals.API_URL + `/device`;
      const response = await fetch(reqEndpoint, requestOptions);
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      await response.json();
    },

    async deleteDevice() {
      const myHeaders = new Headers();

      myHeaders.append("Authorization", "Bearer " + this.accessToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Accept", "application/json");
      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
      };

      let reqEndpoint = "";
      // console.log("id: ", this.editedUser.id);
      reqEndpoint = this.$globals.API_URL + this.$globals.RES_DEVICE + "/" + this.editedDevice.deviceId;
      const response = await fetch(reqEndpoint, requestOptions);
      const data = await response.json();
      this.messageType = "error";
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      } else if (response.status == 200) {
        this.messageType = "success";
      }
      this.showMessage = true;
      this.message = data.message;
      window.setTimeout(() => {
        this.showMessage = false;
      }, 3000);
    },

    async registerTestcase() {
      let response;
      let data;
      const id = this.generateTestcaseId();
      try {
        const myHeaders = new Headers();
        var payload = JSON.stringify({
          _id: id, //this.editedDevice.testcaseId,
          name: this.selectedTestcaseName,
          user_email: this.userEmail,
          customer_name: this.selectedCustomer, // this.customerName,
          sessions: [],
        });
        // console.log("payload: ", payload);

        myHeaders.append("Authorization", "Bearer " + this.accessToken);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: payload,
        };

        let reqEndpoint = "";
        reqEndpoint = this.$globals.API_URL + this.$globals.RES_TESTCASE;
        response = await fetch(reqEndpoint, requestOptions);
        data = await response.json();
      } catch (e) {
        console.error(e);
      }
      // console.log(JSON.stringify(data));
      this.messageType = "error";
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      } else if (response.status == 201) {
        this.messageType = "success";
        this.testcasesNames.push(this.selectedTestcaseName);
        const testcase = {
          _id: id,
          name: this.selectedTestcaseName,
          user_email: this.userEmail,
          customer_name: this.selectedCustomer,
          sessions: [],
        };
        this.testcases.push(testcase);
        this.editedDevice.loggerId = "001";
        this.editedDevice.deviceId = id + this.editedDevice.loggerId;
        this.editedDevice.customer = this.selectedCustomer;
        this.showDevicesDataTable = true;
      } else {
        this.selectedTestcaseName = "";
      }
      this.showMessage = true;
      this.message = data.message;
      window.setTimeout(() => {
        this.showMessage = false;
      }, 3000);
    },

    resetEditedDeviceValues() {
      this.editedDevice.deviceId = this.defaultDevice.deviceId;
      this.editedDevice.cellId = this.defaultDevice.cellId;
      this.editedDevice.customer = this.defaultDevice.customer;
      this.editedDevice.deviceType = this.defaultDevice.deviceType;
      this.editedDevice.loggerId = this.defaultDevice.loggerId;
      this.editedDevice.testcaseId = this.defaultDevice.testcaseId;
    },

    verifyAddTestcase(val) {
      if (this.$v.selectedCustomer.$invalid) {
        this.$v.selectedCustomer.$touch();
        this.invalidSelectedCustomer = true;
        this.selectedTestcaseName = "";
        return;
      }
      this.invalidSelectedTestcaseName = false;
      // this.$v.selectedCustomer.$reset();
      // this.invalidSelectedCustomer = false;
      if (val.length > 0) {
        this.confirmationTitle = "Confirm New Testcase";
        this.confirmationText = "Do you want to add the new testcase '" + val + "'";
        this.dialogAddTestcase = true;
      }
    },

    addTestcaseConfirm() {
      this.dialogAddTestcase = false;
      this.registerTestcase();
    },

    closeAddTestcase() {
      this.dialogAddTestcase = false;
      this.selectedTestcaseName = "";
    },

    editDeviceDialog(item) {
      this.editedIndex = this.filteredDevices.indexOf(item);
      this.editedDevice = Object.assign({}, item);
      this.dialogAddEdit = true;
    },

    deleteDeviceDialog(item) {
      this.editedIndex = this.filteredDevices.indexOf(item);
      this.editedDevice = Object.assign({}, item);
      this.dialogDeleteDevice = true;
    },

    closeDelete() {
      this.dialogDeleteDevice = false;
      this.$nextTick(() => {
        this.resetEditedDeviceValues();
        this.editedIndex = -1;
      });
    },

    deleteDeviceConfirm() {
      this.filteredDevices.splice(this.editedIndex, 1);
      this.closeDelete();
      this.deleteDevice();
    },

    close() {
      this.dialogAddEdit = false;
      this.resetEditedDeviceValues();
      this.$v.editedDevice.$reset();
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },

    onSaveDevice() {
      if (
        this.$v.editedDevice.loggerId.$invalid ||
        this.$v.editedDevice.cellId.$invalid ||
        this.$v.editedDevice.deviceType.$invalid ||
        this.$v.editedDevice.customer.$invalid
      ) {
        this.$v.editedDevice.loggerId.$touch();
        this.$v.editedDevice.cellId.$touch();
        this.$v.editedDevice.deviceType.$touch();
        this.$v.editedDevice.customer.$touch();
        return;
      }
      if (this.editedIndex > -1) {
        // console.log("Editing device: ", this.editedDevice.deviceId);
        Object.assign(this.devices[this.editedIndex], this.editedDevice);
      } else {
        let dev = {
          deviceId: "",
          testcaseId: "",
          loggerId: "",
          cellId: "",
          deviceType: "",
          customer: "",
        };
        dev.deviceId = this.editedDevice.deviceId;
        dev.testcaseId = this.editedDevice.testcaseId;
        dev.loggerId = this.editedDevice.loggerId;
        dev.cellId = this.editedDevice.cellId;
        dev.deviceType = this.editedDevice.deviceType;
        dev.customer = this.editedDevice.customer;
        this.registerDevice();
        const device = {
          device_id: dev.deviceId,
          testcase_id: dev.testcaseId,
          logger_id: dev.loggerId,
          cell_id: dev.cellId,
          device_type: dev.deviceType,
          customer: dev.customer,
        };
        this.devices.push(device);
        this.filteredDevices.push(dev);
        // console.log("devices: ", this.devices);
        // console.log("filteredDevices: ", this.filteredDevices);
      }
      this.close();
    },

    testcaseFound(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },

    findTestcase(text) {
      for (let i = 0; i < this.testcases.length; i += 1) {
        // console.log("text: ", text);
        // console.log("name: ", this.testcases[i].name);
        if (this.testcases[i].name.toString().toLowerCase() === text.toString().toLowerCase()) {
          return this.testcases[i];
        }
      }
      return null;
    },
  },
};
</script>

<style scoped>
.alert-container {
  width: 100%;
  position: absolute;
}
.alert {
  width: 100%;
}
.title {
  margin: 10px;
}
.fullwidth {
  width: 100%;
}
</style>
